import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';

import { Create, Cog } from '../icons';

import './styles.css';
import { withTranslation } from 'react-i18next';

const styles = {
  iconButton: {
    display: 'block',
    height: 24,
    width: 24,
    padding: 3,
  },
  menuItem: {
    fontSize: 13,
  },
};

class ItemContainer extends Component {
  static propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onCreateTouchTap: PropTypes.func,
  };

  state = {
    popover: false,
  };

  handleCreateTouchTap = event => {
    event.stopPropagation();
    this.props.onCreateTouchTap();
  };

  handleSettingsTouchTap = event => {
    event.preventDefault();
    this.setState({
      popover: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      popover: false,
    });
  };

  handleEditTouchTap = () => {
    this.setState({
      popover: false,
    });

    this.props.onEdit();
  };

  handleDeleteTouchTap = () => {
    this.setState({
      popover: false,
    });

    this.props.onDelete();
  };

  render() {
    const { t } = this.props;
    return (
      <div className="item-container">
        <div className="item-container-toolbar">
          <span className="item-container-title">{this.props.title}</span>
          <div className="item-toolbar-actions">
            <IconButton
              style={styles.iconButton}
              onClick={this.handleSettingsTouchTap}
            >
              <Cog />
            </IconButton>
            <Popover
              open={this.state.popover}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              targetOrigin={{ horizontal: 'left', vertical: 'top' }}
              onRequestClose={this.handleRequestClose}
            >
              <Menu>
                <MenuItem
                  primaryText={t('Edit Date/Time')}
                  onClick={this.handleEditTouchTap}
                  innerDivStyle={styles.menuItem}
                />
                <MenuItem
                  primaryText={t('Delete')}
                  onClick={this.handleDeleteTouchTap}
                  innerDivStyle={styles.menuItem}
                />
              </Menu>
            </Popover>
            <IconButton
              style={styles.iconButton}
              onClick={this.handleCreateTouchTap}
            >
              <Create />
            </IconButton>
          </div>
        </div>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default withTranslation()(ItemContainer);
