export const RECEIVE_MARKETPLACE_STATUS = 'RECEIVE_MARKETPLACE_STATUS';
export const STOP_RECEIVING_MARKETPLACE_STATUS =
  'STOP_RECEIVING_MARKETPLACE_STATUS';
export const RECEIVE_FOOD_ORDER_STATUS = 'RECEIVE_FOOD_ORDER_STATUS';
export const STOP_RECEIVING_FOOD_ORDER_STATUS =
  'STOP_RECEIVING_FOOD_ORDER_STATUS';
export const RECEIVE_BOOKING_STATUS = 'RECEIVE_BOOKING_STATUS';
export const STOP_RECEIVING_BOOKING_STATUS = 'STOP_RECEIVING_BOOKING_STATUS';
export const RECEIVE_ONLINE_COURSE_STATUS = 'RECEIVE_ONLINE_COURSE_STATUS';
export const STOP_RECEIVING_ONLINE_COURSE_STATUS =
  'STOP_RECEIVING_ONLINE_COURSE_STATUS';
