import { connect } from 'react-redux';
import { togglePerformanceModal } from './modules/RegionalPerformanceCentre/actions';
import { toggleMatchSupportModal } from './modules/PlMatchSupport/actions';
import { toggleTherapistModal } from './modules/Therapists/actions';
import PGMOL from './PGMOL';
import { toogleHotelTherapyModal } from './modules/HotelTherapy/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    pgmol: state.pgmol,
  };
};

const mapDisptachToProps = (dispatch, ownProps) => ({
  togglePerformanceModal: value => dispatch(togglePerformanceModal(value)),
  toggleMatchSupportModal: value => dispatch(toggleMatchSupportModal(value)),
  toggleTherapistModal: value => dispatch(toggleTherapistModal(value)),
  toogleHotelTherapyModal: value => dispatch(toogleHotelTherapyModal(value)),
});

export default connect(mapStateToProps, mapDisptachToProps)(PGMOL);
