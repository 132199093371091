export const getAllCourses = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { appname }] = queryKey;
  const response = await fetch(
    `${process.env.REACT_APP_ONLINE_COURSE_API}/get-all-courses/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const addItemToCourses = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONLINE_COURSE_API}/add-course`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const duplicateCourse = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONLINE_COURSE_API}/duplicate-course`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const updateItemFromCourse = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONLINE_COURSE_API}/update-course`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const deleteItemFromOnlineCourses = async ({ appname, key }) => {};

export const getAllPaidCourses = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { appname }] = queryKey;
  const response = await fetch(
    `${process.env.REACT_APP_ONLINE_COURSE_API}/get-all-paid-courses/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const setMetaData = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONLINE_COURSE_API}/set_meta_data`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getMetaData = async ({ queryKey }) => {
  const [_key, { appname }] = queryKey;

  const response = await fetch(
    `${process.env.REACT_APP_ONLINE_COURSE_API}/get_meta_data/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getStripeDetails = async ({ queryKey }) => {
  const [_key, { appname }] = queryKey;

  const response = await fetch(
    `${process.env.REACT_APP_ONLINE_COURSE_API}/get_stipe_details/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getAllParticipants = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { appname }] = queryKey;

  const response = await fetch(
    `${process.env.REACT_APP_ONLINE_COURSE_API}/get-all-participants/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getAllCourseEnrollments = async ({ queryKey }) => {
  const [_key, { appname }] = queryKey;

  const response = await fetch(
    `${process.env.REACT_APP_ONLINE_COURSE_API}/get-all-course-enrollments/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};
