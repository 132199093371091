import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import DisabledWrapper from '../../../../../engage/components/disabledWrapper';
import CTAButton from '../../../../../../app/components/buttons/cta/CTA';
import { Trans, useTranslation } from 'react-i18next';

const BookingDisabledWrapper = ({ enableMemberLogin, history, children }) => {
  const { t } = useTranslation();

  const [btnLabel, setBtnLabel] = useState(t('Do it for me!'));
  const [btnDisabled, setBtnDisabled] = useState(false);

  const infoIcon = (
    <Tooltip
      title={t(
        "This will add a new page 'Booking' and add Booking component to that page in your app",
      )}
    >
      <InfoOutlinedIcon fontSize="small" />
    </Tooltip>
  );

  const instructions = (
    <span>
      <span
        role="button"
        tabIndex={0}
        onClick={() => {
          history.push('/members');
        }}
        style={{ borderBottom: '1px solid black', cursor: 'pointer' }}
      >
        <Trans>Go to Member Login</Trans>
      </span>
      &ndash;&gt; <Trans>Enable Member Login Toggle</Trans> &quot;
    </span>
  );

  return (
    <DisabledWrapper
      title={t('Member login has not been enabled yet')}
      message={
        <span>
          <span>
            <Trans>To use this feature enable member login in your app</Trans>
          </span>
          <br />
          {instructions}
        </span>
      }
      content={
        <div>
          <br />
          <CTAButton
            disabled={btnDisabled}
            label={btnLabel}
            height={35}
            minWidth={100}
            icon={infoIcon}
            onClick={() => {
              enableMemberLogin();
              setBtnDisabled(true);
              setBtnLabel(t('Adding...'));
            }}
          />
        </div>
      }
    >
      {children}
    </DisabledWrapper>
  );
};

BookingDisabledWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  history: PropTypes.object.isRequired,
  onAddZoomComponent: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
};

BookingDisabledWrapper.defaultProps = {
  onAddZoomComponent: () => {},
};

export default BookingDisabledWrapper;
