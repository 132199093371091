import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import { designChange, updateMarketplaceSettings } from './actions';
import Marketplace from './Marketplace';

const mapStateToProps = (state, ownProps) => {
  const appname = ownProps.match.params.appname;
  const componentDesign = ownProps.component.design || {};
  const palette = _get(state, `editor.design.${appname}.palette`);
  const design = {
    ...ownProps.component.design,
    enabled: componentDesign.enabled,
    textColor: componentDesign.textColor || '',
    backgroundColor: componentDesign.backgroundColor || '',
    buttonTextColor: componentDesign.buttonTextColor || '',
    buttonBackgroundColor: componentDesign.buttonBackgroundColor || '',
  };
  return {
    ...ownProps,
    profile: { ...state.account.profile },
    appname: ownProps.match.params.appname,
    design,
    component: {
      ...ownProps.component,
      design,
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMarketplaceSettings: (name, data) =>
    dispatch(
      updateMarketplaceSettings(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        name,
        data,
      ),
    ),

  onDesignToggle: value =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        'enabled',
        value,
      ),
    ),
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        field,
        value,
      ),
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Marketplace),
);
