import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Divider from 'material-ui/Divider';
import Checkbox from 'material-ui/Checkbox';

import SectionHeader from '../sectionHeader';

const AutoFillEmail = ({ onChange, isChecked }) => {
  const { t } = useTranslation();
  return (
    <div style={{ width: '100%' }}>
      <Divider
        style={{
          width: '100%',
          height: '2px',
        }}
      />
      <SectionHeader
        title={t('Auto Fill')}
        tooltip={t('Determine settings for auto fill')}
      />
      <Checkbox
        id="auto-fill-email"
        label={t(
          'Auto-fill the users email into the email field if they are logged into the app through member login',
        )}
        style={{
          paddingLeft: 18,
          paddingRight: 18,
          marginTop: 18,
          marginBottom: 18,
        }}
        labelStyle={{ fontSize: 12 }}
        checked={isChecked}
        onCheck={(e, checked) => onChange(checked)}
      />
    </div>
  );
};

AutoFillEmail.propTypes = {
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
};

AutoFillEmail.defaultProps = {
  isChecked: false,
};

export default AutoFillEmail;
