import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { CircularProgress } from '@material-ui/core';

import { getMetaData } from '../../api';

import CoursesTable from '../../components/coursesTable/CoursesTable';
import AddCourse from '../../components/addCourse/AddCourse';

const Courses = props => {
  const {
    addCoursesDialogOpen,
    onCoursesDialogOpen,
    appname,
    isAppAdmin,
    onResetCourseData,
    onCourseItemChange,
    coursesItem,
    pages,
  } = props;

  const [currency, setCurrency] = useState('GBP');

  const { data, isLoading: isLoadingData } = useQuery(
    ['onlineCourseMetaData', { appname }],
    getMetaData,
  );

  useEffect(() => {
    if (data) {
      setCurrency(data.currency || 'GBP');
    }
  }, [data]);

  if (isLoadingData) {
    return (
      <div style={{ margin: 'auto' }}>
        <CircularProgress color="primary" size={40} />;
      </div>
    );
  }

  return (
    <>
      {addCoursesDialogOpen && (
        <AddCourse
          appname={appname}
          addCouresDialogOpen={addCoursesDialogOpen}
          onCoursesDialogOpen={onCoursesDialogOpen}
          onCourseItemChange={onCourseItemChange}
          courseItem={coursesItem}
          onResetCourseData={onResetCourseData}
          currency={currency}
          pages={pages}
        />
      )}

      <CoursesTable
        appname={appname}
        isAppAdmin={isAppAdmin}
        onCoursesDialogOpen={onCoursesDialogOpen}
        onCoursesItemChange={onCourseItemChange}
        currency={currency}
      />
    </>
  );
};

Courses.propTypes = {
  addCoursesDialogOpen: PropTypes.bool.isRequired,
  onCoursesDialogOpen: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
  onResetCourseData: PropTypes.func.isRequired,
  isAppAdmin: PropTypes.bool.isRequired,
  onCourseItemChange: PropTypes.func.isRequired,
  coursesItem: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
};

export default Courses;
