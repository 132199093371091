import firebase from '../../../../../../../firebase';

export const updateMarketplaceSettings = (appname, componentID, name, data) => {
  const draftDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${componentID}/marketplace_settings`);

  const marketplaceRef = firebase
    .database()
    .ref(`marketplace/${appname}/marketplace_settings`);

  draftDataRef.child(name).set(data);
  marketplaceRef.child(name).set(data);

  return {
    type: 'UPDATED_MARKETPLACE_SETTINGS',
  };
};

export const designChange = (appname, dataId, field, value) => {
  const dataRef =
    field === 'layout'
      ? firebase.database().ref(`apps/${appname}/draft/always_data/${dataId}`)
      : firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/${dataId}/design`);

  dataRef.child(field).set(value);

  return {
    type: 'MARKETPLACE_DESIGN_CHANGE',
    appname,
    dataId,
    designType: field,
    value,
  };
};
