import _ from 'lodash';

const initialSettings = {
  open: false,
  file: null,
  cropArray: [],
  minWidth: null,
  minHeight: null,
  currentIndex: 0,
  pending: false,
  error: null,
  warning: null,
  isDeleted: false,
};

const initialState = {
  imagesUploaded: {},
  isImageUploaded: false,
};

const isEmpty = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const imageUpload = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_INITIAL_SETTINGS':
      return {
        ...state,
        [action.targetId]: {
          ...initialSettings,
          cropArray: action.config.map(crop => ({ x: 0, y: 0, ...crop })),
          minWidth: action.config.reduce((currentMax, crop) => {
            let width;
            if (isEmpty(crop)) {
              width = currentMax;
            } else if (crop.dimensions instanceof Array) {
              width = parseInt(crop.dimensions[0].split('x')[0], 10);
            } else {
              width = parseInt(crop.dimensions.split('x')[0], 10);
            }

            return width > currentMax ? width : currentMax;
          }, 0),
          minHeight: action.config.reduce((currentMax, crop) => {
            let height;

            if (isEmpty(crop)) {
              height = currentMax;
            } else if (crop.dimensions instanceof Array) {
              height = parseInt(crop.dimensions[0].split('x')[1], 10);
            } else {
              height = parseInt(crop.dimensions.split('x')[1], 10);
            }

            return height > currentMax ? height : currentMax;
          }, 0),
          currentIndex: 0,
          pending: false,
          error: null,
          open: false,
          file: null,
        },
      };
    case 'RECEIVE_CROP_DIMENSIONS': {
      const cropArray = state[action.targetId].cropArray;
      const cropArrayProps = {
        ...cropArray[state.currentIndex],
        ...action.crop,
      };
      const newState = {
        [action.targetId]: {
          ...state[action.targetId],
          cropArray: [
            {
              ...cropArray.slice(0, state.currentIndex)[0],
              ...cropArrayProps,
              ...cropArray.slice(state.currentIndex + 1),
            },
          ],
        },
      };
      return {
        ...state,
        ...newState,
      };
    }
    case 'REMOVE_UPLOAD_COMPONENT_DATA':
      return _.omit(state, action.targetId);

    case 'RECEIVE_IMAGE': {
      const newState = {
        [action.targetId]: {
          ...state[action.targetId],
          pending: false,
          open: true,
          file: action.file,
        },
      };
      return {
        ...state,
        ...newState,
      };
    }

    case 'DELETE_IMAGE':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          isDeleted: true,
        },
      };

    case 'OPEN_IMAGE_CROP_DIALOG':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          open: true,
          currentIndex: 0, // current index should always be 0 when opening crop dialog
        },
      };

    case 'CLOSE_IMAGE_CROP_DIALOG': {
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          open: false,
        },
      };
    }
    case 'OPEN_FILE_AND_URL_DIALOG': {
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          fileAndUrlDialogOpen: true,
        },
      };
    }

    case 'CLOSE_FILE_AND_URL_DIALOG': {
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          fileAndUrlDialogOpen: false,
        },
      };
    }

    case 'CHANGE_FILE_UPLOAD_URL': {
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          fileUploadUrl: action.value,
        },
      };
    }

    case 'PREV_IMAGE_CROP_STEP':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          currentIndex: state.currentIndex - 1,
        },
      };

    case 'NEXT_IMAGE_CROP_STEP':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          currentIndex: state.currentIndex + 1,
        },
      };

    case 'SET_IMAGE_CROP_STEP':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          currentIndex: action.index,
        },
      };

    case 'WARN_USER_OF_IMAGE':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          warning: action.message,
        },
      };

    case 'SUBMIT_IMAGE_CROP':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          error: null,
          pending: true,
        },
      };

    case 'IMAGE_UPLOAD_FAILURE':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          open: false,
          warning: null,
          error: action.message,
          pending: false,
        },
      };

    case 'IMAGE_UPLOAD_SUCCESS':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          pending: false,
          error: null,
          currentIndex: 0,
        },
        imagesUploaded: {
          ...state.imagesUploaded,
          [action.targetId]: {
            ...state[action.targetId],
            pending: false,
            error: null,
            currentIndex: 0,
          },
        },
        isImageUploaded: true,
      };

    case 'CLEAR_WARNING':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          warning: null,
        },
      };

    case 'CLEAR_IMAGE_UPLOAD_ERROR':
      return {
        ...state,
        [action.targetId]: {
          ...state[action.targetId],
          error: null,
        },
      };

    default:
      return state;
  }
};

export default imageUpload;
