import { openSnackbar } from 'app/modules/snackbar/actions';
import firebase from '../../../../firebase';

export const SET_HOTEL_THERAPIES = 'SET_HOTEL_THERAPIES';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_ERROR = 'SET_ERROR ';
export const TOGGLE_PL_HOTEL_THERAPY_MODAL = 'TOGGLE_PL_HOTEL_THERAPY_MODAL';
export const HOTEL_THERAPY_ITEM_CHANGE = 'HOTEL_THERAPY_ITEM_CHANGE';

export const hotelTherapyItemChange = item => ({
  type: HOTEL_THERAPY_ITEM_CHANGE,
  value: item,
});

export const toogleHotelTherapyModal = value => ({
  type: TOGGLE_PL_HOTEL_THERAPY_MODAL,
  value,
});

export const setAllTherapies = value => ({
  type: SET_HOTEL_THERAPIES,
  value,
});

export const setAllBookings = value => ({
  type: SET_BOOKINGS,
  value,
});

export const setIsLoading = value => ({
  type: SET_IS_LOADING,
  value,
});

export const getAllHotelTherapies = appname => dispatch => {
  dispatch(setIsLoading(true));
  const ref = firebase.database().ref(`pgmol/hotel_therapies/${appname}`);
  ref.on('value', snap => {
    const data = snap.val();
    dispatch(setAllTherapies(data));
    dispatch(setIsLoading(false));
  });
};

export const getAllBookings = appname => dispatch => {
  dispatch(setIsLoading(true));
  const ref = firebase.database().ref(`pgmol/bookings/${appname}`);
  ref.on('value', snap => {
    const data = snap.val();
    dispatch(setAllBookings(data));
    dispatch(setIsLoading(false));
  });
};

export const deleteHotelTherapy = (appname, selected) => dispatch => {
  const ref = firebase.database().ref(`pgmol/hotel_therapies/${appname}`);
  Promise.all(selected.map(key => ref.child(`${key}`).remove())).then(() => {
    dispatch(openSnackbar('Hotel Therapy successfully deleted.'));
  });
};

export const duplicateHotelTherapy = (appname, selected) => async dispatch => {
  const ref = firebase.database().ref(`pgmol/hotel_therapies/${appname}`);

  try {
    await Promise.all(
      selected.map(async id => {
        const refResult = await ref.child(id).once('value');
        const data = refResult.val();

        if (!data) return; // Prevent errors if the id doesn't exist in Firebase

        const { key, match, timeslots, booked, ...others } = data;
        const newRef = ref.push();
        let newTimeSlots = [];
        if (timeslots) {
          newTimeSlots = timeslots.map(item => ({
            ...item,
            capacityBooked: 0,
          }));
        }
        await newRef.set({
          key: newRef.key,
          match: `${match} copy`,
          booked: 0, 
          isDuplicate: true,
          ...(timeslots && { timeslots: newTimeSlots }),
          ...others,
        });
      }),
    );

    dispatch(openSnackbar('Hotel Therapy successfully duplicated.'));
  } catch (error) {
    console.error('Error duplicating hotel therapy:', error);
    dispatch(openSnackbar('Failed to duplicate Hotel Therapy.'));
  }
};

export const addItemToHotelTherapy = (data, callbackFn) => dispatch => {
  const { appname, key, ...rest } = data;
  dispatch(setIsLoading(true));
  const ref = firebase.database().ref(`pgmol/hotel_therapies/${appname}`);
  const newRef = ref.push();
  const id = newRef.key;

  newRef
    .set({
      key: id,
      ...rest,
    })
    .then(() => {
      callbackFn();
      dispatch(toogleHotelTherapyModal(false));
      dispatch(setIsLoading(false));
      dispatch(openSnackbar('Hotel Therapy successfully created.'));
    })
    .catch(e => {
      console.log(e);
      dispatch(openSnackbar('Unable to create Hotel Therapy.'));
    });
};

export const updateItemFromHotelTherapy = (data, callbackFn) => dispatch => {
  const { appname, key, ...rest } = data;
  dispatch(setIsLoading(true));
  const ref = firebase
    .database()
    .ref(`pgmol/hotel_therapies/${appname}/${key}`);

  ref
    .update({
      key,
      ...rest,
    })
    .then(() => {
      callbackFn();
      dispatch(setIsLoading(false));
      dispatch(toogleHotelTherapyModal(false));
      dispatch(openSnackbar('Hotel Therapy successfully updated.'));
    })
    .catch(e => {
      console.log(e);
      dispatch(openSnackbar('Unable to update Hotel Therapy.'));
    });
};
