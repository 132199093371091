import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'material-ui/SelectField';

import './styles.css';
import themeStyles from '../../../_export.scss';

const styles = {
  root: {
    width: '100%',
  },
  underline: {
    borderColor: 'rgba(0, 0, 0, 0.42)',
    borderWidth: '1px',
  },
  underlineFocus: {
    borderColor: themeStyles.primaryColorDark,
    borderWidth: '1px',
  },
  hintText: {
    color: '#333!important',
    fontFamily: 'Raleway, Karla, sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
  },
  selectedMenuItemStyle: {
    color: themeStyles.primaryColor,
  },
};

const BeezerSelectField = ({
  label,
  value,
  options,
  onChange,
  warning,
  disabled,
  style,
  className,
  autoWidth,
  multiple,
  width,
}) => (
  <div className={`select-field-wrapper ${className}`} style={{ width }}>
    <SelectField
      value={value}
      floatingLabelText={label}
      errorText={warning}
      onChange={(e, i, val) => onChange(i, val)}
      disabled={disabled}
      style={{ ...styles.root, ...style }}
      hintStyle={styles.hintText}
      floatingLabelStyle={styles.hintText}
      underlineStyle={styles.underline}
      underlineFocusStyle={styles.underlineFocus}
      autoWidth={autoWidth}
      multiple={multiple}
      selectedMenuItemStyle={styles.selectedMenuItemStyle}
    >
      {options}
    </SelectField>
  </div>
);

BeezerSelectField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.node),
  onChange: PropTypes.func.isRequired,
  warning: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  autoWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  width: PropTypes.string,
};

BeezerSelectField.defaultProps = {
  label: null,
  style: {},
  options: [],
  value: undefined,
  autoWidth: false,
  warning: null,
  disabled: false,
  className: '',
  multiple: false,
  width: '',
};

export default BeezerSelectField;
