import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import Container from '../../../../../../app/components/container';
import { CircularProgress } from '../../../../../../app/components/progress';
import {
  PLANS_WITH_ADDONS,
  TIER_1_PLAN_ID,
} from '../../../../../../plans/constants';

import { plans } from '../../../../../../plans';

import Timer from './timer';
import WordPress from './wordPress';
import Disabled from './disabled';
import Roster from './roster';
import Text from './text';
import Schedule from './schedule';
import Form from './form';
import Vote from './vote';
import FacebookPage from './facebookPage';
import Twitter from './twitter';
import YouTube from './youTube';
import Livestream from './livestream';
import VimeoVideo from './vimeoVideo';
import IFrame from './iFrame';
import Button from './button';
import File from './file';
import PDFViewer from './PDFViewer';
import Media from './media';
import Notification from './notification';
import InstagramFeed from './instagramFeed';
import Zoom from './zoom';
import Image from './image';
import Header from './header';
import Countdown from './countdown';
import EventList from './eventList';
import EventCourses from './eventCourses';
import Workforces from './workforces';
import Marketplace from './marketplace';
import Memberbodies from './memberbodies';
import Delegates from './delegates';
import Loyalty from './loyalty';
import Food from './food';
import Forum from './forum';
import Slider from './slider';
import GoogleMaps from './googleMaps';
import Rating from './rating';
import Appointedd from './appointedd';
import Ecwid from './ecwid';
import Shopify from './shopify';
import Woocommerce from './woocommerce';
import Popup from './Popup';
import PayNow from './payNow';
import RadioStreaming from './radioStreaming';
import TimerHistory from './timerHistory';
import Canva from './Canva';
import Gallery from './gallery';
import EventCalendar from './eventCalendar';
import Booking from './booking';
import Feedback from './feedback';
import Html from './html';
import SubscriptionPlans from './subscriptionPlans';
import PgmolRegionPerformanceCentre from './PgmolRegionPerformanceCentre';
import PgmolPlMatchSupport from './PgmolPlMatchSupport';
import Title from './Title';
import Blog from './Blog';
import OnlineCourse from './onlineCourse';

const NoMatch = () => (
  <p style={{ textAlign: 'center' }}>
    <Trans>Unknown Component Type</Trans>
  </p>
);

class ComponentInterface extends Component {
  static propTypes = {
    view: PropTypes.object,
    component: PropTypes.object,
    loading: PropTypes.bool,
    setListener: PropTypes.func.isRequired,
    removeListener: PropTypes.func.isRequired,
    subscription: PropTypes.oneOf(Object.keys(plans)).isRequired,
  };

  static defaultProps = {
    loading: false,
    view: {},
    component: {},
  };

  componentDidMount() {
    const { component, setListener } = this.props;
    if (typeof component.lazy_data === 'string') {
      setListener(component.lazy_data, component.type);
    }
  }

  componentDidUpdate(prevProps) {
    const { component } = this.props;
    if (component.lazy_data !== prevProps.component.lazy_data) {
      this.props.loadDiyComponents(this.props.match.params.appname);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { component, setListener } = this.props;
    if (component.lazy_data !== nextProps.component.lazy_data) {
      setListener(nextProps.component.lazy_data, nextProps.component.type);
    }
  }

  componentWillUnmount() {
    const { component, removeListener } = this.props;

    removeListener(component.lazy_data);
  }

  getComponentUI = component => {
    if (this.isDisabled(component.type)) {
      return Disabled;
    }

    switch (component.type) {
      case 'WordPress':
        return WordPress;

      case 'Text':
        return Text;

      case 'Image':
        return Image;

      case 'Roster':
        return Roster;

      case 'Schedule':
        return Schedule;

      case 'Form':
        return Form;

      case 'Vote':
        return Vote;

      case 'FacebookPage':
        return FacebookPage;

      case 'Twitter':
        return Twitter;

      case 'TwitterFeed':
        return Twitter;

      case 'YouTube':
        return YouTube;

      case 'YouTubeVideo':
        return YouTube;

      case 'Livestream':
        return Livestream;

      case 'VimeoVideo':
        return VimeoVideo;

      case 'IFrame':
        return IFrame;

      case 'Button':
        return Button;

      case 'File':
        return File;

      case 'PDFViewer':
        return PDFViewer;

      case 'Media':
        return Media;

      case 'Notification':
        return Notification;

      case 'InstagramFeed':
        return InstagramFeed;

      case 'Zoom':
        return Zoom;

      case 'Header':
        return Header;

      case 'Countdown':
        return Countdown;

      case 'EventList':
        return EventList;

      case 'EventCourses':
        return EventCourses;

      case 'EventCalendar':
        return EventCalendar;

      case 'EventOrganizers':
        return Memberbodies;

      case 'Workforces':
        return Workforces;

      case 'Marketplace':
        return Marketplace;

      case 'Memberbodies':
        return Memberbodies;

      case 'Delegates':
        return Delegates;

      case 'Loyalty':
        return Loyalty;

      case 'Food':
        return Food;

      case 'Forum':
        return Forum;

      case 'Slider':
        return Slider;

      case 'GoogleMaps':
        return GoogleMaps;

      case 'Rating':
        return Rating;

      case 'Appointedd':
        return Appointedd;

      case 'Ecwid':
        return Ecwid;

      case 'Shopify':
        return Shopify;

      case 'Woocommerce':
        return Woocommerce;
      case 'RadioStreaming':
        return RadioStreaming;

      case 'Popup':
        return Popup;

      case 'PayNow':
        return PayNow;

      case 'Timer':
        return Timer;

      case 'TimerHistory':
        return TimerHistory;

      case 'Canva':
        return Canva;

      case 'Gallery':
        return Gallery;

      case 'Appointment':
        return Booking;

      case 'Feedback':
        return Feedback;
      case 'Html':
        return Html;
      case 'SubscriptionPlans':
        return SubscriptionPlans;
      case 'PgmolRegionPerformanceCentre':
        return PgmolRegionPerformanceCentre;
      case 'PgmolPlMatchSupport':
        return PgmolPlMatchSupport;
      case 'Title':
        return Title;
      case 'Blog':
        return Blog;
      case 'OnlineCourse':
        return OnlineCourse;
      default:
        return NoMatch;
    }
  };

  isDisabled = component => {
    if (
      this.props.isAppSumAccount &&
      this.props.subscription !== TIER_1_PLAN_ID
    ) {
      return false;
    }
    let subscriptions;
    if (this.props.subscription && this.props.subscription.includes('diy'))
      subscriptions = 'diy';
    if (this.props.subscription && this.props.subscription.includes('deluxe'))
      subscriptions = 'deluxe';
    if (
      this.props.subscription &&
      this.props.subscription.includes('something')
    )
      subscriptions = 'something';
    if (PLANS_WITH_ADDONS.includes(subscriptions || this.props.subscription)) {
      if (
        plans[subscriptions || this.props.subscription].components.includes(
          component,
        )
      ) {
        return false;
      } else if (this.props.diyComponents) {
        const subscribedComponents = Object.keys(this.props.diyComponents);
        if (subscribedComponents.includes(component)) {
          return false;
        }
      }
    } else {
      if (plans[subscriptions || this.props.subscription]) {
        return !plans[
          subscriptions || this.props.subscription
        ].components.includes(component);
      }
    }
    return true;
  };

  render() {
    const { view, component, loading } = this.props;

    const ComponentUI = this.getComponentUI(component);

    if (loading) {
      return (
        <Container>
          <CircularProgress size={24} thickness={3} />
        </Container>
      );
    }

    return <ComponentUI title={view.title} {...this.props} />;
  }
}

export default ComponentInterface;
