import {
  HOTEL_THERAPY_ITEM_CHANGE,
  SET_BOOKINGS,
  SET_ERROR,
  SET_HOTEL_THERAPIES,
  SET_IS_LOADING,
  TOGGLE_PL_HOTEL_THERAPY_MODAL,
} from './actions';

const initialState = {
  allHotelTherapies: [],
  allBookings: [],
  isLoading: false,
  error: null,
  modalOpen: false,
  hotelTherapyItem: {},
};

const hotelTherapyReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOTEL_THERAPY_ITEM_CHANGE:
      return {
        ...state,
        hotelTherapyItem: action.value,
      };
    case SET_HOTEL_THERAPIES:
      return {
        ...state,
        allHotelTherapies: action.value,
        error: null,
      };
    case TOGGLE_PL_HOTEL_THERAPY_MODAL:
      return {
        ...state,
        modalOpen: action.value,
      };
    case SET_BOOKINGS:
      return {
        ...state,
        allBookings: action.value,
        error: null,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.value,
        error: null,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.value,
      };
    default:
      return state;
  }
};

export default hotelTherapyReducer;
