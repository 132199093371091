/* eslint-disable linebreak-style */
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  Grow,
  InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ExpandMore, CancelOutlined } from '@material-ui/icons';
import Toggle from 'material-ui/Toggle';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { filter, every, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FlatButton } from '../../../../../../app/components/buttons';
import firebase from '../../../../../../firebase';
import ImageUpload from '../../../../../editor/internals/imageUpload';
import { addItemToCourses, updateItemFromCourse } from '../../api';
import './styles.css';
import { DialogHeader, ErrorWrapper } from '../../Elements';

import { CourseContext } from '../../modules/courses/context/CourseContext';
import RichTextEditor from '../editor/RichTextEditor';
import { useStyles } from './customStyles';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';

const AddCourse = props => {
  const {
    addCouresDialogOpen,
    onCoursesDialogOpen,
    appname,
    onCourseItemChange,
    onResetCourseData,
    courseItem,
    currency,
    pages,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUpdate = Object.keys(courseItem).length > 0;
  const accountId = useSelector(state => state.account.organisation.key);
  const isImageUploaded = useSelector(
    state => state.editor.internals.imageUpload.isImageUploaded,
  );
  const imagesUploaded = useSelector(
    state => state.editor.internals.imageUpload.imagesUploaded,
  );

  const getImgUrl = useCallback(
    (type, key) => {
      const firebasePathMap = {
        previewImage: `online_courses/${appname}/temp_images/previewImage/${accountId}`,
        previewVideo: `online_courses/${appname}/temp_images/previewVideo/${accountId}`,
        moduleMedia: `online_courses/${appname}/temp_images/moduleMedia/${key}/${accountId}`,
      };

      return firebasePathMap[type];
    },
    [appname, accountId],
  );

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    isUpdate ? updateItemFromCourse : addItemToCourses,
  );

  const courseInfo = useContext(CourseContext);
  const {
    courseData,
    setCourseData,
    expandedModule,
    setExpandedModule,
    handleModuleChange,
    addModule,
    handleAccordionChange,
    resetCourseData,
    error,
    setError,
    isButtonDisabled,
    setIsButtonDisabled,
    stripeKeyError,
    setStripeKeyError,
  } = courseInfo;

  const moduleUpdatesRef = useRef({});

  const sortedRoutes = (() => {
    const copyRoutes = JSON.parse(JSON.stringify(cloneDeep(pages)));
    const filterRoutes = copyRoutes.filter(item => item.type !== 'Popup');
    return filterRoutes.sort((a, b) => a.title.localeCompare(b.title));
  })();

  useEffect(() => {
    const checkStripeKeyExists = async () => {
      setStripeKeyError(false);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_ONLINE_COURSE_API}/stripe-key-exists/${appname}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const { status } = await response.json();

        setStripeKeyError(status);
      } catch (err) {
        setStripeKeyError(true);
      }
    };
    checkStripeKeyExists();
  }, []);

  useEffect(() => {
    if (isUpdate) {
      setCourseData(courseItem);
    } else if (!isImageUploaded && !isUpdate) {
      resetCourseData();
    }
  }, [courseItem, isUpdate]);

  useEffect(() => {
    const getImageUrl = async () => {
      if (!isImageUploaded || !imagesUploaded) return;
      if (!Object.keys(imagesUploaded).length) return;

      try {
        const imagesKeys = Object.keys(imagesUploaded);
        // Filter out module media items - handle them separately
        const regularImages = imagesKeys.filter(
          key => !key.includes('moduleMedia_'),
        );

        if (regularImages.length > 0) {
          let newData = { ...courseData };

          await Promise.all(
            regularImages.map(async item => {
              const url = getImgUrl(item);
              const imageRef = firebase.database().ref(url);
              const snapshot = await imageRef.once('value');
              const value = snapshot.val();
              if (value) {
                newData = {
                  ...newData,
                  [item]: value,
                };
              }
            }),
          );

          if (JSON.stringify(newData) !== JSON.stringify(courseData)) {
            setCourseData(newData);
          }
        }
      } catch (err) {
        console.log('error--->', err);
      }
    };

    getImageUrl();
  }, [isImageUploaded, imagesUploaded, courseData, getImgUrl]);

  // Separate effect for handling module media
  useEffect(() => {
    const getModuleImageUrls = async () => {
      if (!isImageUploaded || !imagesUploaded) return;

      try {
        const imagesKeys = Object.keys(imagesUploaded);
        const moduleImages = imagesKeys.filter(key =>
          key.includes('moduleMedia_'),
        );

        if (moduleImages.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const item of moduleImages) {
            const urlPaths = item.split('_');
            const index = urlPaths.pop();

            // Check if we've already processed this module image
            if (moduleUpdatesRef.current[index]) continue;

            const formatItem = urlPaths.join('_');
            const url = getImgUrl(formatItem, index);

            const imageRef = firebase.database().ref(url);
            const snapshot = await imageRef.once('value');
            const value = snapshot.val();

            if (value) {
              moduleUpdatesRef.current[index] = true;
              // Use the current module data to update
              const currentModule = courseData.modules[index];
              if (currentModule) {
                handleModuleChange(index, 'media', value);
              }
            }
          }
        }
      } catch (err) {
        console.log('error handling module images:', err);
      }
    };

    getModuleImageUrls();
  }, [isImageUploaded, imagesUploaded, getImgUrl, courseData.modules]);

  // Reset module updates ref when dialog closes
  useEffect(() => {
    if (!addCouresDialogOpen) {
      moduleUpdatesRef.current = {};
    }
  }, [addCouresDialogOpen]);

  const deleteUploadedImages = async () => {
    if (isImageUploaded && imagesUploaded) {
      const imagesKeys = Object.keys(imagesUploaded);
      await Promise.all(
        imagesKeys.map(async item => {
          let url = null;
          if (item.includes('moduleMedia_')) {
            const urlPaths = item.split('_');
            const index = urlPaths.pop();
            const formatItem = urlPaths.join('_');
            url = getImgUrl(formatItem, index);
          } else {
            url = getImgUrl(item);
          }
          await firebase
            .database()
            .ref(url)
            .set(null);
        }),
      );
    }
  };

  const handleClose = async () => {
    await deleteUploadedImages();
    resetCourseData();
    onResetCourseData();
  };

  const validateFields = () => {
    const errors = [];

    if (!courseData.previewImage) {
      errors.push('Course Image is required.');
    }

    if (!courseData.description) {
      errors.push('Course Description is required.');
    }

    if (courseData.isPaid && parseInt(courseData.price, 10) === 0) {
      errors.push('Price must be greater than 0.');
    }

    const emptyModules = filter(courseData.modules, module =>
      every(module.content, value => isEmpty(value)),
    );

    if (emptyModules.length > 0) {
      errors.push("All module's content must be filled.");
    }

    return errors;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      // Validate fields
      const errors = validateFields();

      if (errors.length > 0) {
        setError(errors); // Display all errors concatenated
        return;
      }

      if (isButtonDisabled) {
        return;
      }

      setError([]);

      const payload = {
        appname,
        courseData,
      };

      setIsButtonDisabled(true);
      await mutateAsync(payload);

      queryClient.invalidateQueries('courseItems');
      await deleteUploadedImages();
      dispatch(
        openSnackbar(
          `Online Course ${isUpdate ? 'updated' : 'added'} successfully.`,
        ),
      );
      onCoursesDialogOpen(false);
      onCourseItemChange({});
      setIsButtonDisabled(false);
      resetCourseData();
      onResetCourseData();
    } catch (err) {
      dispatch(openSnackbar(`Oops an error occured. Please try again later.`));
    }
  };

  return (
    <Dialog
      data-testid="add-course"
      open={addCouresDialogOpen}
      autoScrollBodyContent
      disableEnforceFocus
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <div className="course-modal-content">
        <DialogHeader>
          <div />
          <h6>
            <Trans>Add a Course</Trans>
          </h6>
          <div>
            <CancelOutlined onClick={() => handleClose()} />
          </div>
        </DialogHeader>

        <form onSubmit={handleSubmit} data-testid="form">
          <fieldset>
            <Grid container spacing={3} width={'92%'}>
              <Grid item md={6}>
                <div>
                  <Trans>Course Image</Trans>
                </div>
                <ImageUpload
                  targetId="previewImage"
                  src={courseData.previewImage}
                  label="Image"
                  caption={t('This image will be displayed for this course.')}
                  firebaseUrl={getImgUrl('previewImage')}
                  title={t('Course Image')}
                  tooltip={t('Minimum image size 240x240 pixels')}
                  showCropAreaSizes
                  {...(isUpdate && {
                    noDelete: true,
                  })}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} width={'92%'}>
              <Grid item md={6}>
                <div>
                  <Trans>Course Video Preview</Trans>
                </div>
                <ImageUpload
                  targetId="previewVideo"
                  src={courseData.previewVideo}
                  label="Video"
                  caption={t(
                    'This video will be the preview video for this course.',
                  )}
                  firebaseUrl={getImgUrl('previewVideo')}
                  title={t('Course Video Preview')}
                  tooltip={t('Minimum image size 240x240 pixels')}
                  showCropAreaSizes
                  isVideoOnlyAllowed
                  {...(isUpdate && {
                    noDelete: true,
                  })}
                />
              </Grid>
            </Grid>

            <div className="field-wrapper">
              <TextField
                type="text"
                id="title"
                label={t('Course Title')}
                value={courseData.title}
                required
                fullWidth
                margin="normal"
                onChange={e => {
                  const value = e.target.value;
                  setCourseData(prev => ({
                    ...prev,
                    title: value,
                  }));
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="field-wrapper">
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Course Description"
                InputLabelProps={{
                  shrink: true,
                }}
                value={courseData.description}
                onChange={e => {
                  const value = e.target.value;
                  setCourseData(prev => ({
                    ...prev,
                    description: value,
                  }));
                }}
                required
              />
            </div>

            {/* Modules Section */}
            <div className="field-wrapper">
              <Grid item xs={12} style={{ margin: '20px 0' }}>
                <Grid className="addMoreWrapper">
                  <Typography variant="h6" gutterBottom>
                    Course Modules
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={addModule}
                    className={classes.addButton}
                  >
                    + Add Module
                  </Button>
                </Grid>
                {courseData.modules.map((module, index) => (
                  <Accordion
                    key={index}
                    expanded={expandedModule === index}
                    onChange={handleAccordionChange(index)}
                    elevation={2}
                    sx={{ mb: 2 }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`module${index}-content`}
                      id={`module${index}-header`}
                    >
                      <Typography>
                        Module - {index + 1}
                        {module.title && `: ${module.title}`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box p={2}>
                        <Grid container spacing={3}>
                          <Grid item md={6}>
                            <div>
                              <Trans>Module Video/Image</Trans>
                            </div>
                            <ImageUpload
                              targetId={`moduleMedia_${index}`}
                              src={module.media}
                              label="Module Video/Image"
                              caption={t(
                                'This image will be video/image for this course',
                              )}
                              firebaseUrl={getImgUrl('moduleMedia', index)}
                              title={t('Module Video/Image')}
                              showCropAreaSizes
                              isVideoAllowed
                              {...(isUpdate && {
                                noDelete: true,
                              })}
                            />
                          </Grid>
                        </Grid>

                        <TextField
                          fullWidth
                          label="Module Title"
                          value={module.title}
                          onChange={e =>
                            handleModuleChange(index, 'title', e.target.value)
                          }
                          margin="normal"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                        <Box my={2}>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            className={classes.subTitle}
                          >
                            Module Content
                          </Typography>
                          <RichTextEditor
                            initialValue={module.content}
                            onChange={content =>
                              handleModuleChange(index, 'content', content)
                            }
                          />
                        </Box>

                        {index > 0 && (
                          <Box mt={2}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              className={classes.removeButton}
                              onClick={() => {
                                const updatedModules = [...courseData.modules];
                                updatedModules.splice(index, 1);

                                setCourseData(prev => ({
                                  ...prev,
                                  modules: updatedModules,
                                }));
                                // Adjust expanded module index after deletion
                                if (expandedModule >= index) {
                                  setExpandedModule(
                                    Math.max(0, expandedModule - 1),
                                  );
                                }
                              }}
                            >
                              Remove Module
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </div>

            <div className="field-wrapper">
              <TextField
                type="text"
                id="whatNext"
                label={t("What's Next After Completion")}
                required
                fullWidth
                margin="normal"
                value={courseData.whatNext}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Content to show after course completion."
                rows={4}
                onChange={e => {
                  const value = e.target.value;
                  setCourseData(prev => ({
                    ...prev,
                    whatNext: value,
                  }));
                }}
              />
            </div>

            <div className="field-wrapper">
              <TextField
                fullWidth
                select
                label="Link to App Page"
                value={courseData.nextPageLink}
                onChange={e => {
                  const value = e.target.value;
                  setCourseData(prev => ({
                    ...prev,
                    nextPageLink: value,
                  }));
                }}
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              >
                <option value="">Select a page</option>
                {sortedRoutes.map((item, index) => (
                  <option key={index} value={item.path}>
                    {item.title}
                  </option>
                ))}
              </TextField>
            </div>

            <div className="field-wrapper custom-wrapper">
              <Toggle
                onToggle={(v, b) => {
                  const value = b;
                  setCourseData(prev => ({
                    ...prev,
                    isActive: value,
                  }));
                }}
                toggled={courseData.isActive}
                label={<span>Enable Course</span>}
                className={classes.customToggle}
              />
            </div>

            <div className="field-wrapper custom-wrapper">
              <Toggle
                onToggle={(v, b) => {
                  const value = b;
                  setCourseData(prev => ({
                    ...prev,
                    isPaid: value,
                  }));
                }}
                toggled={courseData.isPaid}
                label={t('Enable Payment')}
              />
            </div>

            <Grow in={stripeKeyError && courseData.isPaid} unmountOnExit>
              <div>
                <p className="subscription-error-msg">
                  <Trans>Add stripe keys to add paid courses.</Trans>
                </p>
                <p className="subscription-link">
                  <Link
                    to={{
                      pathname: `/${appname}/ecommerce/online-courses/settings`,
                      hash: '#user-app-subscriptions',
                    }}
                  >
                    <Trans>Click here</Trans>
                  </Link>{' '}
                  <Trans>to add keys</Trans>
                </p>
              </div>
            </Grow>

            <Grow in={courseData.isPaid} unmountOnExit>
              <div className="field-wrapper custom-wrapper">
                <TextField
                  type="number"
                  label={t('Price')}
                  value={courseData.price}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {
                          new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency,
                          })
                            .formatToParts(0)
                            .find(part => part.type === 'currency').value
                        }
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 1,
                    step: 0.01,
                  }}
                  onChange={e => {
                    const value = parseFloat(
                      parseFloat(e.target.value).toFixed(2),
                    );
                    setCourseData(prev => ({
                      ...prev,
                      price: value,
                    }));
                  }}
                />
                ;
              </div>
            </Grow>

            <div>
              <FlatButton
                label={
                  isLoading ? t('SAVING...') : isUpdate ? t('Update') : t('ADD')
                }
                type="submit"
                primary
              />
            </div>

            {error && error.length > 0 && (
              <ErrorWrapper>
                {error.map(e => (
                  <p key={e}>{e}</p>
                ))}
              </ErrorWrapper>
            )}
          </fieldset>
        </form>
      </div>
    </Dialog>
  );
};

AddCourse.propTypes = {
  appname: PropTypes.string.isRequired,
  addCouresDialogOpen: PropTypes.bool.isRequired,
  onCoursesDialogOpen: PropTypes.func.isRequired,
  onResetCourseData: PropTypes.func.isRequired,
  courseItem: PropTypes.object.isRequired,
  onCourseItemChange: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
};

export default React.memo(AddCourse);
