import { combineReducers } from 'redux';
import regionalPerformanceCentre from './modules/RegionalPerformanceCentre/reducer';
import plMatchSupport from './modules/PlMatchSupport/reducer';
import plTherapists from './modules/Therapists/reducer';
import plHotelTherapies from './modules/HotelTherapy/reducer';

export default combineReducers({
  regionalPerformanceCentre,
  plMatchSupport,
  plTherapists,
  plHotelTherapies,
});
