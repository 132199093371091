import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import TextField from 'material-ui/TextField';
import MenuItem from 'material-ui/MenuItem';
import { Trans, useTranslation } from 'react-i18next';
import SelectField from '../../../../../../app/components/selectField';
import themeStyles from '../../../../../../_export.scss';
import { whitelabeledHost } from '../../../../../../utils/whitelabeledHost';
import './styles.scss';
import '../../styles.scss';

const TrackSwitchedColor = { backgroundColor: themeStyles.primaryColorLight };
const ThumbSwitchedColor = { backgroundColor: themeStyles.primaryColor };
const isWhiteLabeledHost = whitelabeledHost();

const availableLayouts = {
  desktop: [
    {
      name: 'Standard Layout',
      value: 'standard-layout',
    },
    {
      name: 'Horizontal Scroll Layout',
      value: 'horizontal-scroll',
    },
  ],
  mobile: [
    {
      name: 'Standard Vertical Layout',
      value: 'standard-layout',
    },
    {
      name: 'Horizontal Scroll Layout',
      value: 'horizontal-scroll',
    },
  ],
};

const availableLayoutsSize = {
  desktop: [
    {
      name: 'Standard Full Screen (All Information)',
      value: 'full-screen',
    },
    {
      name: 'Half Screen (Minimal Information)',
      value: 'half-screeen',
    },
    {
      name: 'More Than Half Screen (Little More Information)',
      value: 'more-than-half-screeen',
    },
  ],
  mobile: [
    {
      name: 'Standard Full Screen (All Information)',
      value: 'full-screen',
    },
    {
      name: 'Half Screen (Minimal Information)',
      value: 'half-screeen',
    },
    {
      name: 'More Than Half Screen (Little More Information)',
      value: 'more-than-half-screeen',
    },
  ],
};

const Subscriptions = props => {
  const {
    userSubscriptions,
    updateField,
    stripeApiTestMode,
    paymentMethodChange,
    stripeApiKey,
    updateStripeApiKey,
    stripeApiPublicKey,
    location,
    setDefaultRestrictedAccess,
    copyTextSuccess,
    isAppAdmin,
    desktopLayoutType,
    mobileLayoutType,
    desktopLayoutSize,
    mobileLayoutSize,
    customerPortalLink,
    updatePortalLink,
  } = props;
  const { t } = useTranslation();

  const [userSubRef, setUserSubRef] = useState(null);

  useEffect(() => {
    if (userSubRef && location.hash === '#user-app-subscriptions') {
      userSubRef.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [userSubRef]);

  const copyToClipboard = e => {
    var range = document.createRange();
    range.selectNode(e.target);
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    const newClipText = window.getSelection().toString();
    navigator.clipboard.writeText(newClipText);
    copyTextSuccess(t('Copied to Clipboard'));
    window.getSelection().removeAllRanges(); // to deselect
  };

  const dropdownLayoutOptions = deviceType => {
    const values = availableLayouts[deviceType];
    return values.map(type => (
      <MenuItem
        key={type.value}
        value={type.value}
        primaryText={t(type.name)}
      />
    ));
  };

  const dropdownLayoutSizeOptions = deviceType => {
    const values = availableLayoutsSize[deviceType];
    return values.map(type => (
      <MenuItem
        key={type.value}
        value={type.value}
        primaryText={t(type.name)}
      />
    ));
  };

  return (
    <div>
      <div
        className="auth-panel"
        ref={ref => setUserSubRef(ref)}
        id="user-app-subscriptions"
      >
        <div className="auth-panel-title">
          <Trans>User App Subscriptions</Trans>
        </div>
        <div className="auth-panel">
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => {
              updateField(b, 'userSubscriptions');
              setDefaultRestrictedAccess(b);
            }}
            toggled={userSubscriptions}
            label={t('User subscriptions')}
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          {/* <Toggle
            style={{ margin: '1vh 0' }}
            onToggle={(v, b) => {
              updateField(b, 'horizontalLayout');
            }}
            toggled={horizontalLayout}
            disabled={!userSubscriptions}
            label={t('Horizontal subscription plan layouts on mobile devices')}
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          /> */}
          {!isAppAdmin && (
            <a
              href="https://beezer.zendesk.com/hc/en-us/articles/360049266472-Subscription-Manager"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'block',
                color: themeStyles.primaryColor,
                marginBottom: 5,
                marginTop: 5,
                fontWeight: 'bold',
              }}
            >
              <Trans>How to create a Stripe Business account</Trans>
            </a>
          )}
          {userSubscriptions && (
            <React.Fragment>
              <Toggle
                style={{ margin: '0' }}
                onToggle={(v, b) => updateField(b, 'stripeApiTestMode')}
                toggled={stripeApiTestMode}
                label={t('Test Mode')}
                trackSwitchedStyle={TrackSwitchedColor}
                thumbSwitchedStyle={ThumbSwitchedColor}
              />

              <div className="paymentMethod">
                <Toggle
                  style={{ margin: '0' }}
                  onToggle={(v, b) => updateField(b, 'paymentMethodChange')}
                  toggled={paymentMethodChange}
                  label={t('Add new payment method (for app users)')}
                  trackSwitchedStyle={TrackSwitchedColor}
                  thumbSwitchedStyle={ThumbSwitchedColor}
                />
                {!isAppAdmin && (
                  <a
                    href="https://docs.stripe.com/customer-management"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'block',
                      color: themeStyles.primaryColor,
                      marginBottom: 5,
                      marginTop: 5,
                      fontWeight: 'bold',
                    }}
                  >
                    <Trans>
                      How to setup payment method on stripe customer portal
                    </Trans>
                  </a>
                )}
                {paymentMethodChange && (
                  <TextField
                    id="url"
                    value={customerPortalLink}
                    className="auth-panel-privacy-url-textbox"
                    floatingLabelText={t('Customer portal link')}
                    onChange={(event, value) =>
                      updatePortalLink(value, 'customerPortalLink')
                    }
                  />
                )}
              </div>
              <TextField
                id="url"
                value={stripeApiKey}
                className="auth-panel-privacy-url-textbox"
                floatingLabelText={t('Stripe Secret key')}
                onChange={(event, value) =>
                  updateStripeApiKey(value, 'stripeApiKey')
                }
              />
              <TextField
                id="url"
                value={stripeApiPublicKey}
                className="auth-panel-privacy-url-textbox"
                floatingLabelText={t('Stripe Publishable key')}
                onChange={(event, value) =>
                  updateStripeApiKey(value, 'stripeApiPublicKey')
                }
              />
              {!isAppAdmin && !isWhiteLabeledHost && (
                <div className="webhook_instruction_container">
                  <div className="helper_text">
                    <Trans>Add this webhook to your Stripe Account</Trans>
                  </div>
                  <div
                    onClick={e => {
                      copyToClipboard(e);
                    }}
                    className="webhook_url"
                    title={t('Copy to Clipboard')}
                  >
                    {`${process.env.REACT_APP_PAYMENT_API}/stripe_member_webhooks`}
                  </div>
                </div>
              )}

              <h3>{t('Subscription Layout')}</h3>
              <div className="subscription-layout-container">
                <SelectField
                  width={'500px'}
                  label={t('Select a layout for desktop devices')}
                  value={desktopLayoutType}
                  options={dropdownLayoutOptions('desktop')}
                  onChange={(e, value) =>
                    updateField(value, 'desktopLayoutType')
                  }
                  autoWidth
                />
                <SelectField
                  width={'500px'}
                  label={t('Select layout size for desktop devices')}
                  value={desktopLayoutSize}
                  options={dropdownLayoutSizeOptions('desktop')}
                  onChange={(e, value) =>
                    updateField(value, 'desktopLayoutSize')
                  }
                  autoWidth
                />
              </div>
              <div className="subscription-layout-container">
                <SelectField
                  width={'500px'}
                  label={t('Select a layout for mobile devices')}
                  value={mobileLayoutType}
                  options={dropdownLayoutOptions('mobile')}
                  onChange={(e, value) =>
                    updateField(value, 'mobileLayoutType')
                  }
                  autoWidth
                />
                <SelectField
                  width={'500px'}
                  label={t('Select layout size for mobile devices')}
                  value={mobileLayoutSize}
                  options={dropdownLayoutSizeOptions('desktop')}
                  onChange={(e, value) =>
                    updateField(value, 'mobileLayoutSize')
                  }
                  autoWidth
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

Subscriptions.propTypes = {
  profileEnabled: PropTypes.bool,
  firstNameRequired: PropTypes.bool.isRequired,
  lastNameRequired: PropTypes.bool.isRequired,
  organisationRequired: PropTypes.bool.isRequired,
  roleRequired: PropTypes.bool.isRequired,
  phoneNumberRequired: PropTypes.bool.isRequired,
  linkedInAddressRequired: PropTypes.bool.isRequired,
  twitterAddressRequired: PropTypes.bool.isRequired,
  websiteAddressRequired: PropTypes.bool.isRequired,
  interestsRequired: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired,
};

Subscriptions.defaultProps = {
  profileEnabled: true,
  signupEnabled: true,
};

export default Subscriptions;
