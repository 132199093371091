import React, { useEffect, useState } from 'react';
import AddAppointment from '../../components/addAppointment/AddAppointment';
import AppointmentsTable from '../../components/appointmentsTable/AppointmentsTable';

const Appointments = props => {
  const {
    addAppointmentsDialogOpen,
    onAddAppointmentDialogOpen,
    onAppointmentItemChange,
    appointmentItem,
    appname,
    deleteAppointmentSlot,
    loading,
    onResetAppointmentData,
    isAppAdmin,
    duplicateAppointment,
  } = props;
  return (
    <>
      {addAppointmentsDialogOpen && (
        <AddAppointment
          appname={appname}
          addAppointmentsDialogOpen={addAppointmentsDialogOpen}
          onAppointmentsDialogOpen={onAddAppointmentDialogOpen}
          onAppointmentsItemChange={onAppointmentItemChange}
          appointmentItem={appointmentItem}
          onResetAppointmentData={onResetAppointmentData}
        />
      )}

      <AppointmentsTable
        appname={appname}
        onAppointmentsDialogOpen={onAddAppointmentDialogOpen}
        onAppointmentsItemChange={onAppointmentItemChange}
        deleteAppointmentSlot={deleteAppointmentSlot}
        loading={loading}
        isAppAdmin={isAppAdmin}
        duplicateAppointment={duplicateAppointment}
      />
    </>
  );
};

export default Appointments;
