import React from 'react';
import ParticipantsTable from '../../components/participantsTable/ParticipantsTable';
import ParticipantsDetails from '../../components/participantsDetails/ParticipantsDetails';

const Participants = props => {
  const {
    appname,
    participantDialogOpen,
    onResetParticipantData,
    participantItemChange,
    viewParticipantDialog,
    participantItem,
  } = props;
  return (
    <>
      {participantDialogOpen && (
        <ParticipantsDetails
          viewParticipantDialog={viewParticipantDialog}
          participantDialogOpen={participantDialogOpen}
          selectedParticipant={participantItem}
        />
      )}
      <ParticipantsTable
        appname={appname}
        setOpenDialog={viewParticipantDialog}
        setParticipantDetails={participantItemChange}
      />
    </>
  );
};

export default Participants;
