import {
  ADD_COURSES_DIALOG,
  COURSE_RESET_DATA,
  COURSE_ITEM_CHANGE,
} from './types';

export const addCoursesDialogOpen = isOpen => ({
  type: ADD_COURSES_DIALOG,
  payload: isOpen,
});

export const onResetCourseData = () => ({
  type: COURSE_RESET_DATA,
});

export const courseItemChange = item => ({
  type: COURSE_ITEM_CHANGE,
  payload: item,
});
