import firebase from '../../../../../../../firebase';
import { filterChange } from '../actions';
import { RECEIVE_MARKETPLACE_FILTER_FIELD_UPDATE } from '../editFilter/types';
import { validateField } from '../validators';
import {
  OPEN_NEW_FILTER_DIALOG,
  CLOSE_NEW_FILTER_DIALOG,
  NEW_FILTER_ADDED,
  NEW_FILTER_FIELD_CHANGE,
  ADD_NEW_FILTER,
  RESET_FILTER_DATA,
} from './types';

const newFilterFieldChange = (prop, value, isValid) => ({
  type: NEW_FILTER_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const openNewFilterDialog = () => ({ type: OPEN_NEW_FILTER_DIALOG });

export const closeNewFilterDialog = () => dispatch => {
  dispatch(filterChange({}));
  dispatch({ type: RESET_FILTER_DATA });
  dispatch({ type: CLOSE_NEW_FILTER_DIALOG });
};

export const formFieldChange = (prop, value) => dispatch => {
  const isValid =
    prop === 'name' && value.length > 0 ? validateField[prop](value) : null;

  return dispatch(newFilterFieldChange(prop, value, isValid));
};

export const createFilter = (appname, filter) => dispatch =>
  firebase
    .database()
    .ref(`marketplace_filters/${appname}`)
    .push(filter)
    .then(ref => {
      dispatch({
        type: NEW_FILTER_ADDED,
        key: ref.key,
        data: filter,
      });
    });

export const updateFilter = (app, categoryID, update) => dispatch => {
  firebase
    .database()
    .ref(`marketplace_filters/${app}/${categoryID}`)
    .update(update)
    .then(() => {
      dispatch({
        type: NEW_FILTER_ADDED,
        key: categoryID,
        data: update,
      });
    });
};

export const addFilter = appname => (dispatch, getState) => {
  const {
    name,
    description,
    // filterType,
  } = getState().ecommerce.marketplace.filters.newFilter;
  const { selectedFilter } = getState().ecommerce.marketplace.filters.data;
  const isUpdate = Object.keys(selectedFilter).length > 0;
  let filter = {
    name: name.value,

    description: description.value,
  };
  if (isUpdate) {
    filter = { ...filter, updatedAt: Date.now() };
  } else {
    filter = { ...filter, filterType: 'Product', date: Date.now() };
  }

  dispatch({ type: ADD_NEW_FILTER });
  dispatch(
    isUpdate
      ? updateFilter(appname, selectedFilter.key, filter)
      : createFilter(appname, filter),
  );
};
