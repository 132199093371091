import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';

import {
  RECEIVE_PL_THERAPISTS,
  TOGGLE_PL_THERAPIST_MODAL,
  EDIT_THERAPIST_DATA,
  SELECT_ALL_PL_THERAPISTS,
  DESELECT_ALL_PL_THERAPISTS,
  SELECT_PL_THERAPIST,
  SET_ENABLE_THERAPIST_BOOKING,
  IMAGE_UPLOAD_SUCCESS,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  modalOpen: false,
  rowId: null,
  therapist: {},
  selectAll: false,
  enableTherapistBooking: false,
  isImageUploaded: false,
};

const plTherapistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENABLE_THERAPIST_BOOKING:
      return {
        ...state,
        enableTherapistBooking: action.value,
      };
    case TOGGLE_PL_THERAPIST_MODAL:
      return {
        ...state,
        modalOpen: action.value,
        rowId: null,
        therapist: {},
      };
    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        isImageUploaded: true,
      };
    case RECEIVE_PL_THERAPISTS:
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };
    case EDIT_THERAPIST_DATA:
      return {
        ...state,
        rowId: action.rowId,
        therapist: action.value,
        modalOpen: true,
      };

    case SELECT_ALL_PL_THERAPISTS: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: !chunk.default,
      }));
      const selected = _union(
        state.selected,
        Object.keys(filteredChunks).filter(chunk => chunk !== 'default'),
      );

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_PL_THERAPISTS:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case SELECT_PL_THERAPIST: {
      const prevState =
        (state.filteredChunks[action.key] &&
          state.filteredChunks[action.key].selected) ||
        false;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }
    default:
      return state;
  }
};
export default plTherapistsReducer;
