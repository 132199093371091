import { connect } from 'react-redux';
import Courses from './Courses';
import {
  addCoursesDialogOpen,
  onResetCourseData,
  courseItemChange,
} from './action';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.onlineCourse.onlineCourse,
  appname: ownProps.match.params.appname,
  pages: state.editor.structure.routes || [],
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCoursesDialogOpen: isOpen => dispatch(addCoursesDialogOpen(isOpen)),
  onResetCourseData: () => dispatch(onResetCourseData()),
  onCourseItemChange: item => dispatch(courseItemChange(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
