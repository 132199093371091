/* global fetch Headers */
import _map from 'lodash/map';
import firebase from '../../../../firebase';
import {
  STG_NEW_PRICING_START_DATE,
  LIVE_NEW_PRICING_START_DATE,
  componentNewNames,
} from '../../../../plans';
import {
  openDialog,
  reportDialogError,
  dialogActionComplete,
} from '../../../../app/modules/dialog/actions';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import { getSpecialComponents } from '../../../editor/modules/build/modules/create/createComponent/actions';

export const hydrateSubscriptions = data => ({
  type: 'HYDRATE_SUBSCRIPTIONS',
  data,
});

export const updateSubscription = (appname, data) => (dispatch, getState) => {
  //if old plan then set old plan key
  if (data && data.updated_at) {
    const accountId = getState().account.profile.account;
    const data = { appname };
    data[`{${appname}} upgrade_date`] = data.updated_at;
    const adminAppName = getState().account.profile.admin_appname;
    const isAppAdmin = (adminAppName && adminAppName.length > 0) || false;
    const date =
      process.env.REACT_APP_PLATFORM_ENV === 'staging'
        ? STG_NEW_PRICING_START_DATE
        : LIVE_NEW_PRICING_START_DATE;
    data.old_plan = data.updated_at < date;
  }

  return {
    type: 'UPDATE_SUBSCRIPTION',
    appname,
    data,
  };
};

export const listenToSubscription = appname => (dispatch, getState) => {
  firebase
    .database()
    .ref(`apps/${appname}/subscription`)
    .on('value', snapshot => {
      const data = snapshot.val();
      let subscriptionExpired = false;
      if (data && data.plan) {
        subscriptionExpired = Date.now() > data.current_period_end;
      }

      if (subscriptionExpired) {
        return dispatch(updateSubscription(appname, null));
      }
      if (snapshot.exists()) {
        return dispatch(
          updateSubscription(appname, {
            ...data,
            plan:
              data.plan === 'price_1OPOWsH7KxBKIk4ar4GVzFYV'
                ? 'deluxe'
                : data.plan,
            realPlan:
              data.realPlan === 'price_1OPOWsH7KxBKIk4ar4GVzFYV'
                ? 'deluxe'
                : data.realPlan,
          }),
        );
      }
      return dispatch(updateSubscription(appname, snapshot.val()));
    });
};

export const getSubscriptions = () => (dispatch, getState) => {
  const { organisation } = getState().account;

  if (!organisation.apps) {
    return Promise.resolve([]);
  }

  const promises = _map(
    organisation.apps,
    (app, domain) =>
      new Promise((resolve, reject) => {
        firebase
          .database()
          .ref(`apps/${domain}/subscription`)
          .once('value')
          .then(async snapshot => {
            if (
              getState().account.profile.ip_data &&
              getState().account.profile.ip_data.showDynamicPricing ===
                undefined
            ) {
              const { uid } = firebase.auth().currentUser;

              if (!snapshot.val()) {
                await firebase
                  .database()
                  .ref(`users/${uid}/ip_data/`)
                  .update({
                    showDynamicPricing: true,
                  });
              } else {
                await firebase
                  .database()
                  .ref(`users/${uid}/ip_data/`)
                  .update({
                    showDynamicPricing: false,
                  });
              }
            }
            dispatch(listenToSubscription(domain));
            const result = snapshot.val();

            let subscriptionExpired = false;
            if (result && result.plan) {
              subscriptionExpired = Date.now() > result.current_period_end;
            }
            const response = {
              appname: domain,
              subscription:
                snapshot.exists() && !subscriptionExpired
                  ? {
                      ...result,
                      plan:
                        result.plan === 'price_1OPOWsH7KxBKIk4ar4GVzFYV'
                          ? 'deluxe'
                          : result.plan,
                      realPlan:
                        result.realPlan === 'price_1OPOWsH7KxBKIk4ar4GVzFYV'
                          ? 'deluxe'
                          : result.realPlan,
                    }
                  : snapshot.val(),
            };

            if (response.subscription && response.subscription.offer) {
              return firebase
                .database()
                .ref(`offers/${response.subscription.offer}/discount_text`)
                .once('value')
                .then(s => s.val())
                .then(discountText => {
                  response.subscription.discountText = discountText;
                  return resolve(response);
                })
                .catch(reject);
            }

            return resolve(response);
          })
          .catch(reject);
      }),
  );
  return Promise.all(promises).then(subscriptions =>
    dispatch(hydrateSubscriptions(subscriptions)),
  );
};

const cancellingPlan = () => ({
  type: 'CANCELLING_PLAN',
});

const cancelPlanSuccess = () => (dispatch, getState) => {
  if (window.FM) {
    const org = getState().account.organisation;
    window.FM.trackCustomEvent('Subscription Cancelled', {
      email: org.default_email,
    });
  }
  return dispatch({ type: 'CANCEL_PLAN_SUCCESS' });
};

export const cancelPlan = (plan, appname) => (dispatch, getState) => {
  const profile = getState().account.staffProfile;
  window.location.href = `mailto:support@beezer.com?subject=Cancel my Beezer subscription&body=Dear Support,%0D%0A%0D%0AI would like to cancel my Beezer subscription. My registered email address is [add your registered email address here] and the name of my app is ${appname}.%0D%0A%0D%0ARegards,%0D%0A%0D%0A${profile.name}`;

  // const myHeaders = new Headers();
  // const payload = { plan, appname };

  // dispatch(cancellingPlan(plan, appname));

  // return firebase
  //   .auth()
  //   .currentUser.getIdToken()
  //   .then(token => {
  //     myHeaders.append('Content-Type', 'application/json');
  //     myHeaders.append('x-access-token', token);

  //     return fetch(`${process.env.REACT_APP_PAYMENT_API}/unsubscribe`, {
  //       method: 'post',
  //       headers: myHeaders,
  //       body: JSON.stringify(payload),
  //       mode: 'cors',
  //     });
  //   })
  //   .then(res => {
  //     if (res.status === 200) {
  //       dispatch(cancelPlanSuccess());
  //     } else {
  //       // should not need this (?)
  //       throw new Error('Cannot cancel your plan, an error has occured');
  //     }
  //   });
};

export const cancelAddon = addonDetails => dispatch => {
  const myHeaders = new Headers();
  const payload = addonDetails;

  dispatch(cancellingPlan());

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      if (payload && payload.componentType === 'Diary') {
        return fetch(
          `${process.env.REACT_APP_PAYMENT_API}/remove_customaddon_plan`,
          {
            method: 'post',
            headers: myHeaders,
            body: JSON.stringify(payload),
            mode: 'cors',
          },
        );
      }

      return fetch(`${process.env.REACT_APP_PAYMENT_API}/add_addon`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify(payload),
        mode: 'cors',
      });
    })
    .then(res => {
      if (res.status === 200) {
        setTimeout(() => {
          dispatch(cancelPlanSuccess());
        }, 3000);
      } else {
        // should not need this (?)
        throw new Error('Cannot cancel your plan, an error has occured');
      }
    });
};
export const cancelSpecialAddon = addonDetails => async dispatch => {
  const myHeaders = new Headers();
  const payload = addonDetails;

  dispatch(cancellingPlan());

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      return fetch(
        `${process.env.REACT_APP_PAYMENT_API}/remove_special_addon`,
        {
          method: 'post',
          headers: myHeaders,
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      );
    })
    .then(res => {
      if (res.status === 200) {
        setTimeout(() => {
          dispatch(getSpecialComponents());
          dispatch(cancelPlanSuccess());
          dispatch(
            openSnackbar(
              `Successfully cancelled ${
                componentNewNames[addonDetails.component]
                  ? componentNewNames[addonDetails.component]
                  : `${addonDetails.component}`
              } subscription.`,
            ),
          );
        }, 1000);

        // dispatch(closeDialog());
      } else {
        // should not need this (?)
        throw new Error(
          'Cannot cancel your subscription, an error has occured',
        );
      }
    });
};

export const launchCancelPlanDialog = (
  plan,
  appname,
  isAddon,
  addonDetails,
) => dispatch => {
  const options = {
    title: `Cancel ${isAddon ? 'Addon' : 'Subscription'}`,
    buttonLabel: `Cancel ${isAddon ? 'Addon' : 'Subscription'}`,
    cancelLabel: `Keep ${isAddon ? 'Addon' : 'Subscription'}`,
    text: `Are you sure you wish to cancel ${
      isAddon ? 'this addon subscription' : 'your subscription'
    } for the app ${appname}?
    Your subscription will expire after the current billing period comes to an end.`,
  };

  return dispatch(openDialog(options))
    .then(async val => {
      if (val.action === 'submit') {
        if (isAddon) {
          await dispatch(cancelAddon(addonDetails));
        } else {
          await dispatch(cancelPlan(plan, appname));
        }
      }
    })
    .then(() => {
      setTimeout(() => {
        dispatch(dialogActionComplete());
      }, 1000);
    })
    .catch(err => dispatch(reportDialogError(err)));
};

export const launchCancelSpecialAddonDialog = (
  component,
  period,
  subscriptionId,
  id,
  appname,
) => dispatch => {
  const options = {
    title: `Cancel Addon Subscription`,
    buttonLabel: `Cancel Addon`,
    cancelLabel: `Keep Addon`,
    text: `Are you sure you wish to cancel your ${period} subscription for this ${
      componentNewNames[component]
        ? componentNewNames[component]
        : `${component}`
    }?
    Your subscription will expire after the current billing period comes to an end.`,
  };

  return dispatch(openDialog(options))
    .then(async val => {
      if (val.action === 'submit') {
        await dispatch(
          cancelSpecialAddon({
            subscriptionId,
            id,
            appname,
            component,
            period,
          }),
        );
      }
    })
    .then(() => dispatch(dialogActionComplete()))
    .catch(err => dispatch(reportDialogError(err)));
};
