import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import IconMenu from 'material-ui/IconMenu';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import { withTranslation } from 'react-i18next';
import DialogConfirmation from '../dialogConfirmation';
import EmailDialog from '../emailDialog';
import themeStyles from '../../../_export.scss';

class ActionMenu extends Component {
  static propTypes = {
    onDownloadSelected: PropTypes.func,
    onEmailSelected: PropTypes.func,
    onDeleteSelected: PropTypes.func.isRequired,
    onEditSelected: PropTypes.func,
    onVisibilitySelected: PropTypes.func,
    onMoveSelected: PropTypes.func,
    onCancelSelected: PropTypes.func,
    selected: PropTypes.array.isRequired,
    text: PropTypes.string,
    customDeleteText: PropTypes.string,
    customDeleteText: PropTypes.string,
    onDuplicateSelected: PropTypes.func,
    onViewSelected: PropTypes.func,
  };

  static defaultProps = {
    text: 'items',
    onDownloadSelected: null,
    onVisibilitySelected: null,
    onMoveSelected: null,
    onDeleteSelected: null,
    onEditSelected: null,
    onEmailSelected: null,
    onCancelSelected: null,
    customDeleteText: null,
    customDeleteText: null,
    onDuplicateSelected: null,
    onViewSelected: null,
  };

  state = {
    confirmDeleteDialogOpen: false,
    confirmVisibilityDialogOpen: false,
    confirmEmailDialogOpen: false,
  };

  handleItemTouchTap = (e, child) => {
    const {
      onDownloadSelected,
      onMoveSelected,
      onEditSelected,
      onCancelSelected,
      onDuplicateSelected,
      onViewSelected,
    } = this.props;
    switch (child.props.value) {
      case 'DOWNLOAD':
        return onDownloadSelected();

      case 'DELETE':
        return this.setState({ confirmDeleteDialogOpen: true });

      case 'EMAIL_SELECTED':
        return this.setState({ confirmEmailDialogOpen: true });

      case 'VISIBILITY':
        return this.setState({ confirmVisibilityDialogOpen: true });

      case 'MOVE_GROUP':
        return onMoveSelected();

      case 'CANCEL':
        return onCancelSelected();

      case 'EDIT':
        return onEditSelected();

      case 'VIEW':
        return onViewSelected();

      case 'DUPLICATE':
        return onDuplicateSelected();

      default:
        return null;
    }
  };

  handleCloseDeleteDialog = () =>
    this.setState({ confirmDeleteDialogOpen: false });

  handleCloseVisibilityDialog = () =>
    this.setState({ confirmVisibilityDialogOpen: false });

  handleCloseEmailDialog = () =>
    this.setState({ confirmEmailDialogOpen: false });

  render() {
    const {
      text,
      selected,
      onDownloadSelected,
      onVisibilitySelected,
      onMoveSelected,
      onEmailSelected,
      onDeleteSelected,
      onEditSelected,
      onViewSelected,
      onCancelSelected,
      onDuplicateSelected,
      t,
    } = this.props;

    return (
      <div className="action-menu-container">
        <IconMenu
          onItemTouchTap={this.handleItemTouchTap}
          iconButtonElement={
            <IconButton
              style={{ padding: 0, width: 24, height: 24 }}
              onClick={e => e.stopPropagation()}
            >
              <MoreVertIcon color={themeStyles.primaryColor} />
            </IconButton>
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {onEditSelected && (
            <MenuItem
              value="EDIT"
              primaryText={t('Edit')}
              innerDivStyle={{ fontSize: 14 }}
              disabled={!selected.length}
            />
          )}
          {onViewSelected && (
            <MenuItem
              value="VIEW"
              primaryText={t('View')}
              innerDivStyle={{ fontSize: 14 }}
              disabled={!selected.length}
            />
          )}
          {onDuplicateSelected && (
            <MenuItem
              value="DUPLICATE"
              primaryText={t('Duplicate')}
              innerDivStyle={{ fontSize: 14 }}
              disabled={!selected.length}
            />
          )}
          {onDownloadSelected && (
            <MenuItem
              value="DOWNLOAD"
              primaryText={t('Download')}
              innerDivStyle={{ fontSize: 14 }}
              disabled={selected && !selected.length}
            />
          )}
          {onCancelSelected && (
            <MenuItem
              value="CANCEL"
              primaryText={t('Cancel')}
              innerDivStyle={{ fontSize: 14 }}
              disabled={selected && !selected.length}
            />
          )}
          {onVisibilitySelected && (
            <MenuItem
              value="VISIBILITY"
              primaryText={t('Change Visibility')}
              innerDivStyle={{ fontSize: 14 }}
              disabled={!selected.length}
            />
          )}
          {onMoveSelected && (
            <MenuItem
              value="MOVE_GROUP"
              primaryText={t('Move To Group')}
              innerDivStyle={{ fontSize: 14 }}
              disabled={!selected.length}
            />
          )}
          {onEmailSelected && (
            <MenuItem
              value="EMAIL_SELECTED"
              primaryText={t('Send Email')}
              innerDivStyle={{ fontSize: 14 }}
              disabled={!selected.length}
            />
          )}
          {onDeleteSelected && (
            <MenuItem
              value="DELETE"
              primaryText={t('Delete')}
              innerDivStyle={{ fontSize: 14 }}
              disabled={
                !selected.length ||
                (selected.length === 1 && selected[0] === 'default')
              }
            />
          )}
        </IconMenu>

        <DialogConfirmation
          open={this.state.confirmDeleteDialogOpen}
          onClose={this.handleCloseDeleteDialog}
          message={
            this.props.customDeleteText ||
            `${t(
              'Are you sure you want to permanently delete the selected',
            )} ${text}?`
          }
          action={onDeleteSelected}
        />
        <DialogConfirmation
          open={this.state.confirmCancelDialogOpen}
          onClose={this.handleCloseCancelDialog}
          message={`${t(
            'Are you sure you want to cancel the selected',
          )} ${text}?`}
          action={onCancelSelected}
        />
        <DialogConfirmation
          open={this.state.confirmVisibilityDialogOpen}
          onClose={this.handleCloseVisibilityDialog}
          message={t(
            'The selected notifications will be hidden from users in app',
          )}
          action={onVisibilitySelected}
        />

        <DialogConfirmation
          open={this.state.confirmEmailDialogOpen}
          onClose={this.handleCloseEmailDialog}
          message={t('Send email to all the selected vendors')}
          action={onEmailSelected}
        />

        <EmailDialog
          open={this.state.confirmEmailDialogOpen}
          onClose={this.handleCloseEmailDialog}
          message={t('Send email to all the selected vendors')}
          action={onEmailSelected}
        />
      </div>
    );
  }
}

export default withTranslation()(ActionMenu);
