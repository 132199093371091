import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Toggle from 'material-ui/Toggle';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import themeStyles from '../../../../../../_export.scss';
import { STRIPE_CURRENCIES } from '../../../../../../config/constants';
import {
  getAllPaidCourses,
  getMetaData,
  getStripeDetails,
  setMetaData,
} from '../../api';
import styles from './styles.module.scss';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { ActionWrapper } from '../../Elements';

const TrackSwitchedColor = { backgroundColor: themeStyles.primaryColorLight };
const ThumbSwitchedColor = { backgroundColor: themeStyles.primaryColor };

const Settings = props => {
  const { t } = useTranslation();
  const { appname } = props;
  const dispatch = useDispatch();
  const { mutateAsync, isLoading } = useMutation(setMetaData);
  const { data, error, isLoading: isLoadingData, isError } = useQuery(
    ['onlineCourseMetaData', { appname }],
    getMetaData,
  );
  const { data: stripeData, isLoading: stripeisLoading } = useQuery(
    ['onlineCourseStripeSecret', { appname }],
    getStripeDetails,
  );

  const { data: coursesData } = useQuery(
    ['onlineCourseItems', { appname }],
    getAllPaidCourses,
  );

  const isAppAdmin = useSelector(
    state => state.account.auth.profile.admin_appname || false,
  );

  const isSubAdminWithMulipleApps = useSelector(
    state =>
      (state.account.auth.profile.admin_appnames &&
        Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
      false,
  );

  const [paidCourses, setPaidCourses] = useState([]);
  const [currency, setCurrency] = useState('GBP');
  const [stripePublishableKey, setStripePublishableKey] = useState('');
  const [stripeApiTestMode, setStripeApiTestMode] = useState('');
  const [stripeSecretKey, setStripeSecretKey] = useState('');

  const [hasError, setHasError] = useState(false);
  const [hasStripeErrror, setHasStripeError] = useState(false);

  useEffect(() => {
    if (data) {
      setCurrency(data.currency || 'GBP');
      setStripePublishableKey(data.stripePublishableKey || '');
      setStripeApiTestMode(data.stripeApiTestMode || '');
    }
  }, [data]);

  useEffect(() => {
    if (coursesData) {
      setPaidCourses(coursesData);
    } else setPaidCourses([]);
  }, [coursesData]);

  useEffect(() => {
    if (stripeData) {
      setStripeSecretKey(stripeData.stripeSecretKey || '');
    }
  }, [stripeData]);

  const handleSubmit = async e => {
    e.preventDefault();
    setHasStripeError(false);

    if (!currency) {
      setHasError(true);
      return;
    }

    if ((!stripeSecretKey || !stripePublishableKey) && paidCourses.length > 0) {
      setHasStripeError(true);
      return;
    }

    if (hasError) setHasError(false);

    const data = {
      appname,
      currency,
      stripePublishableKey,
      stripeSecretKey,
      stripeApiTestMode,
    };
    await mutateAsync({ ...data });
    dispatch(openSnackbar(`Online Course settings saved successfully.`));
  };

  if (isLoadingData || stripeisLoading || isLoading) {
    return (
      <div style={{ margin: 'auto' }}>
        <CircularProgress color="primary" size={40} />;
      </div>
    );
  }

  if (isError) {
    return (
      <span>
        <Trans>Error</Trans>: {error.message}
      </span>
    );
  }
  return (
    <div className="scroll-container">
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className={styles.settingContainer}>
          {!isAppAdmin && !isSubAdminWithMulipleApps && (
            <a
              href="https://beezer.zendesk.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'block',
                color: themeStyles.primaryColor,
                marginBottom: 18,
                marginTop: 5,
                fontWeight: 'bold',
              }}
            >
              <span role="img" aria-label="Find out more">
                💡
              </span>{' '}
              <Trans>
                Find out more about how to set up the Bookings settings
              </Trans>
            </a>
          )}

          <div className={styles.settingTitle}>
            <Trans>Payment Settings</Trans>
          </div>
          <div className={styles.settingField}>
            <div>
              <Trans>Currency</Trans>
            </div>
            <FormControl style={{ minWidth: '300px' }}>
              <Select
                value={currency}
                onChange={e => setCurrency(e.target.value)}
                displayEmpty
                variant="standard"
              >
                {STRIPE_CURRENCIES.map(c => (
                  <MenuItem key={c.value} value={c.value}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
              {hasError && (
                <FormHelperText style={{ color: 'red' }}>
                  <Trans>This is required!</Trans>
                </FormHelperText>
              )}
            </FormControl>
          </div>

          <div className={styles.settingField}>
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => setStripeApiTestMode(b)}
              toggled={stripeApiTestMode}
              label={t('Test Mode')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
          </div>
          <div className={styles.settingField}>
            <TextField
              id="stripePublishableKey"
              variant="standard"
              className={styles.field}
              value={stripePublishableKey}
              label={t('Stripe Publishable key')}
              onChange={e => setStripePublishableKey(e.target.value)}
            />
          </div>
          <div className={styles.settingField}>
            <TextField
              id="stripeSecretKey"
              variant="standard"
              type="password"
              autoComplete="off"
              className={styles.field}
              value={stripeSecretKey}
              label={t('Stripe Secret key')}
              onChange={e => setStripeSecretKey(e.target.value)}
            />
          </div>
          <a
            href="https://support.stripe.com/questions/getting-started-with-stripe-create-or-connect-an-account"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'block',
              color: themeStyles.primaryColor,
              marginBottom: 5,
              marginTop: 5,
              fontWeight: 'bold',
            }}
          >
            <span role="img" aria-label="Find out more">
              💡
            </span>{' '}
            <Trans>How to create a Stripe Business account?</Trans>
          </a>

          {hasStripeErrror && (
            <span style={{ color: 'red', margin: '3vh 0' }}>
              Stripe API Keys are required for paid booking.
            </span>
          )}

          <ActionWrapper>
            <Button
              type="submit"
              variant="contained"
              className={styles.submitBtn}
            >
              {isLoading ? t('SAVING...') : t('SAVE')}
            </Button>
          </ActionWrapper>
        </div>
      </form>
    </div>
  );
};

Settings.propTypes = {
  appname: PropTypes.string.isRequired,
};

export default Settings;
