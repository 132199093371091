import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HotelTherapy from './HotelTherapy';
import {
  hotelTherapyItemChange,
  deleteHotelTherapySlot,
  toogleHotelTherapyModal,
  addItemToHotelTherapy,
  updateItemFromHotelTherapy,
  getAllBookings,
  getAllHotelTherapies,
  deleteHotelTherapy,
  duplicateHotelTherapy,
} from './actions';
import {
  getHotels,
  getPlMatchSupportCentre,
  getTherapists,
} from '../PlMatchSupport/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  appname: ownProps.match.params.appname,
  ...state.pgmol.plHotelTherapies,
  hotelBookingEnabled: true,
  hotels: state.pgmol.plMatchSupport.hotels,
  therapists: state.pgmol.plMatchSupport.therapists,
  hotelTherapies: state.pgmol.plHotelTherapies.allHotelTherapies,
  bookings: state.pgmol.plHotelTherapies.allBookings,
  isLoading: state.pgmol.plHotelTherapies.isLoading,
  hotelTherapyItem: state.pgmol.plHotelTherapies.hotelTherapyItem,
  plMatchSupportData: state.pgmol.plMatchSupport,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleBooking: () => {},
  hotelTherapyItemChange: item => dispatch(hotelTherapyItemChange(item)),
  toogleHotelTherapyModal: value => dispatch(toogleHotelTherapyModal(value)),
  getTherapists: () => dispatch(getTherapists(ownProps.match.params.appname)),
  getHotels: () => dispatch(getHotels(ownProps.match.params.appname)),
  deleteHotelTherapy: selected =>
    dispatch(deleteHotelTherapy(ownProps.match.params.appname, selected)),
  duplicateHotelTherapy: selected =>
    dispatch(duplicateHotelTherapy(ownProps.match.params.appname, selected)),
  addItemToHotelTherapy: (value, callbackFn) =>
    dispatch(addItemToHotelTherapy(value, callbackFn)),
  updateItemFromHotelTherapy: (value, callbackFn) =>
    dispatch(updateItemFromHotelTherapy(value, callbackFn)),

  getAllHotelTherapies: () =>
    dispatch(getAllHotelTherapies(ownProps.match.params.appname)),
  getAllBookings: () => dispatch(getAllBookings(ownProps.match.params.appname)),
  getPlMatchSupportCentre: () =>
    dispatch(getPlMatchSupportCentre(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HotelTherapy),
);
