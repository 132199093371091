import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';

import {
  TOGGLE_PL_MATCH_SUPPORT_MODAL,
  NEW_PL_MATCH_SUPPORT_CENTRE_ADDED,
  ADD_NEW_PL_MATCH_SUPPORT_CENTRE,
  DESELECT_ALL_PL_MATCH_SUPPORT_CENTRES,
  FILTER_PL_MATCH_SUPPORT_CENTRE_DATA,
  NEW_PL_MATCH_SUPPORT_FIELD_CHANGE,
  OPEN_PL_MATCH_SUPPORT_CENTRE_DIALOG_UPDATE_DATA,
  RECEIVE_PL_MATCH_SUPPORT_CENTRE,
  REQUEST_PL_MATCH_SUPPORT_CENTRE,
  SELECT_ALL_PL_MATCH_SUPPORT_CENTRES,
  SELECT_PL_MATCH_SUPPORT_CENTRE,
  CLEAR_HOTEL_NOT_AVAILABLE_FIELDS,
  CLEAR_THERAPIST_NOT_AVAILABLE_FIELDS,
  TOGGLE_PL_SNACKBAR,
  SET_HOTELS_DATA,
  SET_THERAPIST_DATA,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  modalOpen: false,
  selectAll: false,
  isUpdate: false,
  therapists: [],
  hotels: [],
  rowId: '',
  location: {
    value: '',
    isValid: null,
  },
  hotel_available: {
    value: true,
    isValid: null,
  },
  hotel: {
    value: '',
    isValid: null,
  },
  hotel_lat: {
    value: '',
    isValid: null,
  },
  hotel_lng: {
    value: '',
    isValid: null,
  },
  hotel_phone: {
    value: '',
    isValid: null,
  },
  therapist_available: {
    value: true,
    isValid: null,
  },
  therapist: {
    value: '',
    isValid: null,
  },
  therapist_phone: {
    value: '',
    isValid: null,
  },
  therapist_email: {
    value: '',
    isValid: null,
  },
  availability: {
    value: '',
    isValid: null,
  },
  snackbarOpen: false,
  hotelId: null,
  therapistId: null,
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = filterHolder.replace(/[^a-zA-Z0-9 ]/g, '');

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'gi');

      return (
        rgx.test(chunk.location) ||
        rgx.test(chunk.hotel) ||
        rgx.test(chunk.therapist) ||
        rgx.test(chunk.hotel_phone) ||
        rgx.test(chunk.therapist_email) ||
        rgx.test(chunk.therapist_phone)
      );
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const plMatchSupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PL_MATCH_SUPPORT_MODAL: {
      if (action.value) {
        return {
          ...state,
          modalOpen: action.value,
        };
      }
      return {
        ...state,
        modalOpen: action.value,
        isUpdate: false,
        rowId: '',
        location: {
          value: '',
          isValid: null,
        },
        hotel: {
          value: '',
          isValid: null,
        },
        hotel_available: {
          value: true,
          isValid: null,
        },
        hotel_lat: {
          value: '',
          isValid: null,
        },
        hotel_lng: {
          value: '',
          isValid: null,
        },
        hotel_phone: {
          value: '',
          isValid: null,
        },
        therapist_available: {
          value: true,
          isValid: null,
        },
        therapist: {
          value: '',
          isValid: null,
        },
        therapist_phone: {
          value: '',
          isValid: null,
        },
        therapist_email: {
          value: '',
          isValid: null,
        },
        availability: {
          value: '',
          isValid: null,
        },
        snackbarOpen: false,
      };
    }

    case NEW_PL_MATCH_SUPPORT_FIELD_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case ADD_NEW_PL_MATCH_SUPPORT_CENTRE:
      return {
        ...state,
        pending: true,
      };

    case NEW_PL_MATCH_SUPPORT_CENTRE_ADDED:
      return {
        ...state,
        modalOpen: false,
        pending: false,
        isUpdate: false,
        rowId: '',
        location: {
          value: '',
          isValid: null,
        },
        hotel: {
          value: '',
          isValid: null,
        },
        hotel_available: {
          value: '',
          isValid: null,
        },
        hotel_lat: {
          value: '',
          isValid: null,
        },
        hotel_lng: {
          value: '',
          isValid: null,
        },
        hotel_phone: {
          value: '',
          isValid: null,
        },
        therapist_available: {
          value: true,
          isValid: null,
        },
        therapist: {
          value: '',
          isValid: null,
        },
        therapist_phone: {
          value: '',
          isValid: null,
        },
        therapist_email: {
          value: '',
          isValid: null,
        },
        availability: {
          value: '',
          isValid: null,
        },
        snackbarOpen: false,
      };

    case REQUEST_PL_MATCH_SUPPORT_CENTRE:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case SET_HOTELS_DATA:
      return {
        ...state,
        hotels: action.value,
      };
    case SET_THERAPIST_DATA:
      return {
        ...state,
        therapists: action.value,
      };
    case RECEIVE_PL_MATCH_SUPPORT_CENTRE:
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };

    case SELECT_PL_MATCH_SUPPORT_CENTRE: {
      const prevState =
        (state.filteredChunks[action.key] &&
          state.filteredChunks[action.key].selected) ||
        false;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_PL_MATCH_SUPPORT_CENTRES: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: !chunk.default,
      }));
      const selected = _union(
        state.selected,
        Object.keys(filteredChunks).filter(chunk => chunk !== 'default'),
      );

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_PL_MATCH_SUPPORT_CENTRES:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case FILTER_PL_MATCH_SUPPORT_CENTRE_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };

    case OPEN_PL_MATCH_SUPPORT_CENTRE_DIALOG_UPDATE_DATA:
      return {
        ...state,
        modalOpen: true,
        isUpdate: true,
        rowId: action.key,
        location: {
          value: action.data.location || '',
          isValid: true,
        },

        hotel: {
          value: action.data.hotel || '',
          isValid: null,
        },
        hotel_available: {
          value:
            action.data.hotel_available !== undefined
              ? action.data.hotel_available
              : true,
          isValid: null,
        },
        hotel_phone: {
          value: action.data.hotel_phone || '',
          isValid: null,
        },
        hotel_lat: {
          value: action.data.hotel_lat || '',
          isValid: null,
        },
        hotel_lng: {
          value: action.data.hotel_lng || '',
          isValid: null,
        },
        therapist_available: {
          value:
            action.data.therapist_available !== undefined
              ? action.data.therapist_available
              : true,
          isValid: null,
        },
        therapist: {
          value: action.data.therapist || '',
          isValid: null,
        },
        therapist_phone: {
          value: action.data.therapist_phone || '',
          isValid: null,
        },
        therapist_email: {
          value: action.data.therapist_email || '',
          isValid: null,
        },
        availability: {
          value: action.data.availability || '',
          isValid: null,
        },
        snackbarOpen: false,
        hotelId: action.data.hotelId || null,
        therapistId: action.data.therapistId || null,
      };

    case CLEAR_HOTEL_NOT_AVAILABLE_FIELDS:
      return {
        ...state,
        hotel: {
          value: '',
          isValid: null,
        },
        hotel_lat: {
          value: '',
          isValid: null,
        },
        hotel_lng: {
          value: '',
          isValid: null,
        },
        hotel_phone: {
          value: '',
          isValid: null,
        },
        therapist_available: {
          value: true,
          isValid: null,
        },
        therapist: {
          value: '',
          isValid: null,
        },
        therapist_phone: {
          value: '',
          isValid: null,
        },
        therapist_email: {
          value: '',
          isValid: null,
        },
        availability: {
          value: '',
          isValid: null,
        },
        snackbarOpen: false,
        hotelId: null,
        therapistId: null,
      };

    case CLEAR_THERAPIST_NOT_AVAILABLE_FIELDS:
      return {
        ...state,
        therapist: {
          value: '',
          isValid: null,
        },
        therapist_phone: {
          value: '',
          isValid: null,
        },
        therapist_email: {
          value: '',
          isValid: null,
        },
        availability: {
          value: '',
          isValid: null,
        },
        snackbarOpen: false,
      };
    case TOGGLE_PL_SNACKBAR: {
      return {
        ...state,
        snackbarOpen: !state.snackbarOpen,
      };
    }
    default:
      return state;
  }
};

export default plMatchSupportReducer;
