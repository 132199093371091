import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import { Trans, withTranslation } from 'react-i18next';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import styled from 'styled-components';
import {
  RadioGroup,
  Radio,
  InputLabel,
  FormControlLabel,
  MenuItem,
  FormControl,
  Select,
  FormLabel,
  TextField as MuiTextField,
} from '@material-ui/core';
import Toggle from '../../../../../../app/components/toggle';
import ImageUpload from '../../../../../editor/internals/imageUpload';
import SectionHeader from './SectionHeader';
import NotificationModal from '../../../../../../app/components/sidebar/notificationModal';
import { FlatButton } from '../../../../../../app/components/buttons';

import themeStyles from '../../../../../../_export.scss';
import '../../styles.scss';
import './style.scss';

class VerificationPage extends React.Component {
  componentDidMount() {
    this.props.loadLayoutData();
  }

  state = {
    showNotification: false,
  };

  closeNotification = () => {
    this.setState({ showNotification: false });
  };

  openNotification = () => {
    this.setState({ showNotification: true });
  };

  notificationText = () => {
    return (
      <p style={{ marginTop: '8px' }}>
        Please note that after you enable email verification any new and
        existing users will have to verify their email address before they can
        continue using the app.{' '}
      </p>
    );
  };

  dropdownNavigationOptions = () =>
    this.props.pages.map(
      (page, index) =>
        page.type !== 'Popup' && (
          <MenuItem key={page.path} value={index ? page.path : '/'}>
            {page.title}
          </MenuItem>
        ),
    );

  isValidUrl = url => {
    const exp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(exp);
    return url.match(regex);
  };

  isLinkDisabled = () => {
    const { link } = this.props;
    return !link || (link && !link.enabled);
  };

  renderToggle = () => (
    <Toggle
      onToggle={(e, isToggled) => {
        this.props.handleNestedChange('link/enabled', isToggled);
      }}
      toggled={this.props.link && this.props.link.enabled}
    />
  );

  toggleEnableVerification = value => {
    const { toggleVerificationPage } = this.props;

    if (value) {
      this.setState({ showNotification: true });
    } else {
      toggleVerificationPage(value);
    }
  };

  actions = () => [
    <FlatButton
      style={{ margin: 20 }}
      label="Cancel"
      secondaryWhite
      onClick={() => {
        this.props.toggleVerificationPage(false);
        this.closeNotification();
      }}
    />,
    <FlatButton
      style={{ margin: 20 }}
      label="Enable"
      primary
      onClick={() => {
        this.props.toggleVerificationPage(true);
        this.closeNotification();
      }}
    />,
  ];
  render() {
    const {
      updateField,
      headerText,
      subHeaderText,
      link,
      handleNestedChange,
      enabled,
      toggleVerificationPage,
      imageSrc,
      appname,
      t,
    } = this.props;

    const { showNotification } = this.state;
    const notification = this.notificationText();

    const SectionHeaderTitle = styled.h5`
      margin: 0;
      color: #281fd6;
      font-weight: 700;
      font-size: 14px;
      font-family: Montserrat, sans-serif;
      text-align: left;
    `;

    const SectionHeaderContainer = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 0px;
      padding-right: 18px;
      padding-top: 18px;
      margin-top: ${props => props.borderTop && '24px'};
      margin-bottom: ${props => props.borderBottom && '4px'};
      padding-bottom: ${props => props.borderBottom && '20px'};
      border-top: ${props => props.borderTop && '1px solid rgba(0, 0, 0, 0.2)'};
      border-bottom: ${props =>
        props.borderBottom && '1px solid rgba(0, 0, 0, 0.2)'};
    `;

    const ImageTitle = () => (
      <SectionHeaderContainer>
        <SectionHeaderTitle>
          <Trans>Upload an image for the verification page</Trans>
        </SectionHeaderTitle>
      </SectionHeaderContainer>
    );

    return (
      <React.Fragment>
        <div className="auth-panel">
          <div className="auth-panel-title">
            <Trans>Email Verification</Trans>
          </div>
          <div className="auth-panel-subTitle">
            <Trans>Restricted access page</Trans>
          </div>

          <div className="auth-panel">
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => this.toggleEnableVerification(b)}
              toggled={enabled}
              label={t('Enabled')}
              trackSwitchedStyle={{
                backgroundColor: themeStyles.primaryColorLight,
              }}
              thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
            />
            <p>
              <Trans>
                After the user signs up, the verification page will be shown,
                preventing access to other pages.
              </Trans>
            </p>

            {enabled ? (
              <React.Fragment>
                <TextField
                  id="header"
                  value={headerText}
                  className="auth-panel-privacy-url-textbox"
                  floatingLabelText={t('Header Text')}
                  underlineShow
                  onChange={(event, value) => updateField('headerText', value)}
                />
                <TextField
                  id="subhrader"
                  value={subHeaderText}
                  className="auth-panel-privacy-url-textbox"
                  floatingLabelText={t('Sub Header Text')}
                  underlineShow
                  onChange={(event, value) =>
                    updateField('subHeaderText', value)
                  }
                />
                <ImageTitle />
                <ImageUpload
                  label={t('Your verification page icon')}
                  targetId="verification_page_icon"
                  caption={t(
                    'This image will be displayed in the verification page.',
                  )}
                  title={t('Verification page image')}
                  src={imageSrc}
                  firebaseUrl={`app_groups/${appname}/groups/default/verification_page/image_src`}
                  fileType="png"
                  height="320px"
                  width="320px"
                  noDelete
                />
                <SectionHeader
                  title={t('Navigation Link')}
                  actions={this.renderToggle()}
                />
                <div className="app-link-menu-wrapper">
                  <TextField
                    id="app-navigation-label"
                    value={link && link.label}
                    onChange={(event, value) =>
                      handleNestedChange('link/label', value)
                    }
                    placeholder={t('Button Label')}
                    disabled={this.isLinkDisabled()}
                  />
                  <div className="app-link-container">
                    <RadioButtonGroup
                      className="radio-wrapper"
                      name="buttonLink"
                      valueSelected={link && link.type}
                      onChange={(event, value) => {
                        handleNestedChange('link/type', value);
                      }}
                    >
                      <RadioButton
                        id="web"
                        value="web"
                        disabled={this.isLinkDisabled()}
                      />
                    </RadioButtonGroup>

                    <div className="link-radio-option-wrapper">
                      <TextField
                        id="custom-app-webpage"
                        value={link && link.type && link.web}
                        onChange={(e, value) =>
                          handleNestedChange('link/web', value)
                        }
                        placeholder={t('Webpage Link')}
                        disabled={
                          this.isLinkDisabled() || false || link.type !== 'web'
                        }
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>

          <div className="auth-panel-subTitle">
            <Trans>Redirect Page</Trans>
          </div>
          <div className="auth-panel">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">
                <Trans>Select redirect page type</Trans>
              </FormLabel>
              <RadioGroup
                aria-label="redirect-page"
                name="redirect-page"
                value={this.props.redirectVerificationPageType}
                onChange={e =>
                  this.props.updateRedirectPageField({
                    field: 'type',
                    value: e.target.value,
                  })
                }
              >
                <div className="radio-select-setting">
                  <FormControlLabel
                    value="appPage"
                    control={<Radio />}
                    label={t('App Page')}
                  />
                  <FormControl
                    style={{ width: '40%', maxWidth: '400px' }}
                    disabled={
                      this.props.redirectVerificationPageType !== 'appPage'
                    }
                  >
                    <InputLabel
                      id="page-select-label"
                      style={{ left: '-14px' }}
                    >
                      <Trans>Select a page</Trans>
                    </InputLabel>
                    <Select
                      labelId="page-select-label"
                      id="page-select"
                      value={this.props.redirectVerificationAppPage}
                      onChange={e =>
                        this.props.updateRedirectPageField({
                          field: 'appPage',
                          value: e.target.value,
                        })
                      }
                      variant="standard"
                    >
                      {this.dropdownNavigationOptions()}
                    </Select>
                  </FormControl>
                </div>
                <div className="radio-select-setting">
                  <FormControlLabel
                    value="url"
                    control={<Radio />}
                    label={t('URL')}
                  />
                  <MuiTextField
                    id="redirect-page-url"
                    label={t('Webpage Link')}
                    variant="standard"
                    style={{
                      width: '40%',
                      maxWidth: '400px',
                    }}
                    value={this.props.redirectVerificationPageUrl}
                    onChange={e =>
                      this.props.updateRedirectPageField({
                        field: 'url',
                        value: e.target.value,
                      })
                    }
                    error={
                      this.props.redirectVerificationPageUrl &&
                      !this.isValidUrl(this.props.redirectVerificationPageUrl)
                    }
                    helperText={
                      this.props.redirectVerificationPageUrl &&
                      !this.isValidUrl(
                        this.props.redirectVerificationPageUrl,
                      ) &&
                      t('Invalid website url')
                    }
                    disabled={this.props.redirectVerificationPageType !== 'url'}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <NotificationModal
          showNotification={showNotification}
          closeNotification={this.closeNotification}
          text={notification}
          action={this.actions()}
        />
      </React.Fragment>
    );
  }
}

VerificationPage.propTypes = {
  updateField: PropTypes.func.isRequired,
  imageSrc: PropTypes.string.isRequired,
  appname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

VerificationPage.defaultProps = {
  profileEnabled: true,
  signupEnabled: true,
};

export default withTranslation()(VerificationPage);
