import firebase from '../../../../firebase';

export const recordAccessToken = (code, state, history) => async (
  dispatch,
  getState,
) => {
  const { profile } = getState().account;
  // console.log({state: getState()}, 'sjd321');
  const dataRef = firebase.database().ref(profile.lastEdited);
  const headers = new Headers({
    Accept: 'application/json, text/javascript, */*; q=0.01',
    'Content-Type': 'application/json',
  });
  const data = {
    client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
    client_secret: process.env.REACT_APP_FACEBOOK_APP_SECRET,
    grant_type: 'authorization_code',
    redirect_uri: process.env.REACT_APP_INSTAGRAM_REDIRECT_URI,
    code,
  };
  console.log({ data, state, dataRef: profile.lastEdited });
  try {
    await fetch(`${process.env.REACT_APP_SOCIAL_MEDIA_API}/instagram-token`, {
      method: 'post',
      headers,
      body: JSON.stringify({
        data,
        state,
      }),
      mode: 'cors',
    })
      .then(res => res.json())
      .then(async resData => {
        console.log({ resData }, { dataRef });
        if (resData.status === 200 && resData.long_token_data.access_token) {
          console.log('123...');
          await dataRef
            .child('access_token')
            .set(resData.long_token_data.access_token);
          await dataRef
            .child('expires_in')
            .set(resData.long_token_data.expires_in);
        } else {
          await dataRef.child('access_token').set('error');
        }
        history.go(-3);
      });
  } catch (error) {
    console.error(error);
  }
};
