import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  margin-top: 20px;
  padding: 9px;
  width: 100% !important;
  font-size: 20px;
  font-weight: bold;
  color: ${props => `${props.theme.primaryTextColor}`};
  height: 100% !important;
  background-color: ${props =>
    !props.disabled ? `${props.theme.primaryColor} !important` : '#cccccc'};
`;
