import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const Coupons = props => {
  const { coupons, setCoupons } = props;
  const { t } = useTranslation();

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...coupons];
    list[index][name] = value;
    setCoupons(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    setCoupons(coupons => [...coupons.filter((_, i) => index !== i)]);
  };

  // handle click event of the Add button
  const handleAddClick = e => {
    e.preventDefault();

    setCoupons([...coupons, { couponCode: '', discountRate: '' }]);
  };

  return (
    <div>
      <h4>
        <Trans>Coupons</Trans>
      </h4>

      {coupons.map((x, i) => {
        return (
          <div className={styles.input_container}>
            <input
              name="couponCode"
              className={styles.coupon_code_input}
              placeholder={t('Coupon Code')}
              value={x.couponCode}
              onChange={e => handleInputChange(e, i)}
            />
            <input
              name="discountRate"
              className={styles.discount_input}
              placeholder={`Discount rate in % (10%)`}
              value={x.discountRate}
              type="number"
              min="0"
              max="100"
              onChange={e => handleInputChange(e, i)}
            />
            <div className={styles.buttons_container}>
              {coupons.length !== 1 && (
                <button
                  className={styles.button}
                  onClick={e => handleRemoveClick(e, i)}
                >
                  <Trans>Remove</Trans>
                </button>
              )}
              {coupons.length - 1 === i && (
                <button
                  className={styles.button}
                  onClick={e => handleAddClick(e)}
                >
                  <Trans>Add</Trans>
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Coupons;
