import {
  RECEIVE_MARKETPLACE_STATUS,
  RECEIVE_FOOD_ORDER_STATUS,
  RECEIVE_BOOKING_STATUS,
  RECEIVE_ONLINE_COURSE_STATUS,
} from '../types';

const initialState = {
  marketplaceEnabled: false,
  foodOrderEnabled: false,
  bookingEnabled: false,
  onlineCourseEnabled: false,
};

const structure = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_MARKETPLACE_STATUS: {
      return {
        ...state,
        marketplaceEnabled: action.value,
      };
    }
    case RECEIVE_FOOD_ORDER_STATUS: {
      return {
        ...state,
        foodOrderEnabled: action.value,
      };
    }
    case RECEIVE_BOOKING_STATUS: {
      return {
        ...state,
        bookingEnabled: action.value,
      };
    }
    case RECEIVE_ONLINE_COURSE_STATUS: {
      return {
        ...state,
        onlineCourseEnabled: action.value,
      };
    }

    default:
      return state;
  }
};

export default structure;
