import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Range from '../../../../app/components/range';
import Subtitle from '../../../../app/components/subtitle';
import { Trans, withTranslation } from 'react-i18next';

class ImagePositionMenu extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    onDesignChange: PropTypes.func.isRequired,
  };

  handleDesign = prop => (e, value) => this.props.onDesignChange(prop, value);

  render() {
    const { component, t } = this.props;

    return (
      component && (
        <div>
          <Subtitle className={!component.design.enabled && 'disabled'}>
            <Trans>Image Position</Trans>
          </Subtitle>
          <Range
            min={0}
            max={100}
            step={1}
            disabled={!component.design.enabled}
            label={`${t('Horizontal')}: ${component.design.positionX}%`}
            value={component.design.positionX}
            onChange={this.handleDesign('positionX')}
            width="320px"
          />
          <Range
            min={0}
            max={100}
            step={1}
            disabled={!component.design.enabled}
            label={`${t('Vertical')}: ${component.design.positionY}%`}
            value={component.design.positionY}
            onChange={this.handleDesign('positionY')}
            width="320px"
          />
        </div>
      )
    );
  }
}

export default withTranslation()(ImagePositionMenu);
