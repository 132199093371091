import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { sortArray } from 'utils';
import TryAgain from 'app/components/tryAgain';
import ActionMenu from 'app/components/actionMenu';
import ResultsTable from 'app/components/resultsTable';
import TherapistsModal from './TherapistsModal';
import themeStyles from '../../../../_export.scss';
import TableCheckbox from '../../../memberLogin/components/tableCheckbox';
import { CircularProgress } from '@material-ui/core';

const isCheckboxDisabled = (error, pending) => error && !pending;

const Therapists = props => {
  const {
    appname,
    plTherapists,
    onSelectAllTherapists,
    onDeselectAllTherapists,
    onDeleteSelected,
    onUpdatePlTherapist,
    onSelect,
    getTherapists,
    toggleTherapistModal,
    submitTherapist,
    isImageUploaded,
  } = props;

  const {
    modalOpen,
    filteredChunks,
    error,
    pending,
    selected,
    selectAll,
    chunks,
    items,
    therapist,
  } = plTherapists;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getTherapists();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleModalClose = () => {
    toggleTherapistModal(false);
  };

  const getTitle = (centreSelected, PlSupportCentres) => (
    <span>
      PL Match Day Support:{' '}
      {centreSelected.length
        ? `${centreSelected.length} / ${Object.keys(PlSupportCentres).length}`
        : Object.keys(PlSupportCentres).length}
    </span>
  );

  const getSortedItems = () => {
    if (!filteredChunks || Object.keys(filteredChunks).length === 0) {
      return [];
    }
    let sortedItems = sortArray(
      Object.entries(filteredChunks),
      'createdAt',
      false,
    );
    sortedItems = sortedItems.map(entity => entity[0]);
    return sortedItems;
  };

  const getSelectAllCheckBox = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllTherapists() : onDeselectAllTherapists()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          text={'plTherapist'}
          customDeleteText="Are you sure you wish to delete?"
        />
      </div>
    );
  };

  const body = error ? (
    <TryAgain message="Oops, something went wrong..." onRetry={getTherapists} />
  ) : (
    <ResultsTable
      items={getSortedItems()}
      chunks={filteredChunks}
      loading={pending}
      pending={pending}
      error={
        !filteredChunks || Object.keys(filteredChunks).length === 0
          ? 'yes'
          : null
      }
      emptyMessage={`Sorry, no results found.`}
      onRowTouchTap={rowId =>
        onUpdatePlTherapist(rowId, { ...filteredChunks[rowId], key: rowId })
      }
      pgmolTable
      columns={[
        {
          id: 'name',
          header: 'Name',
          classNames: 'col-xs-3 pl-table-text-left',
        },
        {
          id: 'email',
          header: 'Email',
          classNames: 'col-xs-5 pl-table-text-left',
          containerElement: ({ value }) => (
            <pre className="pl-match-support-hotel-address">{value}</pre>
          ),
        },
        {
          id: 'phone',
          header: 'Mobile',
          classNames: 'col-xs-3 pl-table-text-left',
        },

        {
          id: 'selected',
          header: getSelectAllCheckBox(),
          classNames: `col-xs-1 ${items.length < 4 ? 'fix-left' : 'selected'}`,
          containerElement: ({ value, rowId }) =>
            rowId !== 'default' && (
              <>
                <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
                <ActionMenu
                  selected={[rowId]}
                  onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
                  onEditSelected={() =>
                    onUpdatePlTherapist(rowId, filteredChunks[rowId])
                  }
                  text={'plTherapist'}
                  customDeleteText="Are you sure you wish to delete?"
                />
              </>
            ),
        },
      ]}
    />
  );

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  return (
    <section>
      <div className="pgmol-table-container">{body}</div>

      {modalOpen && (
        <TherapistsModal
          appname={appname}
          open={modalOpen}
          therapist={therapist}
          allTherapistsData={chunks}
          toggleTherapistModal={handleModalClose}
          submitTherapist={submitTherapist}
          isImageUploaded={isImageUploaded}
        />
      )}
    </section>
  );
};

export default Therapists;
