import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Checkbox from 'material-ui/Checkbox';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton';

import Container from 'app/components/container';
import TextEditor from 'app/components/textEditor';
import Toggle from 'app/components/toggle';
import Range from 'app/components/range';
import Subtitle from 'app/components/subtitle';
import ColorPicker from 'modules/editor/components/colorPicker';
import DesignToggle from 'modules/editor/components/designToggle';
import ButtonDesignMenu from 'modules/editor/components/buttonDesignMenu';
import ImageDesignMenu from 'modules/editor/components/imageDesignMenu';
import { Tabs, Tab } from '../../../../../components/tabs';
import SectionHeader from '../../../../../../editor/components/sectionHeader';

import { getAllCourses } from '../../../../../../../modules/ecommerce/modules/onlineCourses/api';
import { objectToArray } from '../../../../../../../utils';

import './styles.css';
import { ButtonWrapper } from './elements';

const OnlineCourse = props => {
  const {
    data,
    match,
    component,
    onChange,
    appname,
    onDesignChange,
    onButtonDesignChange,
    design,
  } = props;

  const { t } = useTranslation();

  const toolbarButtons = {
    fonts: true,
    fontSize: true,
    bold: true,
    italic: true,
    underline: true,
    url: true,
    image: true,
    video: true,
    orderedList: true,
    unorderedList: true,
    negativeIntent: true,
    positiveIndent: true,
    textAlign: true,
    textColor: true,
    textBackground: true,
    clearTextFormatting: true,
    codeBlock: true,
    blockquote: true,
  };

  const [checkedItems, setCheckedItems] = useState([]);
  const [courseData, setCourseData] = useState([]);

  const { data: courses, error, isLoading, isError } = useQuery(
    ['courseItems', { appname }],
    getAllCourses,
  );

  const handleChange = prop => value => onChange(prop, value);

  useEffect(() => {
    if (courses) {
      setCourseData(objectToArray(courses));
    } else setCourseData([]);
  }, [courses]);

  useEffect(() => {
    setCheckedItems(data.courseList ? data.courseList : []);
  }, [data]);

  const saveCourses = () => {
    handleChange('courseList')(checkedItems);
  };

  const handleOnlineCourseChange = (checked, key) => {
    const items = checkedItems.filter(item => item === key);
    const otherItems = checkedItems.filter(item => item !== key);
    if (items.length > 0) {
      setCheckedItems([...otherItems]);
    } else {
      setCheckedItems([...checkedItems, key]);
    }
  };

  const handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onDesignChange(prop, rgba);
  };

  const handleDesign = prop => (e, value) => onDesignChange(prop, value);

  const buttonToggle = (
    <Toggle
      onToggle={handleDesign('/buttonDesign/enabled')}
      toggled={component.design.buttonDesign.enabled}
      disabled={!component.design.enabled}
    />
  );

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <div className="onlinecourse-wrapper">
      <Tabs>
        <Tab label={t('content')}>
          <SectionHeader
            title={t('Title')}
            tooltip={t('A Title for the online courses')}
            borderTop
          />
          <TextEditor
            id="title"
            value={data.title}
            onChange={handleChange('title')}
            placeholder={t('Title')}
            toolbar={toolbarButtons}
          />
          <SectionHeader
            title={t('Subtitle')}
            tooltip={t('A Subtitle for the online courses')}
            borderTop
          />
          <TextEditor
            id="subtitle"
            value={data.subtitle}
            onChange={handleChange('subtitle')}
            placeholder={t('Subtitle')}
            toolbar={toolbarButtons}
          />

          <Container>
            <SectionHeader
              title={t('Online Courses')}
              tooltip={t('Select online courses to display')}
            />

            {courseData.length > 0 && (
              <div className="container">
                <p>
                  <Trans>Select Online Courses to display</Trans>
                </p>
              </div>
            )}
            <div className="container">
              <div className="items">
                {courseData.length > 0 ? (
                  courseData.map(course => {
                    return (
                      course.value.isActive && (
                        <div className="item">
                          <Checkbox
                            label={course.value.title}
                            style={{
                              paddingLeft: 18,
                              paddingRight: 18,
                              marginTop: 18,
                              marginBottom: 18,
                            }}
                            labelStyle={{ fontSize: 14 }}
                            checked={checkedItems.includes(course.value.key)}
                            onCheck={(e, checked) =>
                              handleOnlineCourseChange(
                                checked,
                                course.value.key,
                              )
                            }
                          />
                        </div>
                      )
                    );
                  })
                ) : (
                  <div>
                    <p>
                      <Trans>No Online Courses yet.</Trans>{' '}
                      <Link
                        to={`/${match.params.appname}/ecommerce/online-courses/courses`}
                      >
                        <Trans>Click here</Trans>
                      </Link>{' '}
                      <Trans>to create an online course</Trans>
                    </p>
                  </div>
                )}
              </div>
            </div>

            {courseData.length > 0 && (
              <div className="container">
                <ButtonWrapper onClick={() => saveCourses()}>
                  <Trans>Save</Trans>
                </ButtonWrapper>
              </div>
            )}
          </Container>
        </Tab>

        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={onDesignChange}
            />
            {/* <ColorPicker
              label={t('Text Color')}
              color={component.design.textColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('textColor')}
            />
            <ColorPicker
              label={t('Background Color')}
              color={design.backgroundColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('backgroundColor')}
            /> */}

            {/* <SectionHeader
              title={t('Button')}
              actions={buttonToggle}
              tooltip={t('Define the styles for your button')}
              borderTop
            /> */}
            <ButtonDesignMenu
              component={component}
              onDesignChange={onButtonDesignChange}
            />
          </Container>
        </Tab>
      </Tabs>
    </div>
  );
};

OnlineCourse.propTypes = {
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
  onDesignChange: PropTypes.func.isRequired,
  onButtonDesignChange: PropTypes.func.isRequired,
  design: PropTypes.object.isRequired,
};
export default OnlineCourse;
