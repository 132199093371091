import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import MenuItem from 'material-ui/MenuItem';
import { Trans, withTranslation } from 'react-i18next';

import SelectField from '../../../../../../app/components/selectField';
import Toggle from '../../../../../../app/components/toggle';
import SectionHeader from './SectionHeader';

import '../../styles.scss';
import themeStyles from '../../../../../../_export.scss';

const TrackSwitchedColor = { backgroundColor: themeStyles.primaryColorLight };
const ThumbSwitchedColor = { backgroundColor: themeStyles.primaryColor };

const layout = [
  {
    name: 'Display Standard Restricted Access Page',
    value: 'restricted-access',
  },
  {
    name: 'Display Subscriptions',
    value: 'subscriptions',
  },
  {
    name: 'Display Subscriptions Specific to the Page',
    value: 'page-specific-subscriptions',
  },
];
class RestrictedAccess extends React.Component {
  componentDidMount() {
    this.props.loadLayoutData();
  }

  dropdownNavigationOptions = () =>
    this.props.pages.map(
      (page, index) =>
        page.type !== 'Popup' && (
          <MenuItem
            key={page.path}
            value={index ? page.path : '/'}
            primaryText={page.title}
          />
        ),
    );

  dropdownLayoutOptions = () => {
    const { t } = this.props;
    return layout.map(type => (
      <MenuItem
        key={type.value}
        value={type.value}
        primaryText={t(type.name)}
      />
    ));
  };

  isLinkDisabled = () => {
    const { link } = this.props;
    return !link || (link && !link.enabled);
  };

  renderNavigationLinkToggle = () => (
    <Toggle
      onToggle={(e, isToggled) => {
        this.props.handleNestedChange('link/enabled', isToggled);
      }}
      toggled={this.props.link && this.props.link.enabled}
    />
  );

  render() {
    const {
      updateField,
      headerText,
      subHeaderText,
      link,
      handleNestedChange,
      pageLayout,
      loginRedirectEnabled,
      t,
    } = this.props;
    return (
      <React.Fragment>
        <div
          className="auth-panel"
          ref={ref => {
            if (
              ref &&
              this.props.location.hash === '#restricted-access-pages'
            ) {
              ref.scrollIntoView({
                behavior: 'smooth',
              });
            }
          }}
          id="restricted-access-pages"
        >
          <div className="auth-panel-title">
            <Trans>Restricted Access Page</Trans>
          </div>
          <div className="auth-panel">
            <div className="header-Wrapper">
              <SectionHeader title={t('Not Signed in Users')} color="#281FD6" />
            </div>
            <Toggle
              style={{ width: '100%' }}
              onToggle={(v, b) => {
                updateField('loginRedirectEnabled', b);
                this.props.handleNestedChange('link/enabled', false);
              }}
              toggled={loginRedirectEnabled}
              label={t(
                'Redirect the non-signed-in users to Sign up or Sign in before they can access the Restricted Access Page.',
              )}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <span styl>
              <Trans>
                When the toggle is disabled, the non-signed-in users will view
                the Restricted Access Page contents without signing up or
                signing in.
              </Trans>
            </span>
            <SectionHeader
              color="#281FD6"
              title={t('Restricted Access Page Layout')}
            />
            <SelectField
              label={t('Select a layout')}
              value={pageLayout}
              options={this.dropdownLayoutOptions()}
              onChange={(e, value) => handleNestedChange('layout', value)}
              autoWidth
            />
            <TextField
              id="header"
              value={headerText}
              className="auth-panel-privacy-url-textbox"
              floatingLabelText={t('Header Text')}
              underlineShow
              onChange={(event, value) => updateField('headerText', value)}
            />
            <TextField
              id="subhrader"
              value={subHeaderText}
              className="auth-panel-privacy-url-textbox"
              floatingLabelText={t('Sub Header Text')}
              underlineShow
              onChange={(event, value) => updateField('subHeaderText', value)}
            />
            <SectionHeader
              color="#281FD6"
              title={t('Navigation Link')}
              actions={this.renderNavigationLinkToggle()}
            />
            <div className="app-link-menu-wrapper">
              <TextField
                id="app-navigation-label"
                value={link && link.label}
                onChange={(event, value) =>
                  handleNestedChange('link/label', value)
                }
                placeholder={t('Button Label')}
                disabled={this.isLinkDisabled()}
              />
              <div className="app-link-container">
                <RadioButtonGroup
                  className="radio-wrapper"
                  name="buttonLink"
                  valueSelected={link && link.type}
                  onChange={(event, value) => {
                    handleNestedChange('link/type', value);
                  }}
                >
                  <RadioButton
                    id="page"
                    value="page"
                    disabled={this.isLinkDisabled()}
                  />
                  <RadioButton
                    id="web"
                    value="web"
                    disabled={this.isLinkDisabled()}
                  />
                </RadioButtonGroup>

                <div className="link-radio-option-wrapper">
                  <SelectField
                    label={t('Select a page')}
                    value={link && link.type && link.page}
                    options={this.dropdownNavigationOptions()}
                    onChange={(e, value) =>
                      handleNestedChange('link/page', value)
                    }
                    disabled={
                      this.isLinkDisabled() || false || link.type !== 'page'
                    }
                  />
                  <TextField
                    id="custom-app-webpage"
                    value={link && link.type && link.web}
                    onChange={(e, value) =>
                      handleNestedChange('link/web', value)
                    }
                    placeholder={t('Webpage Link')}
                    disabled={
                      this.isLinkDisabled() || false || link.type !== 'web'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

RestrictedAccess.propTypes = {
  updateField: PropTypes.func.isRequired,
};

RestrictedAccess.defaultProps = {
  profileEnabled: true,
  signupEnabled: true,
};

export default withTranslation()(RestrictedAccess);
