import {
  OPEN_PARTICIPANT_DIALOG,
  PARTICIPANT_ITEM_CHANGE,
  RESET_PARTICIPANT_DETAILS,
} from './types';

export const viewParticipantDialog = isOpen => ({
  type: OPEN_PARTICIPANT_DIALOG,
  payload: isOpen,
});

export const onResetParticipantData = () => ({
  type: RESET_PARTICIPANT_DETAILS,
});

export const participantItemChange = item => ({
  type: PARTICIPANT_ITEM_CHANGE,
  payload: item,
});
