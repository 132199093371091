/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Dialog from 'material-ui/Dialog';
// import { TextField as MuiTextArea } from '@material-ui/core';
import Divider from 'material-ui/Divider';
import Toggle from 'material-ui/Toggle';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  Autocomplete,
  TextField as AutoTextField,
  Popper,
} from '@material-ui/core';

import styled from 'styled-components';
import HighlightOff from '@material-ui/icons/HighlightOff';
import PlMatchDaySupportImg from './pl_match_day_support.png';
import TextField from '../../../../app/components/textField';
import TextArea from '../../../../app/components/textArea';
import CircularValidation from '../../../../app/components/circularValidation';
import { FlatButton } from '../../../../app/components/buttons';
import { CircularProgress } from '../../../../app/components/progress';
import themeStyles from '../../../../_export.scss';

const CustomPopper = styled(Popper)`
  z-index: 2001; // Ensures it's above the modal (default modal z-index is 1300)
`;

const WrapperDiv = styled.div`
  margin-top: 30px;
  max-width: 324px;
  margin-bottom: ${props =>
    props.marginBottom ? `${props.marginBottom}px` : '40px'};
`;

const ErrorText = styled.div`
  color: #ff0000;
  text-align: center;
`;

const PlMatchSupportModal = props => {
  const {
    data,
    toggleMatchSupportModal,
    appname,
    onFieldChangeWithUniqueCheck,
    onFieldChange,
    onSubmit,
    onTogglePlSnackbar,
    hotels,
    therapists,
  } = props;

  const {
    modalOpen,
    location,
    hotel,
    hotel_available,
    availability,
    therapist_available,
    therapist,
    therapist_phone,
    therapist_email,
    filteredChunks,
    pending,
    isUpdate,
    hotel_phone,
    hotel_lat,
    hotel_lng,
    snackbarOpen,
    therapistId,
    hotelId,
  } = data;

  const [selectedHotel, setSelectedHotel] = useState(null);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (therapistId) {
      const initialTherapist = therapists.find(
        item => item.value === therapistId,
      );

      setSelectedTherapist(initialTherapist);
    }
  }, [therapistId, therapists]);

  useEffect(() => {
    if (hotelId) {
      const initialHotel = hotels.find(item => item.value === hotelId);
      setSelectedHotel(initialHotel);
    }
  }, [hotelId, hotels]);

  const handleSelectedHotel = value => {
    setSelectedHotel(value);
    onFieldChange('hotel', [value.name, value.address].join('\n'));
    onFieldChange('hotel_lat', value.lat);
    onFieldChange('hotel_lng', value.lng);
    onFieldChange('hotel_phone', value.phone);
  };

  const handleSelectedTherapist = value => {
    setSelectedTherapist(value);
    onFieldChange('therapist', value.name);
    onFieldChange('therapist_phone', value.phone);
    onFieldChange('therapist_email', value.email);
  };

  const handleSubmit = () => {
    if (!selectedTherapist) {
      setError('Select a Therapist');
      return;
    } else if (!selectedHotel) {
      setError('Select a Hotel');
      return;
    }
    setError(null);

    onSubmit(selectedHotel.value, selectedTherapist.value);
  };
  const handleModalClose = () => {
    toggleMatchSupportModal(!modalOpen || false);
  };

  const buttonSubmit = !pending ? (
    <FlatButton
      label={isUpdate ? 'Update' : 'Add'}
      onClick={handleSubmit}
      disabled={!location.isValid}
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );

  return (
    <Dialog
      open={modalOpen || false}
      // onRequestClose={handleModalClose}
      autoScrollBodyContent
      className="pgmol-modal-main-container"
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        onClose={onTogglePlSnackbar}
        message="Please complete all required fields."
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onTogglePlSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <div className="user-memberBodies-modal-content">
        <div className="modal-heading pgmol-modal-heading">
          <img src={PlMatchDaySupportImg} alt="imgg" />
          <h5>PL Match Day Support</h5>
          <HighlightOff
            className="pgmol-heading-close-icon"
            onClick={handleModalClose}
          />
        </div>
      </div>
      <div>
        <Divider />
        <div className="row">
          <div className="col-sm-6">
            <div className="users-list-container">
              <TextField
                id="name"
                placeholder={'Club'}
                {...location}
                onChange={value =>
                  onFieldChangeWithUniqueCheck(
                    'location',
                    value,
                    filteredChunks,
                  )
                }
                rightInputAction={
                  <CircularValidation
                    isValid={location.isValid}
                    errorMsg={'Club already exists.'}
                    hideValidIcon
                  />
                }
                required
                showRequiredAsterick
                applyPgmolLabelStyle
                disabled={isUpdate}
              />
              <br />
              <Toggle
                onToggle={(v, b) => onFieldChange('hotel_available', b)}
                toggled={hotel_available.value}
                label="Hotel Therapy Available?"
                trackSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColorLight,
                }}
                thumbSwitchedStyle={{
                  backgroundColor: 'rgb(120, 255, 197)',
                }}
                style={{
                  width: 'calc(100% - 20px)',
                  fontSize: `${hotel_available.value ? '0.74rem' : '0.95rem'}`,
                  color: '#000',
                }}
              />
              {hotel_available && hotel_available.value && (
                <>
                  <WrapperDiv>
                    <Autocomplete
                      options={hotels}
                      getOptionLabel={option => option.label}
                      value={selectedHotel}
                      onChange={(event, newValue) => {
                        handleSelectedHotel(newValue);
                      }}
                      renderInput={params => (
                        <AutoTextField
                          {...params}
                          label="Select Hotel"
                          variant="standard"
                          required
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: null,
                            style: {
                              color: '#000000',
                              fontSize: '0.95rem',
                              fontWeight: '100',
                              fontFamily: "'Raleway', 'Karla', sans-serif",
                            },
                          }}
                        />
                      )}
                      ListboxProps={{
                        style: {
                          color: '#000000', // Custom text color
                          fontSize: '1rem', // Adjust font size if needed
                        },
                      }}
                      PopperComponent={props => (
                        <CustomPopper
                          {...props}
                          keepMounted
                          disablePortal
                          placement="bottom-start"
                          modifiers={[{ name: 'flip', enabled: false }]}
                        />
                      )} // Use the custom Popper
                    />
                  </WrapperDiv>
                  <TextArea
                    id="hotel"
                    placeholder="Hotel"
                    {...hotel}
                    onChange={value => onFieldChange('hotel', value)}
                    label="Hotel Address"
                    minHeight="170px"
                    required
                    errorText={'Field is required.'}
                    showRequiredAsterick
                    applyPgmolLabelStyle
                    readonly
                  />

                  <TextField
                    id="hotel_lat"
                    placeholder="Latitude"
                    {...hotel_lat}
                    onChange={value => onFieldChange('hotel_lat', value)}
                    label="Hotel Longitude"
                    applyPgmolLabelStyle
                    readonly
                  />

                  <TextField
                    id="hotel_lng"
                    placeholder="Longitude"
                    {...hotel_lng}
                    onChange={value => onFieldChange('hotel_lng', value)}
                    label="Hotel Longitude"
                    applyPgmolLabelStyle
                    readonly
                  />

                  <TextField
                    id="hotel_phone"
                    placeholder={'Hotel Telephone Number '}
                    {...hotel_phone}
                    onChange={value => onFieldChange('hotel_phone', value)}
                    required
                    errorText={'Field is required.'}
                    showRequiredAsterick
                    applyPgmolLabelStyle
                    readonly
                  />
                  <br />

                  <Toggle
                    onToggle={(v, b) => onFieldChange('therapist_available', b)}
                    toggled={therapist_available.value}
                    label="Therapist Available?"
                    trackSwitchedStyle={{
                      backgroundColor: themeStyles.primaryColorLight,
                    }}
                    thumbSwitchedStyle={{
                      backgroundColor: 'rgb(120, 255, 197)',
                    }}
                    style={{
                      width: 'calc(100% - 20px)',
                      fontSize: `${
                        therapist_available.value ? '0.74rem' : '0.95rem'
                      }`,
                      color: '#000',
                    }}
                  />

                  {therapist_available && therapist_available.value && (
                    <>
                      <WrapperDiv marginBottom={2}>
                        <Autocomplete
                          options={therapists}
                          getOptionLabel={option => option.label}
                          value={selectedTherapist}
                          onChange={(event, newValue) => {
                            handleSelectedTherapist(newValue);
                          }}
                          renderInput={params => (
                            <AutoTextField
                              {...params}
                              label="Select Therapist"
                              variant="standard"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: null,
                                style: {
                                  color: '#000000',
                                  fontSize: '0.95rem',
                                  fontWeight: '100',
                                  fontFamily: "'Raleway', 'Karla', sans-serif",
                                },
                              }}
                              required
                            />
                          )}
                          ListboxProps={{
                            style: {
                              color: '#000000', // Custom text color
                              fontSize: '1rem', // Adjust font size if needed
                            },
                          }}
                          PopperComponent={props => (
                            <CustomPopper
                              {...props}
                              keepMounted
                              disablePortal
                              placement="bottom-start"
                              modifiers={[{ name: 'flip', enabled: false }]}
                            />
                          )} // Use the custom Popper
                        />
                      </WrapperDiv>
                      <TextField
                        id="therapist"
                        placeholder={'Therapist'}
                        {...therapist}
                        onChange={value => onFieldChange('therapist', value)}
                        required
                        errorText={'Field is required.'}
                        showRequiredAsterick
                        applyPgmolLabelStyle
                        readonly
                      />
                      <TextField
                        id="therapist_phone"
                        placeholder={'Therapist Telephone Number'}
                        {...therapist_phone}
                        onChange={value =>
                          onFieldChange('therapist_phone', value)
                        }
                        showRequiredAsterick
                        required
                        errorText={'Field is required.'}
                        applyPgmolLabelStyle
                        readonly
                      />
                      <TextField
                        id="therapist_email"
                        placeholder={'Therapist Email'}
                        {...therapist_email}
                        onChange={value =>
                          onFieldChange('therapist_email', value)
                        }
                        type="email"
                        required
                        showRequiredAsterick
                        errorText={'Field is required.'}
                        applyPgmolLabelStyle
                        readonly
                      />

                      <TextArea
                        id="availability"
                        placeholder="Availability"
                        {...availability}
                        onChange={value => onFieldChange('availability', value)}
                        label="Availability"
                        minHeight="100px"
                        required
                        errorText={'Field is required.'}
                        showRequiredAsterick
                        applyPgmolLabelStyle
                      />
                    </>
                  )}
                </>
              )}

              <br />
            </div>
          </div>
        </div>
        <br />
        <Divider />
      </div>
      <Divider />
      <div className="submit-button-container">{buttonSubmit}</div>
      <ErrorText>{error && <span>{error}</span>}</ErrorText>
    </Dialog>
  );
};

export default PlMatchSupportModal;
