import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation, Trans } from 'react-i18next';
import ResultsTable from '../../../../app/components/resultsTable';
import TryAgain from '../../../../app/components/tryAgain';
import Header from '../../../../app/components/header';

class Payment extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object.isRequired,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    selected: PropTypes.arrayOf(PropTypes.string),
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    filter: PropTypes.string,
  };

  static defaultProps = {
    error: null,
    filter: '',
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
  };

  getTitle = () => null;

  formatPrice = (currency, price) => {
    const currencyCode = {
      AUD: { symbol: '&#36;', position: 'left' },
      CAD: { symbol: '&#36;', position: 'left' },
      CZK: { symbol: '&#75;&#269;', position: 'right' },
      DKK: { symbol: '&#107;&#114;', position: 'right' },
      EUR: { symbol: '&#8364;;', position: 'left' },
      HKD: { symbol: '&#36;', position: 'left' },
      INR: { symbol: '&#8377;', position: 'left' },
      ILS: { symbol: '&#8362;', position: 'left' },
      MXN: { symbol: '&#36;', position: 'left' },
      NOK: { symbol: '&#107;&#114;', position: 'right' },
      NZD: { symbol: '&#36;', position: 'left' },
      PHP: { symbol: '&#8369;', position: 'left' },
      PLN: { symbol: '&#122;&#322;', position: 'left' },
      GBP: { symbol: '&#163;', position: 'left' },
      RUB: { symbol: '&#1088;&#1091;&#1073;', position: 'left' },
      SGD: { symbol: '&#36;', position: 'left' },
      CHF: { symbol: '&#67;&#72;&#70;', position: 'left' },
      SEK: { symbol: '&#107;&#114;', position: 'left' },
      USD: { symbol: '&#36;', position: 'left' },
      THB: { symbol: '&#3647;', position: 'left' },
      DEFAULT: { symbol: '&#36;', position: 'left' },
    };

    const code = currencyCode[currency]
      ? currencyCode[currency]
      : currencyCode.DEFAULT;

    const codeText = <span dangerouslySetInnerHTML={{ __html: code.symbol }} />;

    return (
      <span>
        {code.position === 'left' && codeText} {price}{' '}
        {code.position === 'right' && codeText}
      </span>
    );
  };

  componentDidMount = () => {
    this.props.getPaymentHistory();
  };

  render() {
    const {
      pending,
      items,
      error,
      getPaymentHistory,
      chunks,
      filteredChunks,
      onFilterPaymentHistory,
      selected,
      colSorted,
      order,
      onSort,
      t,
    } = this.props;

    const paymentHistory = (
      <p className="instructions-container">
        <p className="instructions-section-1">
          <Trans>
            In Payment History you can see the list of the transactions which
            occurred via the Pay now component. The Pay now component allows you
            to take one-off payments via PayPal.
          </Trans>
        </p>
        <p className="instructions-section-2">
          <span>
            <Trans>You can add the Pay now button by visiting Build</Trans> →{' '}
            <Trans>Pages</Trans> →<Trans>Components menu</Trans> →{' '}
            <Trans>Pay now</Trans>.
          </span>
          <br />
        </p>
          
      </p>
    );

    const body = error ? (
      <TryAgain
        message={t('Opps, something went wrong...')}
        onRetry={getPaymentHistory}
      />
    ) : (
      <ResultsTable
        items={items}
        chunks={filteredChunks}
        loading={pending}
        colSorted={colSorted}
        order={order}
        pending={pending}
        sortTable={cols => onSort(cols)}
        colsToSort={['createdAt', 'status']}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        optionalMessage={paymentHistory}
        emptyMessage={t('Looks like you do not have any payment history yet')}
        columns={[
          {
            id: 'createdAt',
            header: t('Date Created'),
            classNames: 'col-xs-2',
            containerElement: ({ value }) => {
              return (
                <span className="alignCenter">
                  {value && moment(value).format('DD/MM/YYYY')}
                </span>
              );
            },
          },
          {
            id: 'firstName',
            header: t('Name'),
            classNames: 'col-xs-2',
            containerElement: ({ value, rowId }) => {
              const row = filteredChunks[rowId];
              const name =
                row && row.lastName ? `${value} ${row.lastName}` : value;
              return <span>{name}</span>;
            },
          },
          {
            id: 'email',
            header: t('Email'),
            classNames: 'col-xs-2',
          },
          {
            id: 'product',
            header: t('Product'),
            classNames: 'col-xs-2',
          },
          {
            id: 'amount',
            header: t('Amount'),
            classNames: 'col-xs-1',
            containerElement: ({ value, rowId }) => {
              const row = filteredChunks[rowId];
              const currency = row && row.currency ? row.currency : '';
              return this.formatPrice(currency, value);
            },
          },
          {
            id: 'uid',
            header: t('User Type'),
            classNames: 'col-xs-2',
            containerElement: ({ value }) => {
              return value ? t('App User') : t('Public Visitor');
            },
          },
          {
            id: 'status',
            header: t('Status'),
            classNames: 'col-xs-1',
          },
        ]}
      />
    );

    return (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={onFilterPaymentHistory}
        />
        {body}
      </section>
    );
  }
}

export default withTranslation()(Payment);
