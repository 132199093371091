import React, { useState } from 'react';
import debounce from 'lodash.debounce';

export const CourseContext = React.createContext({});

const initialCourseData = {
  title: '',
  isActive: true,
  previewImage: '',
  previewVideo: '',
  description: '',
  modules: [{ title: '', media: '', content: '' }],
  whatNext: '',
  nextPageLink: '',
  isPaid: false,
  price: '',
};

export const CourseProvider = ({ children }) => {
  const [stripeKeyError, setStripeKeyError] = useState(false);
  const [courseData, setCourseData] = useState(initialCourseData);
  const [expandedModule, setExpandedModule] = useState(0);
  const [error, setError] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleModuleChange = (index, field, value) => {
    setCourseData(prevData => {
      const updatedModules = [...prevData.modules];
      updatedModules[index] = {
        ...updatedModules[index],
        [field]: value,
      };
      return {
        ...prevData,
        modules: updatedModules,
      };
    });
  };

  const addModule = () => {
    setCourseData(prevData => ({
      ...prevData,
      modules: [...prevData.modules, { title: '', media: '', content: '' }],
    }));
    setExpandedModule(courseData.modules.length);
  };

  const handleAccordionChange = panel => (event, isExpanded) => {
    setExpandedModule(isExpanded ? panel : -1);
  };

  const resetCourseData = () => {
    setCourseData(initialCourseData);
    setStripeKeyError(false);
    setError(false);
  };

  const debouncedUpdate = debounce((field, value) => {
    setCourseData(prev => ({
      ...prev,
      [field]: value,
    }));
  }, 1000);

  const handleCourseDataChange = (field, value) => {
    debouncedUpdate(field, value);
  };

  return (
    <CourseContext.Provider
      value={{
        stripeKeyError,
        setStripeKeyError,
        courseData,
        setCourseData,
        handleCourseDataChange,
        expandedModule,
        setExpandedModule,
        handleModuleChange,
        addModule,
        handleAccordionChange,
        resetCourseData,
        error,
        setError,
        isButtonDisabled,
        setIsButtonDisabled,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};
