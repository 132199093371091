export const startComponents = [
  'Image',
  'Text',
  'TwitterFeed',
  'Button',
  'Header',
  'Slider',
  'EventList',
  'RadioStreaming',
  'EventCourses',
  'EventCalendar',
  'EventOrganizers',
  'Workforces',
  'Memberbodies',
  'Gallery',
  'PgmolPlMatchSupport',
  'PgmolRegionPerformanceCentre',
  'Title',
  'Blog',
  'SubscriptionPlans',
  'OnlineCourse',
];

export const somethingComponents = [
  'Image',
  'Text',
  'TwitterFeed',
  'Button',
  'Header',
  'Slider',
  'EventList',
  'RadioStreaming',
  'EventCourses',
  'Form',
  'Workforces',
  'Memberbodies',
  'Forum',
];

export const launchComponents = [
  ...startComponents,
  'Twitter',
  'Tweet',
  'VimeoVideo',
  'YouTube',
  'Livestream',
  'FacebookPage',
  'InstagramFeed',
  'Zoom',
  'YouTubeVideo',
  'Form',
  'Roster',
  'Schedule',
  'File',
  'Media',
  'Vote',
  'Delegates',
  'Loyalty',
  'Food',
  'Marketplace',
  'Forum',
  'Countdown',
  'Rating',
  'Appointment',
  'FkAcademyGroups',
];

export const diyComponents = [
  'Image',
  'Text',
  'Header',
  'Slider',
  'Button',
  'Delegates',
  'Loyalty',
  'Food',
  'Marketplace',
  'Forum',
  'Form',
  'Rating',
  'Roster',
  'Schedule',
  'File',
  'Media',
  'Vote',
  'Countdown',
  'FacebookPage',
  'InstagramFeed',
  'Zoom',
  'Twitter',
  'TwitterFeed',
  'YouTube',
  'VimeoVideo',
  'Appointment',
  'Timer',
  'TimerHistory',
  'EventCalendar',
  'EventOrganizers',
  'Memberbodies',
  'FkAcademyGroups',
];

export const diyRestrictedAddons = [
  'Image',
  'Text',
  'Header',
  'Slider',
  'Button',
  'Delegates',
  'Loyalty',
  'Food',
  'Marketplace',
  'Forum',
  'Form',
  'Rating',
  'Roster',
  'Schedule',
  'File',
  'Media',
  'Vote',
  'Countdown',
  'FacebookPage',
  'InstagramFeed',
  'Zoom',
  'Twitter',
  'YouTube',
  'Livestream',
  'VimeoVideo',
  'Appointment',
  'EventCalendar',
];

export const accelerateComponents = [
  ...launchComponents,
  'IFrame',
  'WordPress',
  'Notification',
  'Appointedd',
  'Shopify',
  'Ecwid',
  'GoogleMaps',
  'Woocommerce',
  'Popup',
  'PayNow',
  'Canva',
  'Timer',
  'TimerHistory',
  'Gallery',
  'EventCalendar',
  'EventOrganizers',
  'Html',
  'PDFViewer',
  'Feedback',
];

export const appSumoBasicTierComponents = [
  ...startComponents,
  'Twitter',
  'Tweet',
  'VimeoVideo',
  'YouTube',
  'Livestream',
  'FacebookPage',
  'InstagramFeed',
  'Zoom',
  'YouTubeVideo',
  'Form',
  'Roster',
  'Schedule',
  'File',
  'Media',
  'Vote',
  'Delegates',
  'Loyalty',
  'Forum',
  'Countdown',
  'Rating',
  'IFrame',
  'WordPress',
  'Notification',
  'Appointedd',
  'Shopify',
  'Ecwid',
  'GoogleMaps',
  'Woocommerce',
  'Popup',
  'PayNow',
  'Canva',
  'Timer',
  'TimerHistory',
  'Html',
  'PDFViewer',
  'Feedback',
  'Blog',
];

export const specialAddonComponents = ['PDFViewer', 'Feedback', 'Blog'];
