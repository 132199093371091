import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Toggle } from 'material-ui';
import style from './marketplace.module.scss';
import { Tabs, Tab } from '../../../../../components/tabs';
import Container from '../../../../../../../app/components/container';
import SectionHeader from '../../../../../components/sectionHeader';
import ColorPicker from '../../../../../components/colorPicker';
import DesignToggle from '../../../../../components/designToggle';

const Marketplace = ({
  appname,
  onDesignChange,
  design,
  component,
  onDesignToggle,
}) => {
  const { t } = useTranslation();

  const handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onDesignChange(prop, rgba);
  };
  return (
    <React.Fragment>
      <Tabs>
        <Tab label={t('content')}>
          <Container
            style={{ display: 'flex', flexDirection: 'column', padding: '1em' }}
          >
            <p>
              <Trans>
                This component allows users to sell and buy products & services
                on the app. On this page, you will see the list of products &
                services added by the app users whom you approved as Sellers in
                your app.
              </Trans>
            </p>
            <p>
              <Trans>To configure this component go to</Trans>{' '}
              <Link to={`/${appname}/ecommerce/marketplace/settings`}>
                <Trans>Business Tools</Trans> - <Trans>Marketplace</Trans> -{' '}
                <Trans>Settings</Trans>
              </Link>
              .
            </p>
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={(e, isToggled) => onDesignToggle(isToggled)}
            />

            <ColorPicker
              label={t('Text Color')}
              color={design.textColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('textColor')}
            />
            <ColorPicker
              label={t('Background Color')}
              color={design.backgroundColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('backgroundColor')}
            />
            <ColorPicker
              label={t('Button Text Color')}
              color={design.buttonTextColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('buttonTextColor')}
            />
            <ColorPicker
              label={t('Button Background Color')}
              color={design.buttonBackgroundColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('buttonBackgroundColor')}
            />
          </Container>
        </Tab>
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    </React.Fragment>
  );
};

export default Marketplace;
