import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import { Trans, useTranslation } from 'react-i18next';
import { RoundedThinButton } from '../../components/buttons';
import { PLANS_WITH_ADDONS } from '../../../plans/constants';
import {
  sendSlackNotification,
  USER_CLICKED_UPGRADE,
  PLATFORM_EVENTS,
  capitaliseString,
} from '../../../utils';
import { features, oldPlansMapping, plans, plansAppSumo } from '../../../plans';
import AddToPlanDialog from '../../components/addToPlanDialog';
import * as images from '../../../modules/editor/modules/build/modules/create/createComponent/images';
import './styles.css';

const UpgradeButton = props => {
  const {
    accountId,
    appname,
    history,
    integration,
    subscription,
    hidePlanName,
    openWixPlans,
    organisation,
    diyComponents,
    position,
    togglePopup,
    popupState,
    match,
    onAddonAdd,
    isProcessing,
    intl,
    noPlanTitle,
    isAppAdmin,
    isAppSumoAccount,
    trialExpired,
    freeTrial,
    isSubAdminWithMulipleApps,
  } = props;
  const integrationType = _get(integration, 'type');

  const { t } = useTranslation();

  const notifySlack = () => {
    sendSlackNotification({
      appname,
      notificationTitle: USER_CLICKED_UPGRADE,
      account: accountId,
      currentPlan: subscription.plan,
      slackChannel: PLATFORM_EVENTS,
    });
  };

  const hasAddon = addon => {
    const allAddons = diyComponents ? Object.keys(diyComponents) : {};
    return diyComponents ? allAddons.includes(addon) : false;
  };

  const isDiyPlan = () =>
    subscription &&
    subscription.plan &&
    PLANS_WITH_ADDONS.includes(subscription.plan.toLowerCase());

  const handleSubscribe = () => {
    notifySlack();

    if (integrationType === 'Weebly') {
      return history.push(`/${appname}/choose_plan`);
    }

    const plan = 'premium';
    return openWixPlans(appname, plan, history, accountId);
  };

  const isIntegration = integration !== null;

  const isPremiumIntegration =
    subscription && subscription.plan && subscription.plan === 'premium';

  const classNameText =
    subscription || isPremiumIntegration
      ? 'text-premium-plan'
      : 'text-free-plan';

  const UpgradeButtonComponent = props => {
    return isDiyPlan() &&
      position === 'push-notifications' &&
      !hasAddon('Notification') ? (
      <RoundedThinButton
        className="success"
        {...props}
        onClick={() => togglePopup(true)}
      >
        <Trans>Add to Package</Trans>
      </RoundedThinButton>
    ) : isAppSumoAccount ? null : (
      <RoundedThinButton className="success" {...props}>
        {'Upgrade'}
        {/* {isAppSumoAccount ? 'Billing' : 'Upgrade'} */}
      </RoundedThinButton>
    );
  };
  const withLink = component => {
    let subscriptionName = subscription && subscription.plan;
    let title = plans[subscriptionName]
      ? plans[subscriptionName].title
      : subscriptionName;
    let currency;
    if (title && title.includes('-')) {
      const formattedTitle = title.replace('2023', '');
      currency = formattedTitle.split('-')[1];
    }

    return isDiyPlan() && position === 'push-notifications' ? (
      <div>
        <AddToPlanDialog
          intl={intl}
          subscription={subscription}
          open={popupState}
          match={match}
          history={history}
          heading={t('Upgrade Required')}
          component={features.Notification}
          onRequestClose={() => {
            togglePopup(false);
          }}
          image={images.notification}
          onAddonAdd={newComponent => {
            onAddonAdd('Notification', newComponent, currency);
          }}
          isProcessing={isProcessing}
        />
        {component}
      </div>
    ) : (
      <Link to={`/${appname}/choose_plan`} onClick={notifySlack}>
        {component}
      </Link>
    );
  };

  const FreePlanContent = () => (
    <div>
      {!hidePlanName && <div className={classNameText}>Free Trial</div>}
      {isIntegration ? (
        <UpgradeButtonComponent onClick={handleSubscribe} />
      ) : (
        withLink(<UpgradeButtonComponent />)
      )}
    </div>
  );

  const integrationText = isPremiumIntegration ? (
    <div className={classNameText}>
      <Trans>Premium Plan</Trans>
    </div>
  ) : (
    <div className={classNameText}>
      <Trans>Free Plan</Trans>
    </div>
  );

  // Capitalize first letter of the subscription name (stored in all lowercase in DB)
  const getSubscriptionName = () => {
    if (isAppSumoAccount) {
      return plansAppSumo[subscription.plan]
        ? plansAppSumo[subscription.plan].title
        : '';
    }

    let subscriptionName = subscription && subscription.plan;
    const cancelled = subscription && subscription.cancelled;
    const isExpert = organisation && organisation.legacy_flag === 20;
    const freeTrialExpired = freeTrial < new Date().toISOString();

    let subscriptionExpired = false;
    if (subscription && subscription.plan) {
      subscriptionExpired = Date.now() > subscription.current_period_end;
    }

    if (subscriptionName && !subscriptionExpired) {
      if (isExpert) {
        return 'Reseller';
      } else {
        if (oldPlansMapping[subscriptionName]) {
          subscriptionName = oldPlansMapping[subscriptionName];
        }

        if (subscriptionName.includes('diy')) subscriptionName = 'diy';
        if (subscriptionName.includes('deluxe')) subscriptionName = 'deluxe';

        // if (subscriptionName === 'price_1OPOWsH7KxBKIk4ar4GVzFYV')
        //   subscriptionName = 'deluxe';

        if (subscriptionName.includes('something'))
          subscriptionName = 'something';

        let title = plans[subscriptionName]
          ? plans[subscriptionName].title
          : subscriptionName;
        if (title.includes('diy')) title = 'diy';
        if (title.includes('deluxe')) title = 'deluxe';
        return capitaliseString(title);
      }
    }

    return 'Free';
  };

  const premiumContent = isIntegration ? (
    integrationText
  ) : isSubAdminWithMulipleApps ? null : (
    <div className="upgrade-item-content">
      {noPlanTitle ? null : (
        <div className={classNameText}>{getSubscriptionName()} Plan</div>
      )}
      {withLink(<UpgradeButtonComponent />)}
    </div>
  );

  const hasPremiumPlan = () =>
    (subscription &&
      subscription.plan &&
      subscription.plan.toLowerCase() !== 'free') ||
    (isIntegration && isPremiumIntegration);

  if (isAppAdmin) {
    return null;
  }

  return (
    <div className="upgrade-item">
      <div>
        {/* check if the plan is premium (regradless if it's subscription or not) */}
        {/*hasPremiumPlan() && isDiyPlan() ? premiumContent : <FreePlanContent />*/}
        {hasPremiumPlan() ? premiumContent : <FreePlanContent />}
      </div>
    </div>
  );
};

UpgradeButton.propTypes = {
  accountId: PropTypes.string.isRequired,
  appname: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  integration: PropTypes.object,
  subscription: PropTypes.object.isRequired,
  hidePlanName: PropTypes.bool,
  openWixPlans: PropTypes.func.isRequired,
  organisation: PropTypes.object.isRequired,
  position: PropTypes.string,
  isAppAdmin: PropTypes.bool,
  isSubAdminWithMulipleApps: PropTypes.bool.isRequired,
};

UpgradeButton.defaultProps = {
  hidePlanName: false,
  integration: null,
  isAppAdmin: false,
  position: null,
};

export default UpgradeButton;
