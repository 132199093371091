import firebase from '../../../../../../../firebase';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import { splitStringCamelCase } from '../../../../../../../utils';

// eslint-disable-next-line

export const fieldChange = (appname, dataId, field, value) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(field).set(value);

  dispatch(openSnackbar(`${splitStringCamelCase(field)} successfully saved.`));

  return {
    type: 'ONLINE_COURSE_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const designChange = (appname, dataId, field, value) => {
  const dataRef =
    field === 'layout'
      ? firebase.database().ref(`apps/${appname}/draft/always_data/${dataId}`)
      : firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/${dataId}/design`);

  dataRef.child(field).set(value);

  return {
    type: 'BOX_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};
