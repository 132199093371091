import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import { Trans, withTranslation } from 'react-i18next';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Toggle from '../../../../app/components/toggle';
import SectionHeader from '../../components/sectionHeader';
import TextField from '../../../../app/components/textField';
import SelectField from '../../../../app/components/selectField';
import ErrorMessage from '../../../../app/components/errorMessage';

import './style.css';
import { FlatButton } from '../../../../app/components/buttons';

class ButtonLinkMenu extends Component {
  static propTypes = {
    link: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired,
    borderTop: PropTypes.bool,
    linkOptional: PropTypes.bool,
    buttonOptional: PropTypes.bool,
    noButtonLink: PropTypes.bool,
    gaEnabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    tooltip: PropTypes.string,
    gaCategory: PropTypes.string,
    gaAction: PropTypes.string,
    googleAnalytics: PropTypes.object,
    iconEnabled: PropTypes.bool,
    onGetIconUrls: PropTypes.func.isRequired,
    btnIconUrl: PropTypes.string,
    customIconsArray: PropTypes.array,
  };

  static typeComponent = 'component';
  static typeSliderItem = 'sliderItem';

  static defaultProps = {
    borderTop: false,
    linkOptional: true,
    buttonOptional: false,
    noButtonLink: false,
    gaEnabled: false,
    tooltip: 'Allow users to navigate to other page',
    gaCategory: '',
    gaAction: '',
    googleAnalytics: {},
    iconEnabled: false,
    onGetIconUrls: () => {},
    customIconsArray: [],
  };

  handleChange = prop => value => {
    this.props.onChange(prop, value);
  };

  getGaToggled = () => {
    let gaToggled = this.props.gaEnabled || false;

    if (!(this.props.googleAnalytics && this.props.googleAnalytics.id)) {
      gaToggled = false;
      this.handleChange('gaEnabled')(false);
    }

    return gaToggled;
  };

  state = {
    gaToggled: this.getGaToggled(),
    gaCategoryValue: this.props.gaCategory || '',
    gaActionValue: this.props.gaAction || '',
    iconEnabled: this.props.iconEnabled,
    btnIconUrl: this.props.btnIconUrl,
  };

  componentDidMount() {
    this.props.onGetIconUrls();
  }

  onButtonLabelChange = value =>
    value.length < 21 && this.handleChange('link/button/label')(value);

  handleOptionElChange = prop => (e, isToggled) =>
    this.props.onChange(prop, isToggled);

  handleLinkChange = (index, payload) => {
    this.props.onChange('link/linkType/page', payload);
    this.props.onChange('link/linkType/selectedOption', 'page');
  };

  handleOptionsChange = (prop, value) => {
    this.props.onChange(prop, value);
  };

  isLinkDisabled = () => {
    const { linkOptional, link } = this.props;
    return (
      !link ||
      (link && !link.enabled) ||
      (linkOptional && link && !link.enabled)
    );
  };

  isButtonLableDisabled = () =>
    (this.props.buttonOptional && !this.props.link.button.enabled) ||
    this.isLinkDisabled();

  handleGaToggle = () => {
    if (!this.props.googleAnalytics.id) {
      return;
    }
    this.setState({ gaToggled: !this.state.gaToggled }, () => {
      this.handleChange('gaEnabled')(this.state.gaToggled);
    });
  };

  handleGaCategoryChange = value => {
    this.setState({ gaCategoryValue: value }, () => {
      this.handleChange('gaCategory')(value);
    });
  };

  handleGaActionChange = value => {
    this.setState({ gaActionValue: value }, () => {
      this.handleChange('gaAction')(value);
    });
  };

  hasToggleToEnableLink = () =>
    this.props.linkOptional ? (
      <Toggle
        onToggle={(e, isToggled) => {
          this.handleOptionElChange('link/enabled')(e, isToggled);
          this.handleOptionElChange('link/button/enabled')(e, isToggled);
          if (isToggled) {
            this.handleLinkChange(0, '/');
          }
        }}
        toggled={this.props.link && this.props.link.enabled}
      />
    ) : null;

  hasToggleToShowButton = () =>
    this.props.buttonOptional && (
      <Toggle
        onToggle={this.handleOptionElChange('link/button/enabled')}
        toggled={this.props.link.button.enabled}
        disabled={!this.props.link.enabled}
      />
    );

  pagetItemProperties = (page, index) => {
    const { key } = page;

    const nestedPages = [];
    if (this.props.pages.length > 0) {
      this.props.pages.forEach(route => {
        route.parentPageKey === key &&
          nestedPages.push({
            ...route,
          });
      });
    }
    if (nestedPages.length > 0) {
      nestedPages.sort((a, b) => a.order - b.order);
    }
    return {
      id: key,
      index,
      nestedPages,
    };
  };
  rearrangePageRoutes = (page, index) => {
    const pageTypes = ['NestedTabPage', 'TabView', 'View'];
    if (!pageTypes.includes(page.type)) {
      return null;
    }
    return { ...page, ...this.pagetItemProperties(page, index) };
  };

  getValidPages = () => {
    const pages = this.props.pages.map(this.rearrangePageRoutes);
    const validPages = [];
    pages.forEach(page => {
      if (page) {
        validPages.push(page);
        if (page.nestedPages) validPages.push(...page.nestedPages);
      }
    });
    // validPages.push({ title: 'Sign up Page', path: 'sign-up' });
    return validPages;
  };

  dropdownNavigationOptions = () => {
    const formattedPageRoutes = this.getValidPages();
    return formattedPageRoutes.map(
      (page, index) =>
        page.type !== 'Popup' && (
          <MenuItem
            key={page.path}
            value={index ? page.path : '/'}
            primaryText={page.title}
          />
        ),
    );
  };

  authDropdownNavigationOptions = () => {
    const { t } = this.props;
    const authPages = [
      {
        path: 'sign-in',
        title: t('Sign in'),
      },
      {
        path: 'sign-up',
        title: t('Sign up'),
      },
    ];

    return authPages.map((page, index) => (
      <MenuItem key={page.path} value={page.path} primaryText={page.title} />
    ));
  };

  handleIconSelect = iconUrl => {
    this.setState({ btnIconUrl: iconUrl });
    this.props.onChange('btnIconUrl', iconUrl);
  };

  render() {
    const { link, borderTop, t } = this.props;

    return (
      <div className="button-link-menu-wrapper">
        <SectionHeader
          title={t('Navigation Link')}
          actions={this.hasToggleToEnableLink()}
          tooltip={t(this.props.tooltip)}
          borderTop={borderTop}
        />
        <div className="button-link-container">
          <RadioButtonGroup
            className="radio-wrapper"
            name="buttonLink"
            defaultSelected={
              (link && link.linkType && link.linkType.selectedOption) || 'page'
            }
            onChange={this.handleOptionElChange('link/linkType/selectedOption')}
          >
            <RadioButton
              id="page"
              value="page"
              label={t('Page')}
              disabled={this.isLinkDisabled()}
            />
            <RadioButton
              id="auth"
              value="auth"
              label={t('Sign up Page')}
              labelStyle={{
                width: '65px',
              }}
              disabled={this.isLinkDisabled()}
            />
            <RadioButton
              id="web"
              value="web"
              label={t('Web')}
              disabled={this.isLinkDisabled()}
            />
            <RadioButton
              id="phone"
              value="phone"
              label={t('Phone')}
              disabled={this.isLinkDisabled()}
            />
            <RadioButton
              id="email"
              value="email"
              label={t('Email')}
              disabled={this.isLinkDisabled()}
            />
          </RadioButtonGroup>
          <div className="link-radio-option-wrapper">
            <SelectField
              label={t('Select a page')}
              value={link && link.linkType && link.linkType.page}
              options={this.dropdownNavigationOptions()}
              onChange={this.handleLinkChange}
              disabled={
                this.isLinkDisabled() ||
                (link &&
                  link.linkType &&
                  link.linkType.selectedOption !== 'page')
              }
            />
            <SelectField
              label={t('Select a page')}
              value={link && link.linkType && link.linkType.auth}
              options={this.authDropdownNavigationOptions()}
              onChange={(i, payload) =>
                this.handleOptionsChange('link/linkType/auth', payload)
              }
              disabled={
                this.isLinkDisabled() ||
                (link &&
                  link.linkType &&
                  link.linkType.selectedOption !== 'auth')
              }
            />
            <TextField
              id="custom-button-webpage"
              value={link && link.linkType && link.linkType.web}
              onChange={this.handleChange('link/linkType/web')}
              placeholder={t('Webpage Link')}
              disabled={
                this.isLinkDisabled() ||
                (link &&
                  link.linkType &&
                  link.linkType.selectedOption !== 'web')
              }
            />
            <TextField
              id="custom-button-phone"
              value={link && link.linkType && link.linkType.phone}
              onChange={this.handleChange('link/linkType/phone')}
              placeholder={t('Phone Number')}
              disabled={
                this.isLinkDisabled() ||
                (link &&
                  link.linkType &&
                  link.linkType.selectedOption !== 'phone')
              }
            />

            <TextField
              id="custom-button-email"
              value={link && link.linkType && link.linkType.email}
              onChange={this.handleChange('link/linkType/email')}
              placeholder={t('Email')}
              disabled={
                this.isLinkDisabled() ||
                (link &&
                  link.linkType &&
                  link.linkType.selectedOption !== 'email')
              }
            />
          </div>
        </div>
        {this.props.noButtonLink ? null : (
          <div>
            <SectionHeader
              title={t('Link Button')}
              actions={this.hasToggleToShowButton()}
              tooltip={
                !this.props.link.enabled
                  ? t('Enable Navigation Link')
                  : t('Navigation button on screen')
              }
              tooltipType={!this.props.link.enabled ? 'warning' : 'info'}
            />
            <TextField
              id="button-navigation-label"
              value={link && link.button && link.button.label}
              onChange={this.onButtonLabelChange}
              placeholder={t('Button Label')}
              disabled={this.isButtonLableDisabled()}
            />
            {this.props.customIconsArray.length > 0 && (
              <SectionHeader
                title={t('Add Icon')}
                tooltip={
                  this.isButtonLableDisabled()
                    ? t('Enable Link Button')
                    : t('Icon for button')
                }
                tooltipType={this.isButtonLableDisabled() ? 'warning' : 'info'}
                actions={
                  <Toggle
                    onToggle={this.handleOptionElChange(
                      'link/button/iconEnabled',
                    )}
                    toggled={this.props.iconEnabled}
                    disabled={this.isButtonLableDisabled()}
                  />
                }
              />
            )}
            {this.props.customIconsArray.length > 0 && this.props.iconEnabled && (
              <>
                <TextField
                  id="btnIconUrl"
                  value={this.state.btnIconUrl}
                  onChange={value => {
                    this.setState({ btnIconUrl: value });
                    this.props.onChange('btnIconUrl', value);
                  }}
                  placeholder={t('Enter Icon Url')}
                />
                <center>
                  {this.state.btnIconUrl &&
                  this.state.btnIconUrl.length > 10 ? (
                    <div className="iconUrlContainer">
                      <img src={this.state.btnIconUrl} alt="icon" />
                    </div>
                  ) : null}

                  {/* <ImageUpload
                    src={null}
                    label="Image"
                    caption="This image will be displayed for icons"
                    firebaseUrl={`/bookings/${appname}/temp_images/staff`}
                    title="Appointment Image"
                  /> */}

                  <h5 style={{ color: '#1B6633' }}>
                    <Trans>Or Select Icons from Below</Trans>
                  </h5>

                  {this.props.customIconsArray.length > 0 ? (
                    <>
                      <div className="iconContainer">
                        {this.props.customIconsArray.map(_icon => (
                          <img
                            src={_icon}
                            alt="icon"
                            onClick={() => this.handleIconSelect(_icon)}
                            style={{
                              border: `${
                                this.state.btnIconUrl === _icon
                                  ? '5px solid green'
                                  : ''
                              }`,
                            }}
                          />
                        ))}
                      </div>
                    </>
                  ) : null}
                </center>
              </>
            )}
          </div>
        )}
        <div>
          <SectionHeader
            title={t('Google Analytics Events')}
            actions={
              <Toggle
                onToggle={this.handleGaToggle}
                toggled={this.state.gaToggled}
              />
            }
            tooltip={
              !(this.props.googleAnalytics && this.props.googleAnalytics.id)
                ? t('You need to enable Google Analytics to use this feature')
                : t('Set google analytics events on button click')
            }
            tooltipType={
              !(this.props.googleAnalytics && this.props.googleAnalytics.id)
                ? 'warning'
                : 'info'
            }
          />
          <TextField
            value={this.state.gaCategoryValue}
            onChange={value => this.handleGaCategoryChange(value)}
            placeholder={t('GA Category')}
            disabled={!this.state.gaToggled}
          />
          {!this.state.gaCategoryValue && this.state.gaToggled && (
            <ErrorMessage style={{ textAlign: 'center' }}>
              <Trans>GA Category is required!</Trans>
            </ErrorMessage>
          )}

          <TextField
            value={this.state.gaActionValue}
            onChange={value => this.handleGaActionChange(value)}
            placeholder={t('GA Action')}
            disabled={!this.state.gaToggled}
          />
          {!this.state.gaActionValue && this.state.gaToggled && (
            <ErrorMessage style={{ textAlign: 'center' }}>
              <Trans>GA Action is required!</Trans>
            </ErrorMessage>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ButtonLinkMenu);
