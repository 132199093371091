import React from 'react';

import BookingsTable from './bookingsTable/BookingsTable';

const ConfirmedBookings = props => {
  const { appname } = props;

  return <BookingsTable staffData={{}} metaData={{}} appname={appname} />;
};

export default ConfirmedBookings;
