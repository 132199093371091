import React from 'react';

const OnlineCourse = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    //   width={1440}
    //   height={1440}
    x="0px"
    y="0px"
    viewBox="0 0 32 40"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
    className="icon drivers"
  >
    <path d="M13.578,17.287c0.244,0,0.497-0.074,0.751-0.221l4.474-2.582c0.451-0.261,0.711-0.648,0.709-1.063  c0-0.414-0.258-0.802-0.709-1.063l-4.474-2.58c-0.255-0.147-0.507-0.222-0.751-0.222c-0.543,0-1.091,0.397-1.091,1.284v5.163  C12.487,16.891,13.035,17.287,13.578,17.287z M13.307,10.84c0-0.174,0.035-0.465,0.271-0.465c0.068,0,0.182,0.021,0.341,0.111  l4.474,2.581c0.188,0.109,0.3,0.241,0.3,0.354c0.001,0.111-0.112,0.244-0.3,0.354l-4.474,2.581  c-0.159,0.092-0.272,0.111-0.341,0.111c-0.236,0-0.271-0.292-0.271-0.465V10.84z" />
    <path d="M17.373,20.969c0.359,0,0.664-0.211,0.816-0.512h3.205c0.227,0,0.41-0.184,0.41-0.41s-0.184-0.409-0.41-0.409h-3.207  c-0.152-0.3-0.455-0.511-0.814-0.511s-0.664,0.211-0.815,0.511h-5.954c-0.227,0-0.41,0.183-0.41,0.409s0.184,0.41,0.41,0.41h5.953  C16.709,20.758,17.014,20.969,17.373,20.969z" />
    <path d="M28.205,7.045V5.622c0-0.227-0.184-0.409-0.41-0.409h-8.1c-1.951,0-3.115,1.305-3.695,2.49  c-0.58-1.186-1.744-2.49-3.697-2.49H4.205c-0.227,0-0.41,0.183-0.41,0.409v1.423C2.571,7.433,1.682,8.67,1.682,10.082v13.635  c0,1.693,1.377,3.07,3.069,3.07h22.498c1.692,0,3.069-1.377,3.069-3.07V10.082C30.318,8.67,29.429,7.433,28.205,7.045z M4.615,6.032  h7.688c2.619,0,3.27,2.852,3.295,2.974C15.64,9.193,15.807,9.328,16,9.328c0.192,0,0.359-0.135,0.4-0.322  c0.027-0.121,0.678-2.974,3.295-2.974h7.689v16.55h-7.864c-1.397,0-2.656,0.395-3.522,1.093c-0.861-0.689-2.145-1.093-3.52-1.093  H4.615V6.032z M29.498,23.717c0,1.24-1.009,2.25-2.249,2.25H4.751c-1.241,0-2.249-1.01-2.249-2.25V10.082  c0-1.063,0.605-1.826,1.293-2.158v15.068c0,0.227,0.184,0.41,0.41,0.41h8.273c1.326,0,2.534,0.416,3.23,1.111  c0.08,0.08,0.186,0.12,0.291,0.12c0.104,0,0.209-0.04,0.289-0.12c0.697-0.695,1.905-1.111,3.231-1.111h8.274  c0.227,0,0.41-0.184,0.41-0.41V7.924c0.688,0.332,1.293,1.095,1.293,2.158V23.717z" />
  </svg>
);

export default OnlineCourse;
