import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import Grid from '@material-ui/core/Grid';
import Toggle from 'material-ui/Toggle';
import IconButton from 'material-ui/IconButton';
import Delete from 'material-ui/svg-icons/action/delete';
import { Trans, withTranslation } from 'react-i18next';
import TextField from '../../../../../../../app/components/textField';
import SelectField from '../../../../../../../app/components/selectField';
import CircularValidation from '../../../../../../../app/components/circularValidation';
import CreateField from './modules/create';
import DialogConfirmation from '../../../../../../../app/components/dialogConfirmation';
import {
  MARKETPLACE_FILTER_FIELD_OPTIONS,
  MARKETPLACE_FILTER_OPTIONS,
} from '../../../../../../../config';
import { CUSTOM_MARKETPLACE_APPS } from '../../../../../../../plans/constants';

const gridStyles = {
  border: '1px solid #f3f3f3',
  borderRadius: '5px',
  marginBottom: '10px',
  background: '#fbfbfb',
};

class EditFilter extends Component {
  state = {
    confirmDeleteDialogOpen: false,
  };

  componentDidMount() {
    this.props.getFilterDetails();
  }

  toggleConfirmBox = () =>
    this.setState(prevState => ({
      confirmDeleteDialogOpen: !prevState.confirmDeleteDialogOpen,
    }));

  render() {
    const {
      error,
      type,
      updateFilter,
      filterDetails,
      fields,
      updateField,
      deleteField,
      t,
      appname,
    } = this.props;

    const isCustomMarkeplaceApp = CUSTOM_MARKETPLACE_APPS.includes(appname);

    const displayError = error ? (
      <div>
        <strong>{error}</strong>
      </div>
    ) : null;

    const fieldOptions = MARKETPLACE_FILTER_FIELD_OPTIONS.map(data => (
      <MenuItem key={data.id} value={data.id} primaryText={data.value} />
    ));

    const filterTypeOptions = MARKETPLACE_FILTER_OPTIONS.map(data => (
      <MenuItem key={data.id} value={data.id} primaryText={data.value} />
    ));
    const fieldsUI = fields.map(id => {
      const { t } = this.props;
      return (
        <Grid
          container
          spacing={0}
          key={`field-${id}`}
          alignItems="center"
          style={gridStyles}
        >
          <Grid item xs={3}>
            <TextField
              id="name"
              placeholder={t('Name')}
              label={t('Name')}
              multiLine
              value={filterDetails.fields[id].name}
              onChange={value =>
                value.length >= 2 ? updateField('name', id, value) : null
              }
              rightInputAction={
                <CircularValidation
                  isValid={filterDetails.fields[id].name.length > 2}
                  defaultMsg={t("Type's name required")}
                  errorMsg={t('Name must be at least 2 characters long')}
                  showDefault={!filterDetails.fields[id].name}
                />
              }
            />
          </Grid>
          <Grid item xs={2}>
            <SelectField
              className="offset-top"
              options={fieldOptions}
              label={t('Select Type')}
              onChange={(i, payload) => updateField('type', id, payload)}
              value={filterDetails.fields[id].type}
              autoWidth
              rightInputAction={
                <CircularValidation
                  isValid={type.isValid}
                  defaultMsg={t('Type type is required')}
                  errorMsg={t('Type type is required')}
                  showDefault={!filterDetails.fields[id].type}
                />
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Toggle
              className="small-toggle"
              onToggle={(v, b) => updateField('isRequired', id, b)}
              toggled={filterDetails.fields[id].isRequired || false}
              label={t('Required')}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              className="delete-icon"
              onClick={() => {
                this.setState({ item: id });
                this.toggleConfirmBox();
              }}
              tooltip={t('Delete')}
            >
              <Delete color="action" />
            </IconButton>
          </Grid>
          {filterDetails.fields[id].type === 'Dropdown' && (
            <React.Fragment>
              <Grid item xs={3}>
                <TextField
                  id="options"
                  placeholder={t('Dropdown Options')}
                  label={t('Dropdown Options')}
                  multiLine
                  value={filterDetails.fields[id].options}
                  onChange={value =>
                    value.length >= 2 ? updateField('options', id, value) : null
                  }
                  rightInputAction={
                    <CircularValidation
                      isValid={
                        filterDetails.fields[id].options &&
                        filterDetails.fields[id].options.length > 1
                      }
                      defaultMsg={t(
                        'Add multiple options using comma separated',
                      )}
                      errorMsg={t('Options is required')}
                      showDefault={!filterDetails.fields[id].options}
                    />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Toggle
                  onToggle={(v, b) => updateField('isMultiple', id, b)}
                  toggled={filterDetails.fields[id].isMultiple || false}
                  label={t('Multiple Selection')}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      );
    });

    const form = (
      <div className="types-panel">
        <div className="types-panel-title">
          {isCustomMarkeplaceApp ? (
            'Business Type Field Management'
          ) : (
            <Trans>Category Field Management</Trans>
          )}
        </div>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <TextField
              id="name"
              placeholder={isCustomMarkeplaceApp ? 'Type' : t('Category Name')}
              label={t('Select Type')}
              value={filterDetails.name}
              onChange={value => updateFilter('name', value)}
              rightInputAction={
                <CircularValidation
                  isValid={filterDetails.name.length >= 2}
                  defaultMsg={t("Filter's name required")}
                  errorMsg={t('Name must be at least 2 characters long')}
                  showDefault={!filterDetails.name.value}
                />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="description"
              placeholder={
                isCustomMarkeplaceApp
                  ? 'Business Type Description'
                  : t('Category Description')
              }
              label={t('Select Type')}
              value={filterDetails.description}
              onChange={value => updateFilter('description', value)}
              rightInputAction={
                <CircularValidation
                  isValid={filterDetails.description.length >= 2}
                  defaultMsg={t("Filter's description is required")}
                  errorMsg={t('Description must be at least 2 characters long')}
                  showDefault={!filterDetails.description.value}
                />
              }
            />
          </Grid>
          {/* <Grid item xs={2}>
            
            <SelectField
        className="offset-top"
        options={filterTypeOptions}
        label="Select Filter Type"
        onChange={(i, payload) => updateFilter('filterType',payload)}
        autoWidth
        value={filterDetails.filterType}
      />
      </Grid> */}
        </Grid>
        {fieldsUI}
        <CreateField />
      </div>
    );

    return (
      <div className="types-container">
        <div className="list-fieldset">{displayError}</div>
        <DialogConfirmation
          open={this.state.confirmDeleteDialogOpen}
          onClose={this.toggleConfirmBox}
          message={t(
            `Are you sure you want to permenantly delete the field? This will also remove all the data which belongs from this field.`,
          )}
          action={() => deleteField(this.state.item)}
        />
        {form}
      </div>
    );
  }
}

EditFilter.propTypes = {
  error: PropTypes.string,
  name: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  type: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  updateFilter: PropTypes.func.isRequired,
  getFilterDetails: PropTypes.func.isRequired,
  filterDetails: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  updateField: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
};

EditFilter.defaultProps = {
  error: null,
};

export default withTranslation()(EditFilter);
