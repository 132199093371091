import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import CheckBox from 'material-ui/svg-icons/toggle/check-box';
import CheckBoxOutlineBlank from 'material-ui/svg-icons/toggle/check-box-outline-blank';

import { RaisedButton } from '../../../../../../../app/components/buttons';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';
import Toggle from '../../../../../../../app/components/toggle';
import TextField from '../../../../../../../app/components/textField';
import CheckboxField from '../../../../../../../app/components/checkbox';
import Legal from '../../../../../../account/modules/legal';
import { Trans, withTranslation } from 'react-i18next';

const styles = {
  radioButton: {
    fontSize: 18,
    padding: '12px 0',
    margin: '6px 0',
    display: 'block',
  },
  radioButtonGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0rem 1rem',
  },
  error: {
    color: '#f00',
    fontSize: '1rem',
    textAlign: 'center',
  },
  loginButton: {
    marginTop: '1.5rem',
  },
};

class InstagramFeed extends Component {
  handleToggle = prop => (e, isToggled) => this.props.onChange(prop, isToggled);
  handleLogin = () => {
    this.props.recordLastEdited();

    window.location.href =
      'https://www.facebook.com/v18.0/dialog/oauth' +
      `?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}` +
      `&redirect_uri=${process.env.REACT_APP_INSTAGRAM_REDIRECT_URI}` +
      `&scope=instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights` +
      `&response_type=code` +
      `&state=${this.props.component.lazy_data}@@${this.props.match.params.appname}=origin=${window.location.origin}`;
  };
  handleLogout = () => {
    this.props.removeAccessToken();
  };

  state = {
    checked: false,
  };

  render() {
    const { data, onChange, onOpenLegalModal, t } = this.props;
    const checkboxLabel = (
      <span>
        <Trans>I have read and agree to</Trans>
        <button
          className="inline-button underline-text"
          style={{ textAlign: 'left' }}
          onClick={onOpenLegalModal('instagram')}
        >
          Beezer&#39;s&nbsp;{' '}
          <Trans>Data Use Processes for Instagram Basic Display API</Trans>
        </button>
      </span>
    );

    if (!data.access_token) {
      return (
        <Tabs>
          <Tab label={t('content')}>
            <Container>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '20px',
                }}
              >
                <CheckboxField
                  id="agreements"
                  checked={this.state.checked}
                  label={checkboxLabel}
                  onChange={() =>
                    this.setState({ checked: !this.state.checked })
                  }
                />
              </div>
              <RaisedButton
                label={t('log in')}
                style={styles.loginButton}
                onClick={this.handleLogin}
                disabled={!this.state.checked}
              />
              <Legal />
            </Container>
          </Tab>
          <Tab
            label="design"
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
          <Tab
            label="layout"
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
        </Tabs>
      );
    }

    if (data.access_token === 'error') {
      return (
        <Tabs>
          <Tab label={t('content')}>
            <Container>
              <p style={styles.error}>
                <Trans>
                  An error occurred with your Instagram login. Please delete
                  this component and try again.
                </Trans>
              </p>
            </Container>
          </Tab>
        </Tabs>
      );
    }

    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <RadioButtonGroup
              name="layout"
              defaultSelected="instagram"
              style={styles.radioButtonGroup}
              value={data.display}
              onChange={e => onChange('display', e.target.value)}
            >
              {/* <RadioButton
                value="simple"
                label="Simple"
                uncheckedIcon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBox />}
                style={styles.radioButton}
              /> */}
              <RadioButton
                value="instagram"
                label="Instagram"
                uncheckedIcon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBox />}
                style={styles.radioButton}
              />
            </RadioButtonGroup>
            <Toggle
              label={t('Instagram Share')}
              toggled={data.instagramShare}
              onToggle={this.handleToggle('instagramShare')}
              className="full-width toggle-button"
            />
            {data.instagramShare && (
              <React.Fragment>
                <TextField
                  id="share"
                  name="share"
                  placeholder={t('Enter a Share Url')}
                  hintText="URL"
                  value={data.shareUrl}
                  onChange={value => onChange('shareUrl', value)}
                />
              </React.Fragment>
            )}
            <RaisedButton
              label={t('log out')}
              style={styles.loginButton}
              onClick={this.handleLogout}
            />
          </Container>
        </Tab>
        <Tab
          label="design"
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
        <Tab
          label="layout"
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

InstagramFeed.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  recordLastEdited: PropTypes.func.isRequired,
  removeAccessToken: PropTypes.func.isRequired,
  onOpenLegalModal: PropTypes.func.isRequired,
};

export default withTranslation()(InstagramFeed);
