import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import TextField from '../../../../../../../app/components/textField';
import DesignToggle from '../../../../../components/designToggle';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';

const IFrame = ({ data, onChange, component, onDesignChange }) => {
  const { t } = useTranslation();

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <TextField
            id="title"
            name="title"
            placeholder={t('Enter a heading for your widget')}
            value={data.title}
            onChange={value => onChange('title', value)}
          />
          <TextField
            id="url"
            name="url"
            placeholder={t('Enter the URL of your widget')}
            value={data.src}
            onChange={value => onChange('src', value)}
          />
          <p style={{ margin: '30px 34px 0' }}>
            <Trans>
              Please note that there are some limitations to using iFrames and
              not all sites will work as expected when used within an iFrame.
            </Trans>
          </p>
        </Container>
      </Tab>
      <Tab
        label={t('design')}
        style={{
          color: '#261ed5',
        }}
      >
        <DesignToggle
          component={component}
          onDesignChange={(el, isToggled) =>
            onDesignChange(null)(el, isToggled)
          }
        />
        <TextField
          id="size"
          name="size"
          type="number"
          disabled={!component.design.enabled}
          placeholder={t('Height of IFrame on mobile.')}
          value={component.design.mobileHeightOverride}
          onChange={value =>
            onDesignChange(null)('mobileHeightOverride', value)
          }
        />
        <TextField
          id="size"
          name="size"
          type="number"
          disabled={!component.design.enabled}
          placeholder={t('Height of IFrame on desktop.')}
          value={component.design.desktopHeightOverride}
          onChange={value =>
            onDesignChange(null)('desktopHeightOverride', value)
          }
        />

        <p style={{ margin: '30px 34px 0' }}>
          <Trans>The value entered above is in Pixels. For example</Trans>:{' '}
          <Trans>
            Entering 100 as a value will result in the iFrame to be 100 Pixels
            tall
          </Trans>
        </p>
      </Tab>
      {/* <Tab
      label="layout"
      disabled
      style={{
        color: '#d9d9d9',
      }}
    /> */}
    </Tabs>
  );
};

IFrame.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  component: PropTypes.object.isRequired,
  onDesignChange: PropTypes.func.isRequired,
};

export default IFrame;
