import React, { useState } from 'react';

export const TherapistsContext = React.createContext({});

export const TherapistsProvider = ({ children }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dialCode, setDialCode] = useState('+44');
  const [imageUrl, setImageUrl] = useState('');

  const resetTherapistData = () => {
    setName('');
    setEmail('');
    setMobile('');
    setDialCode('+44');
    setImageUrl('');
  };

  return (
    <TherapistsContext.Provider
      value={{
        name,
        setName,
        email,
        setEmail,
        mobile,
        setMobile,
        isButtonDisabled,
        setIsButtonDisabled,
        dialCode,
        setDialCode,
        resetTherapistData,
        imageUrl,
        setImageUrl,
      }}
    >
      {children}
    </TherapistsContext.Provider>
  );
};
