import React, { useEffect } from 'react';
import HotelTherapyTable from './HotelTherapyTable';
import HotelTherapyModal from './HotelTherapyModal/HotelTherapyModal';

const HotelTherapy = props => {
  const {
    modalOpen,
    hotels,
    therapists,
    getTherapists,
    getHotels,
    toogleHotelTherapyModal,
    addItemToHotelTherapy,
    updateItemFromHotelTherapy,
    getAllHotelTherapies,
    getAllBookings,
    hotelTherapies,
    bookings,
    isLoading,
    appname,
    hotelTherapyItemChange,
    hotelTherapyItem,
    deleteHotelTherapy,
    getPlMatchSupportCentre,
    plMatchSupportData,
    duplicateHotelTherapy,
  } = props;

  useEffect(() => {
    getPlMatchSupportCentre();
  }, []);

  useEffect(() => {
    getPlMatchSupportCentre();
  }, []);

  return (
    <>
      {modalOpen && (
        <HotelTherapyModal
          appname={appname}
          open={modalOpen}
          toogleHotelTherapyModal={toogleHotelTherapyModal}
          hotelTherapyItemChange={hotelTherapyItemChange}
          onResetHotelTherapyData={() => {}}
          getHotels={getHotels}
          getTherapists={getTherapists}
          therapists={therapists}
          hotels={hotels}
          addItemToHotelTherapy={addItemToHotelTherapy}
          updateItemFromHotelTherapy={updateItemFromHotelTherapy}
          isLoading={isLoading}
          hotelTherapyItem={hotelTherapyItem}
          clubs={plMatchSupportData.filteredChunks}
        />
      )}

      <HotelTherapyTable
        appname={appname}
        toogleHotelTherapyModal={toogleHotelTherapyModal}
        hotelTherapyItemChange={hotelTherapyItemChange}
        deleteHotelTherapy={deleteHotelTherapy}
        loading={false}
        getAllHotelTherapies={getAllHotelTherapies}
        getAllBookings={getAllBookings}
        error={null}
        hotelTherapies={hotelTherapies}
        bookings={bookings}
        isLoading={false}
        addItemToHotelTherapy={addItemToHotelTherapy}
        updateItemFromHotelTherapy={updateItemFromHotelTherapy}
        duplicateHotelTherapy={duplicateHotelTherapy}
      />
    </>
  );
};

export default HotelTherapy;
