import { PGMOL_APPS } from 'config';

export const getAllAppointments = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { appname }] = queryKey;
  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/get-all-appointments/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const addItemToAppointment = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/add-appointment`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const updateItemFromAppointment = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/update-appointment`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const deleteItemFromAppointment = async ({ appname, key }) => {
  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/delete-appointment/${appname}/${key}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const addStaffMember = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/add-staff`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const editStaffMember = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/edit-staff`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getAllStaff = async ({ queryKey }) => {
  const [_key, { appname }] = queryKey;
  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/get-all-staff/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const setMetaData = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/set_meta_data`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getMetaData = async ({ queryKey }) => {
  const [_key, { appname }] = queryKey;

  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/get_meta_data/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getStripeDetails = async ({ queryKey }) => {
  const [_key, { appname }] = queryKey;

  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/get_stipe_details/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getAllBookings = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { appname }] = queryKey;
  const path = PGMOL_APPS.includes(appname)
    ? 'get_pgmol_user_bookings'
    : 'get_bookings';

  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/${path}/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const assignStaffToBooking = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BOOKING_API}/assign_staff_to_booking`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const cancelBooking = async ({ ...data }) => {
  const path = PGMOL_APPS.includes(data.appname)
    ? 'cancel_pgmol_booking'
    : 'cancel_booking';

  const response = await fetch(`${process.env.REACT_APP_BOOKING_API}/${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};
