import { openSnackbar } from 'app/modules/snackbar/actions';
import firebase from '../../../../firebase';

export const TOGGLE_PL_THERAPIST_MODAL = 'TOGGLE_PL_THERAPIST_MODAL';
export const RECEIVE_PL_THERAPISTS = 'RECEIVE_PL_THERAPISTS';
export const EDIT_THERAPIST_DATA = 'EDIT_THERAPIST_DATA';
export const SELECT_ALL_PL_THERAPISTS = 'SELECT_ALL_PL_THERAPISTS';
export const DESELECT_ALL_PL_THERAPISTS = 'DESELECT_ALL_PL_THERAPISTS';
export const SELECT_PL_THERAPIST = 'SELECT_PL_THERAPIST';
export const SET_ENABLE_THERAPIST_BOOKING = 'SET_ENABLE_THERAPIST_BOOKING';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';
export const enableTherapistBooking = value => ({
  type: SET_ENABLE_THERAPIST_BOOKING,
  value,
});

export const toggleTherapistModal = value => ({
  type: TOGGLE_PL_THERAPIST_MODAL,
  value,
});

export const receiveTherapists = value => ({
  type: RECEIVE_PL_THERAPISTS,
  value,
});

export const onUpdatePlTherapist = (rowId, data) => ({
  type: EDIT_THERAPIST_DATA,
  rowId,
  value: data,
});

export const onSelectAllTherapists = () => ({
  type: SELECT_ALL_PL_THERAPISTS,
});

export const onDeselectAllTherapists = () => ({
  type: DESELECT_ALL_PL_THERAPISTS,
});

export const selectTherapist = key => ({
  type: SELECT_PL_THERAPIST,
  key,
});

export const deletePlTherapists = (appname, selectedRowId) => (
  dispatch,
  getState,
) => {
  const { selected } = getState().pgmol.plTherapists;

  const ref = firebase.database().ref(`pgmol/therapists/${appname}`);

  if (!selected.length && selectedRowId.length) {
    Promise.all(selectedRowId.map(key => ref.child(`${key}`).remove())).then(
      () => {
        dispatch(openSnackbar(`Therapist deleted successfully.`));
      },
    );
  } else {
    Promise.all(selected.map(key => ref.child(`${key}`).remove())).then(() => {
      dispatch(openSnackbar(`Therapist deleted successfully.`));
    });
  }
};

export const submitTherapist = (appname, data) => async dispatch => {
  const { key, ...rest } = data;
  let message = '';
  const ref = firebase.database().ref(`pgmol/therapists/${appname}`);
  if (key) {
    await ref.child(`${data.key}`).update({ ...rest });
    message = `Therapist added successfully.`;
  } else {
    await ref.push({ ...rest, createdAt: new Date().getTime() });
    message = `Therapist updated successfully.`;
  }
  dispatch(openSnackbar(message));
  dispatch(toggleTherapistModal(false));
};

export const toggleTherapistBooking = (appname, value) => dispatch => {
  const ref = firebase.database().ref(`app_groups/${appname}/default`);
  ref.update({ therapistBooking: value }).then(() => {
    dispatch(openSnackbar(value ? 'Booking Enabled.' : 'Booking Disabled.'));
  });
};

export const getTherapistBooking = appname => dispatch => {
  const ref = firebase
    .database()
    .ref(`app_groups/${appname}/default/therapistBooking`);
  ref.on('value', snap => {
    const data = snap.val();
    dispatch(enableTherapistBooking(data || false));
  });
};
