import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Therapists from './Therapists';
import { getTherapists } from '../PlMatchSupport/actions';
import {
  deletePlTherapists,
  onDeselectAllTherapists,
  onSelectAllTherapists,
  onUpdatePlTherapist,
  selectTherapist,
  submitTherapist,
  toggleTherapistModal,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.pgmol.plTherapists,
  appname: ownProps.match.params.appname,
  plTherapists: state.pgmol.plTherapists,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  onSelectAllTherapists: () => dispatch(onSelectAllTherapists()),
  onDeselectAllTherapists: () => dispatch(onDeselectAllTherapists()),
  onDeleteSelected: rowId =>
    dispatch(deletePlTherapists(ownProps.match.params.appname, rowId)),
  onUpdatePlTherapist: (rowId, data) =>
    dispatch(onUpdatePlTherapist(rowId, data)),
  onSelect: key => dispatch(selectTherapist(key)),
  getTherapists: () => dispatch(getTherapists(ownProps.match.params.appname)),
  toggleTherapistModal: value => dispatch(toggleTherapistModal(value)),
  submitTherapist: data =>
    dispatch(submitTherapist(ownProps.match.params.appname, data)),
});

export default withRouter(
  connect(mapStateToProps, mapDisptachToProps)(Therapists),
);
