import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ConfirmedBookings from './ConfirmedBookings';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  appname: ownProps.match.params.appname,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmedBookings),
);
