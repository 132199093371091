import {
  ADD_COURSES_DIALOG,
  COURSE_ITEM_CHANGE,
  COURSE_RESET_DATA,
} from './types';

const initialState = {
  addCoursesDialogOpen: false,
  coursesItem: {},
};
const onlineCourses = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COURSES_DIALOG:
      return {
        ...state,
        addCoursesDialogOpen: action.payload,
      };
    case COURSE_ITEM_CHANGE:
      return {
        ...state,
        coursesItem: action.payload,
      };
    case COURSE_RESET_DATA:
      return initialState;
    default:
      return state;
  }
};

export default onlineCourses;
