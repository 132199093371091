import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PgmolMenu from './PgmolMenu';
import {
  getTherapistBooking,
  toggleTherapistBooking,
} from '../Therapists/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  therapistBookingEnabled: state.pgmol.plTherapists.enableTherapistBooking,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleBooking: v =>
    dispatch(toggleTherapistBooking(ownProps.match.params.appname, v)),
  getTherapistBooking: () =>
    dispatch(getTherapistBooking(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PgmolMenu),
);
