import { connect } from 'react-redux';

import Filters from './Filters';
import {
  selectFilter,
  searchFilters,
  selectAllFilters,
  deselectAllFilters,
  deleteFilters,
  filterData,
  getFilters,
  filterChange
} from './actions';

import { openNewFilterDialog } from './newFilter/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  pending: state.ecommerce.marketplace.filters.data.pending,
  chunks: state.ecommerce.marketplace.filters.data.chunks,
  filteredChunks: state.ecommerce.marketplace.filters.data.filteredChunks,
  error: state.ecommerce.marketplace.filters.data.error,
  items: state.ecommerce.marketplace.filters.data.items,
  selected: state.ecommerce.marketplace.filters.data.selected,
  selectAll: state.ecommerce.marketplace.filters.data.selectAll,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addNewFilter: () => dispatch(openNewFilterDialog()),
  onSelect: key => dispatch(selectFilter(key)),
  onSearch: query => dispatch(searchFilters(query)),
  onSelectAllFilters: () => dispatch(selectAllFilters()),
  onDeselectAllFilters: () => dispatch(deselectAllFilters()),
  onDeleteSelected: selected =>
    dispatch(deleteFilters(ownProps.match.params.appname, selected)),
  onFilterData: filter => dispatch(filterData(filter)),
  getFilters: () => dispatch(getFilters(ownProps.match.params.appname)),
  onFilterChange: filter => dispatch(filterChange(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
