// import ice from './templateImages/ice.webp';
// import frankfurter from './templateImages/frankfurter.webp';
import marketplace from './templateImages/marketplace.webp';
import cafe from './templateImages/cafe.webp';
// import event from './templateImages/event.webp';
import pub from './templateImages/pub.webp';
import wedding from './templateImages/wedding.webp';
import retailnew from './templateImages/retailnew.webp';
import mosque from './templateImages/mosque.webp';
import salon from './templateImages/salon.webp';
import maintenance from './templateImages/maintenance.webp';
import plumber from './templateImages/plumber.webp';
import book from './templateImages/booking.webp';
import candle from './templateImages/candle.webp';
import education from './templateImages/education.webp';
import health from './templateImages/health.webp';
import realestate from './templateImages/realestate.webp';
import entertainer from './templateImages/entertainer_artist.webp';
import sportsClubTeam from './templateImages/sports_team.webp';
import charity from './templateImages/charity.webp';
import agency from './templateImages/agency.webp';
import trainingProgram from './templateImages/training_program.webp';
import restaurantPubReopening from './templateImages/pub_restaurant_reopening.webp';
import blank from './templateImages/blank.webp';
import yoga from './templateImages/yoga.webp';

export const componentInstructionLinks = {
  text: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360020754931-Text-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Text+Component.pdf',
  },
  image: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360020486792-Image-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Image+Component.pdf',
  },
  header: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021329571-Header-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Header+Component.pdf',
  },
  slider: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021080592-Slider-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Slider+Component.pdf',
  },
  radioStreaming: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360040334131-Radio-Streaming',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Radio+Component.pdf',
  },
  button: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021080952-Button-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Button+Component.pdf',
  },
  delegates: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360016773291-Delegates-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Delegates+Component.pdf',
  },
  loyalty: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360050353091-Loyalty-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Loyalty%2BComponent.pdf',
  },

  forum: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360056524051-Forum-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Forum+Component.pdf',
  },
  form: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360016772691-Form-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Form_+Component.pdf',
  },
  foodOrder: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360020064817-Business-Tools-Food-Orders',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Business+Tools+-+Food+Orders.pdf',
  },
  rating: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021331431-Rating-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Rating+Component.pdf',
  },
  roster: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021081212-Roster-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Roster+Component.pdf',
  },
  schedule: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021102232-Schedule-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Schedule+Component.pdf',
  },
  file: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021102392-File-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/File+Component.pdf',
  },
  media: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360040163071-In-app-Music-Player',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Media+Component.pdf',
  },
  vote: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360020754531-Vote-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Vote+Component.pdf',
  },
  workout: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360048357512-Workout-Timer-History',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Workout+Timer.pdf',
  },
  iframe: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021351391-iFrame-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Iframe__Component.pdf',
  },
  notification: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021351731-Notification-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Notification+Component.pdf',
  },
  countdown: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360038414571-Countdown-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Countdown+Component.pdf',
  },
  popup: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360040021131-Popup-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Popup+Component.pdf',
  },
  payNow: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360042628252-PayNow-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Pay+Now+Component.pdf',
  },
  canva: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360056378891-Canva-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Canva+Component.pdf',
  },
  imageGallery: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/4402775272081-Image-Gallery',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Image+Gallery+Component.pdf',
  },
  facebookPage: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021103872-Facebook-Page',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Facebook+Page.pdf',
  },
  googleMaps: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021353231-Google-Maps',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Google+Maps+Component.pdf',
  },
  instagramFeed: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021354671-Instagram-Feed',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Instagram+Feed.pdf',
  },
  twitter: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021105752-Twitter-Feed-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Twitter+Feed+Component.pdf',
  },
  wordPress: {
    link: 'https://beezer.zendesk.com/hc/en-us/articles/360021354731-Wordpress',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/WordPress.pdf',
  },
  youTube: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360038416411-YouTube-Vimeo',
    subAdminLink: 'https://kb-articles.s3.eu-west-2.amazonaws.com/YouTube.pdf',
  },
  liveStream: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360051561552-Live-Streaming-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Livestream+Component.pdf',
  },
  vimeoVideo: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360038416411-YouTube-Vimeo',
    subAdminLink: 'https://kb-articles.s3.eu-west-2.amazonaws.com/Vimeo.pdf',
  },
  zoom: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360019699457-Zoom-Integration',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Zoom+Integration.pdf',
  },
  html: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/13693148810001-Custom-HTML',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Custom+HTML.pdf',
  },
  appointedd: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360021105992-Appointedd',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Appointed.pdf',
  },
  shopify: {
    link: 'https://beezer.zendesk.com/hc/en-us/articles/360021355751-Shopify',
    subAdminLink: 'https://kb-articles.s3.eu-west-2.amazonaws.com/Shopify.pdf',
  },
  woocommerce: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/360038935011-WooCommerce',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/WooCommerce.pdf',
  },
  eventOrganizer: {
    link: 'https://beezer.zendesk.com/hc/en-us/articles/4402813034897',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Event+Organizer.pdf',
  },
  eventCalendar: {
    link: 'https://beezer.zendesk.com/hc/en-us/articles/4402899123601',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Event+Component.pdf',
  },
  appointment: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/5608165130641-Business-Tools-Bookings',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Booking+-1Appointments.pdf',
  },
  marketplace: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/6185359080849-Business-Tools-Marketplace',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Business+Tools+Marketplace.pdf',
  },
  feedback: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/7886904523281-Feedback-Component',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Video+Feedback.pdf',
  },
  pdfViewer: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/13731740023185-PDF-Viewer',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/PDF+Library.pdf',
  },
  subscriptionPlans: {
    link: 'https://beezer.zendesk.com/hc/en-us/articles/20224426062097',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Subscription+Plans+Component.pdf',
  },
  blog: {
    link:
      'https://beezer.zendesk.com/hc/en-us/articles/29634406161937-Blog-Articles',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Blog+Component_2.pdf',
  },
  onlineCourse: {
    link: 'https://beezer.zendesk.com/hc/en-us',
    subAdminLink:
      'https://kb-articles.s3.eu-west-2.amazonaws.com/Blog+Component_2.pdf',
  },
};

// options for creating dynamic fields for workforce groups
export const OPTIONS = [
  {
    id: 'Text',
    value: 'Text',
  },
  {
    id: 'Dropdown',
    value: 'Dropdown',
  },
  {
    id: 'Date',
    value: 'Date',
  },
  {
    id: 'ExpiryDate',
    value: 'ExpiryDate',
  },
  {
    id: 'Location',
    value: 'Location',
  },
  {
    id: 'Number',
    value: 'Number',
  },
  {
    id: 'File',
    value: 'File',
  },
];
export const MARKETPLACE_FILTER_FIELD_OPTIONS = [
  {
    id: 'Text',
    value: 'Text',
  },
  {
    id: 'Dropdown',
    value: 'Dropdown',
  },
  {
    id: 'Date',
    value: 'Date',
  },
  {
    id: 'ExpiryDate',
    value: 'ExpiryDate',
  },
  {
    id: 'Location',
    value: 'Location',
  },
  {
    id: 'Number',
    value: 'Number',
  },
];

export const MARKETPLACE_FILTER_OPTIONS = [
  {
    id: 'Product',
    value: 'Product',
  },
  {
    id: 'Vendor',
    value: 'Vendor',
  },
];

// user role for staff admin
export const STAFF_ADMIN = 'staff_admin';

// In this const you can specify the apps which will access the features of HS
// WF - Workforce Member
// WFB - Workforce Member Groups
// STA - Staff Admin
// SA - Super Admin
// MB - Member Body
// ECD - Events/Courses/CPDs
// HS - HorseScotland Application
// [feature] Multiple user type "STA, SA, WF"
// [feature] Platform Modules for SA - MB, STA, WF, WFB
// [feature] Platform Modules for STA - MB, ECD
// [feature] Platform Modules for STA - MB, ECD
// [feature] Platform Component for AppShell ECD
// [feature] AppShell Modules for WF - Manage WF profile
export const PLAN_COST_DISABLED_ACCOUNTS = [
  '-MBtKgsd5OrXPKom6WUP',
  '-MBtKpNV5B0D0O7Za_ah',
  '-MBtL0dLfukuQMlEQ8K6',
  '-MBtLCx-v74EzalTKMUa',
  '-MBtLK2v19LJjzdTiV8w',
  '-MBtLSj-3Qlb7K7nD6HN',
  '-MBtL_mNBm-PKPm693T3',
  '-MBtLj_H_Chs2B_KN_lx',
  '-MBtLv9W_rovjsIGkY-L',
  '-MBtM2uQCTZ_V2DleOLl',
  '-MBtMGSy_PZZCIsBI9iy',
  '-MBtMNfoVh59rFo20dG6',
  '-MBtMU-9Gff7K3Egx4iN',
  '-MBtMxSEkTv3Omij5kdk',
  '-MBtN2wkemF9DpaqTX8q',
];

export const REMOVE_500_OFFER = [
  '-MBtKgsd5OrXPKom6WUP',
  '-MBtKpNV5B0D0O7Za_ah',
  '-MBtL0dLfukuQMlEQ8K6',
  '-MBtLCx-v74EzalTKMUa',
  '-MBtLK2v19LJjzdTiV8w',
  '-MBtLSj-3Qlb7K7nD6HN',
  '-MBtL_mNBm-PKPm693T3',
  '-MBtLj_H_Chs2B_KN_lx',
  '-MBtLv9W_rovjsIGkY-L',
  '-MBtM2uQCTZ_V2DleOLl',
  '-MBtMGSy_PZZCIsBI9iy',
  '-MBtMNfoVh59rFo20dG6',
  '-MBtMU-9Gff7K3Egx4iN',
  '-MBtMxSEkTv3Omij5kdk',
  '-MBtN2wkemF9DpaqTX8q',
  '-MOHY4vQYtiWXOx5E6zw',
  '-MN0mfHnO5zDIThGhVzl',
  '-MOHZTXlvgGtxgMI_lwM',
  '-MWZUnP3ePQHT70fxWw1',
  '-MWZUHN8cfjo8ApOSyRU',
  '-MWY9YzcFx6timx9HQ66',
];

export const HS_FEATURES_ENABLED_APPS = [
  'horsescotland',
  'horsescotlandcafe',
  'horsescotlandstage',
  'testingapp1',
  'testingapp2',
  'demo12',
  'pgmoldemo',
];

export const SCOTTISH_CURLING_FEATURES_ENABLED_APPS = [
  'sajjadstaging', //testing
  'bookingnew', //testing
  'skillawards',
];

export const isSpanishApp = appname => {
  const spanishAppList = [
    'biblio_clon',
    'biblio',
    'stagingbear',
    'sjd2199',
    'sjdtps',
    'tuascension5d',
    'adrimomfit',
    'ascavas',
    'avocado',
    'avocadodemo',
    'connectaton',
    'csaltocostanera',
    'cuidamostubienestar',
    'eldemanolin',
    'eliasgarciapt',
    'fitevolutio',
    'foodtogo',
    'grupovida',
    'hamburgueseriasharky',
    'indalfood',
    'laslomas',
    'metodocampeona',
    'personaltrainingapp',
    'plisplass',
    'rincongallego',
    'starfit',
    'taboaonline',
    'ukelelehawaiifood',
    'vergarasport',
  ];
  return spanishAppList.includes(appname);
};

export const isSpanishAccount = accountId => {
  const spanishAccountList = [
    '-MSbQUhoAv8CNfhhcXJm',
    '-NGuaWKAn_lkwhes7rpE',
    '-NMmrhpoRPGB4Ot4YG9t',
    '-NN6XkguHx_gBZvjfmAV',
    '-MUCX3g35pkrB5QiyTMx',
    '-NCUxj1kJmOG6fHR1Gsw',
    '-N1PtbUw0yHNEfMo5FXa',
  ];
  return spanishAccountList.includes(accountId);
};
export const FOOTBALL_ACADEMY_APPS = [
  'footballacademy',
  'socceracademy',
  'fkacademy1',
  'anything2',
];

export const PGMOL_APPS = [
  'lsclone',
  'pgmoldemo',
  'performancesupport',
  'pgmoltest',
  'testevent102',
  'referrerapptest',
];

export const SMS_REVIEW_ACCOUNTS = [
  '-N3-nRUHIESMVMvyqoQo',
  '-NwWcT9kc3JkVz1I-TWC',
  '-Nwie1Qad2ETkEPcU3En',
  '-NwYmjf4X7fjn9k8zCzi',
];

export const LOCATION_FEATURE_ENABLED_APPS = ['eve1', 'fastvodka'];

export const BILLING_CYCLE = [
  {
    label: 'Daily',
    value: 'day',
  },
  {
    label: 'Weekly',
    value: 'week',
  },
  {
    label: 'Monthly',
    value: 'month',
  },
  {
    label: 'Every 3 months',
    value: 'quarter',
  },
  {
    label: 'Every 6 months',
    value: 'semiannual',
  },
  {
    label: 'Yearly',
    value: 'year',
  },
];

export const STRIPE_CURRENCIES = [
  {
    label: 'USD - US Dollar',
    value: 'USD',
    symbol: '$',
  },
  {
    label: 'AED - United Arab Emirates Dirham',
    value: 'AED',
  },
  {
    label: 'AFN - Afghan Afghani',
    value: 'AFN',
  },
  {
    label: 'ALL - Albanian Lek',
    value: 'ALL',
  },
  {
    label: 'AMD - Armenian Dram',
    value: 'AMD',
  },
  {
    label: 'ANG - Netherlands Antillean Guilder',
    value: 'ANG',
  },
  {
    label: 'AOA - Angolan Kwanza',
    value: 'AOA',
  },
  {
    label: 'ARS - Argentine Peso',
    value: 'ARS',
  },
  {
    label: 'AUD - Australian Dollar',
    value: 'AUD',
  },

  {
    label: 'AWG - Aruban Florin',
    value: 'AWG',
  },
  {
    label: 'AZN - Azerbaijani Manat',
    value: 'AZN',
  },
  {
    label: 'BAM - Bosnia-Herzegovina Convertible Mark',
    value: 'BAM',
  },
  {
    label: 'BBD - Barbadian Dollar',
    value: 'BBD',
  },
  {
    label: 'BGN - Bulgarian Lev',
    value: 'BGN',
  },

  {
    label: 'BIF - Burundian Franc',
    value: 'BIF',
  },

  {
    label: 'BMD - Bermudan Dollar',
    value: 'BMD',
  },
  {
    label: 'BND - Brunei Dollar',
    value: 'BND',
  },
  {
    label: 'BOB - Bolivian Boliviano',
    value: 'BOB',
  },
  {
    label: 'BRL - Brazilian Real',
    value: 'BRL',
  },
  {
    label: 'BSD - Bahamian Dollar',
    value: 'BSD',
  },
  {
    label: 'BWP - Botswanan Pula',
    value: 'BWP',
  },
  {
    label: 'BZD - Belize Dollar',
    value: 'BZD',
  },
  {
    label: 'CAD - Canadian Dollar',
    value: 'CAD',
  },
  {
    label: 'CDF - Congolese Franc',
    value: 'CDF',
  },
  {
    label: 'CHF - Swiss Franc',
    value: 'CHF',
  },
  {
    label: 'CLP - Chilean Peso',
    value: 'CLP',
  },
  {
    label: 'CNY - Chinese Yuan',
    value: 'CNY',
  },
  {
    label: 'COP - Colombian Peso',
    value: 'COP',
  },

  {
    label: 'CRC - Costa Rican Colón',
    value: 'CRC',
  },
  {
    label: 'CVE - Cape Verdean Escudo',
    value: 'CVE',
  },
  {
    label: 'CZK - Czech Koruna',
    value: 'CZK',
  },
  {
    label: 'DJF - Djiboutian Franc',
    value: 'DJF',
  },
  {
    label: 'DKK - Danish Krone',
    value: 'DKK',
  },

  {
    label: 'DOP - Dominican Peso',
    value: 'DOP',
  },
  {
    label: 'DZD - Algerian Dinar',
    value: 'DZD',
  },
  {
    label: 'EGP - Egyptian Pound',
    value: 'EGP',
  },

  {
    label: 'ETB - Ethiopian Birr',
    value: 'ETB',
  },
  {
    label: 'EUR - Euro',
    value: 'EUR',
  },
  {
    label: 'FJD - Fijian Dollar',
    value: 'FJD',
  },

  {
    label: 'FKP - Falkland Islands Pound',
    value: 'FKP',
  },
  {
    label: 'GBP - British Pound',
    value: 'GBP',
    symbol: '£',
  },
  {
    label: 'GEL - Georgian Lari',
    value: 'GEL',
  },
  {
    label: 'GIP - Gibraltar Pound',
    value: 'GIP',
  },
  {
    label: 'GMD - Gambian Dalasi',
    value: 'GMD',
  },
  {
    label: 'GNF - Guinean Franc',
    value: 'GNF',
  },
  {
    label: 'GTQ - Guatemalan Quetzal',
    value: 'GTQ',
  },
  {
    label: 'GYD - Guyanaese Dollar',
    value: 'GYD',
  },
  {
    label: 'HKD - Hong Kong Dollar',
    value: 'HKD',
  },
  {
    label: 'HNL - Honduran Lempira',
    value: 'HNL',
  },
  {
    label: 'HRK - Croatian Kuna',
    value: 'HRK',
  },
  {
    label: 'HTG - Haitian Gourde',
    value: 'HTG',
  },
  {
    label: 'HUF - Hungarian Forint',
    value: 'HUF',
  },
  {
    label: 'IDR - Indonesian Rupiah',
    value: 'IDR',
  },
  {
    label: 'ILS - Israeli New Shekel',
    value: 'ILS',
  },
  {
    label: 'INR - Indian Rupee',
    value: 'INR',
  },
  {
    label: 'ISK - Icelandic Króna',
    value: 'ISK',
  },
  {
    label: 'JMD - Jamaican Dollar',
    value: 'JMD',
  },
  {
    label: 'JPY - Japanese Yen',
    value: 'JPY',
  },
  {
    label: 'KES - Kenyan Shilling',
    value: 'KES',
  },
  {
    label: 'KGS - Kyrgystani Som',
    value: 'KGS',
  },
  {
    label: 'KHR - Cambodian Riel',
    value: 'KHR',
  },
  {
    label: 'KMF - Comorian Franc',
    value: 'KMF',
  },

  {
    label: 'KRW - South Korean Won',
    value: 'KRW',
  },
  {
    label: 'KYD - Cayman Islands Dollar',
    value: 'KYD',
  },
  {
    label: 'KZT - Kazakhstani Tenge',
    value: 'KZT',
  },
  {
    label: 'LAK - Laotian Kip',
    value: 'LAK',
  },
  {
    label: 'LBP - Lebanese Pound',
    value: 'LBP',
  },
  {
    label: 'LRD - Liberian Dollar',
    value: 'LRD',
  },
  {
    label: 'LSL - Lesotho Loti',
    value: 'LSL',
  },
  {
    label: 'MAD - Moroccan Dirham',
    value: 'MAD',
  },

  {
    label: 'MGA - Malagasy Ariary',
    value: 'MGA',
  },
  {
    label: 'MKD - Macedonian Denar',
    value: 'MKD',
  },
  {
    label: 'MMK - Myanmar Kyat',
    value: 'MMK',
  },

  {
    label: 'MNT - Mongolian Tugrik',
    value: 'MNT',
  },
  {
    label: 'MOP - Macanese Pataca',
    value: 'MOP',
  },
  {
    label: 'MRO - Mauritanian Ouguiya (1973–2017)',
    value: 'MRO',
  },
  {
    label: 'MUR - Mauritian Rupee',
    value: 'MUR',
  },
  {
    label: 'MVR - Maldivian Rufiyaa',
    value: 'MVR',
  },
  {
    label: 'MWK - Malawian Kwacha',
    value: 'MWK',
  },
  {
    label: 'MXN - Mexican Peso',
    value: 'MXN',
  },
  {
    label: 'MYR - Malaysian Ringgit',
    value: 'MYR',
  },
  {
    label: 'MZN - Mozambican Metical',
    value: 'MZN',
  },
  {
    label: 'NAD - Namibian Dollar',
    value: 'NAD',
  },

  {
    label: 'NGN - Nigerian Naira',
    value: 'NGN',
  },
  {
    label: 'NIO - Nicaraguan Córdoba',
    value: 'NIO',
  },
  {
    label: 'NOK - Norwegian Krone',
    value: 'NOK',
  },
  {
    label: 'NPR - Nepalese Rupee',
    value: 'NPR',
  },

  {
    label: 'NZD - New Zealand Dollar',
    value: 'NZD',
  },
  {
    label: 'PAB - Panamanian Balboa',
    value: 'PAB',
  },
  {
    label: 'PEN - Peruvian Sol',
    value: 'PEN',
  },

  {
    label: 'PGK - Papua New Guinean Kina',
    value: 'PGK',
  },
  {
    label: 'PHP - Philippine Piso',
    value: 'PHP',
  },
  {
    label: 'PKR - Pakistani Rupee',
    value: 'PKR',
  },
  {
    label: 'PLN - Polish Zloty',
    value: 'PLN',
  },
  {
    label: 'PYG - Paraguayan Guarani',
    value: 'PYG',
  },
  {
    label: 'QAR - Qatari Rial',
    value: 'QAR',
  },
  {
    label: 'RON - Romanian Leu',
    value: 'RON',
  },

  {
    label: 'RSD - Serbian Dinar',
    value: 'RSD',
  },
  {
    label: 'RUB - Russian Ruble',
    value: 'RUB',
  },
  {
    label: 'RWF - Rwandan Franc',
    value: 'RWF',
  },

  {
    label: 'SAR - Saudi Riyal',
    value: 'SAR',
  },
  {
    label: 'SBD - Solomon Islands Dollar',
    value: 'SBD',
  },

  {
    label: 'SCR - Seychellois Rupee',
    value: 'SCR',
  },
  {
    label: 'SEK - Swedish Krona',
    value: 'SEK',
  },

  {
    label: 'SGD - Singapore Dollar',
    value: 'SGD',
  },
  {
    label: 'SHP - St. Helena Pound',
    value: 'SHP',
  },
  {
    label: 'SLL - Sierra Leonean Leone',
    value: 'SLL',
  },
  {
    label: 'SRD - Surinamese Dollar',
    value: 'SRD',
  },
  {
    label: 'STD - São Tomé &amp; Príncipe Dobra (1977–2017)',
    value: 'STD',
  },
  {
    label: 'SZL - Swazi Lilangeni',
    value: 'SZL',
  },
  {
    label: 'THB - Thai Baht',
    value: 'THB',
  },

  {
    label: 'TOP - Tongan Paʻanga',
    value: 'TOP',
  },
  {
    label: 'TRY - Turkish Lira',
    value: 'TRY',
  },
  {
    label: 'TTD - Trinidad &amp; Tobago Dollar',
    value: 'TTD',
  },
  {
    label: 'TWD - New Taiwan Dollar',
    value: 'TWD',
  },
  {
    label: 'TZS - Tanzanian Shilling',
    value: 'TZS',
  },
  {
    label: 'UAH - Ukrainian Hryvnia',
    value: 'UAH',
  },
  {
    label: 'UGX - Ugandan Shilling',
    value: 'UGX',
  },
  {
    label: 'UYU - Uruguayan Peso',
    value: 'UYU',
  },
  {
    label: 'UZS - Uzbekistani Som',
    value: 'UZS',
  },
  {
    label: 'VND - Vietnamese Dong',
    value: 'VND',
  },
  {
    label: 'VUV - Vanuatu Vatu',
    value: 'VUV',
  },
  {
    label: 'WST - Samoan Tala',
    value: 'WST',
  },
  {
    label: 'XAF - Central African CFA Franc',
    value: 'XAF',
  },
  {
    label: 'XCD - East Caribbean Dollar',
    value: 'XCD',
  },
  {
    label: 'XOF - West African CFA Franc',
    value: 'XOF',
  },
  {
    label: 'XPF - CFP Franc',
    value: 'XPF',
  },
  {
    label: 'YER - Yemeni Rial',
    value: 'YER',
  },
  {
    label: 'ZAR - South African Rand',
    value: 'ZAR',
  },
  {
    label: 'ZMW - Zambian Kwacha',
    value: 'ZMW',
  },
];

export const appTemplates = {
  blank: {
    label: 'Blank',
    type: 'blankapptemplate',
    content: 'blankapptemplate',
    appTitle: 'blankapptemplate',
    image: blank,
  },
  marketplace: {
    label: 'Marketplace',
    type: 'marketplaceapptemplate',
    content: 'marketplaceapptemplate',
    appTitle: 'marketplaceapptemplate',
    image: marketplace,
  },
  rest_pub_opening: {
    label: 'SPECIAL: Restaurant/Pub Reopening',
    type: 'restaurantapptemplate',
    content: 'restaurantapptemplate',
    appTitle: 'restaurantapptemplate',
    image: restaurantPubReopening,
  },
  cafe: {
    label: 'Cafe',
    type: 'cafeapptemplate',
    content: 'cafeapptemplate',
    appTitle: 'cafeapptemplate',
    image: cafe,
  },
  event: {
    label: 'Event',
    type: 'eventapptemplate',
    content: 'eventapptemplate',
    appTitle: 'artist12',
    image: entertainer,
  },
  book: {
    label: 'BOOK CLUB',
    type: 'bookapptemplate',
    content: 'bookapptemplate',
    appTitle: 'bookapptemplate',
    image: book,
  },
  candle: {
    label: 'CANDLE BUSINESS',
    type: 'candleapptemplate',
    content: 'candleapptemplate',
    appTitle: 'candleapptemplate',
    image: candle,
  },
  retail: {
    label: 'Retail',
    type: 'retailapptemplate',
    content: 'retailapptemplate',
    appTitle: 'retailapptemplate',
    image: retailnew,
  },
  plumber: {
    label: 'PLUMBER/BATHROOM REPAIRS',
    type: 'plumberapptemplate',
    content: 'plumberapptemplate',
    appTitle: 'plumberapptemplate',
    image: plumber,
  },
  maintenanceservices: {
    label: 'Maintenance services',
    type: 'maintainenceapptemplate',
    content: 'maintainenceapptemplate',
    appTitle: 'maintainenceapptemplate',
    image: maintenance,
  },
  mypub: {
    label: 'Pub/Bar',
    type: 'pubapptemplate',
    content: 'pubapptemplate',
    appTitle: 'pubapptemplate',
    image: pub,
  },
  hairdresser_barber_salon: {
    label: 'Hairdresser/Barber/Salon',
    type: 'salonapptemplate',
    content: 'salonapptemplate',
    appTitle: 'salonapptemplate',
    image: salon,
  },
  mosque: {
    label: 'Religious Organisation',
    type: 'religiousapptemplate',
    content: 'religiousapptemplate',
    appTitle: 'churches',
    image: mosque,
  },
  wedding: {
    label: 'Wedding',
    type: 'weddingapptemplate',
    content: 'weddingapptemplate',
    appTitle: 'weddingapptemplate',
    image: wedding,
  },
  education: {
    label: 'Educational',
    type: 'educationalapptemplate',
    content: 'educationalapptemplate',
    appTitle: 'educationalapptemplate',
    image: education,
  },
  health: {
    label: 'Health and Fitness',
    type: 'fitnessapptemplate',
    content: 'fitnessapptemplate',
    appTitle: 'fitnessapptemplate',
    image: health,
  },
  yoga: {
    label: 'Yoga',
    type: 'yogatemplate',
    content: 'yogatemplate',
    appTitle: 'yogatemplate',
    image: yoga,
  },
  realestate: {
    label: 'Real Estate',
    type: 'realestateapptemplate',
    content: 'realestateapptemplate',
    appTitle: 'realestateapptemplate',
    image: realestate,
  },
  entertainerArtist: {
    label: 'Entertainer/Artist',
    type: 'entertainerapptemplate',
    content: 'entertainerapptemplate',
    appTitle: 'entertainerapptemplate',
    image: entertainer,
  },
  sportsClubTeam: {
    label: 'Sports Club/Team',
    type: 'sportsclubapptemplate',
    content: 'sportsclubapptemplate',
    appTitle: 'sportsclubapptemplate',
    image: sportsClubTeam,
  },
  charity: {
    label: 'Charity',
    type: 'charityapptemplate',
    content: 'charityapptemplate',
    appTitle: 'charityapptemplate',
    image: charity,
  },
  agency: {
    label: 'Agency/Consulting',
    type: 'agencyapptemplate',
    content: 'agencyapptemplate',
    appTitle: 'agencyapptemplate',
    image: agency,
  },
  trainingProgram: {
    label: 'Online Training Program',
    type: 'onlinetrainingapptemplate',
    content: 'onlinetrainingapptemplate',
    appTitle: 'onlinetrainingapptemplate',
    image: trainingProgram,
  },
  yoga: {
    label: 'Yoga',
    type: 'yogatemplate',
    content: 'yogatemplate',
    appTitle: 'yogatemplate',
    image: yoga,
  },
};

export const PROMO_DETAILS = {
  startDate: new Date('Nov 23 2021 GMT').getTime(),
  endDate: new Date('Dec 01 2021  GMT').getTime(),
  registerPageContent: `
  &lt;p&gt; Black Friday is one of the most exciting days of the year to shop for your favourite products without breaking the bank. At Beezer, we decided that one day is not enough - for this reason, we will be celebrating the entire week! Starting from today until the 30th of November 00:00 am GMT, you will be able to save 50% off our Everything plan (monthly and yearly) and maximize your app building experience for just half of the price. And the best part is - if you sign up on the 30th of November, you can keep the price forever!
&lt;/p&gt;
    
  &lt;p&gt; With the Everything Plan, you will be able to enjoy all of our premium features and integrations without any extra fees. From configuring your custom domain to sending unlimited push notifications, you will have the full set of tools to build your awesome app. Grab your exclusive deal today!&lt;/p&gt;
  &lt;p&gt; Use &lt;strong&gt;BLACKFRIDAYNOV30&lt;/strong&gt; at checkout and get 50% off our Everything plan (yearly and monthly) today! - and keep the price for a lifetime!&lt;/p&gt;
    `,
  registerPageTitle: `Last chance! Get 50% off our Everything plan and keep the price forever.`,
  popUpContent:
    '&lt;p&gt;Hello! Remember that you only have until 00:00 am GMT on Tuesday, 30th of November to grab your exclusive 50% off our Everything plan (monthly and yearly). Upgrade today to enjoy all premium features and integrations for just half of the price, and keep the price forever. &lt;/p&gt; &lt;p&gt;Use &lt;strong&gt;BLACKFRIDAYNOV30&lt;/strong&gt; at checkout and get 50% off our Everything plan (yearly and monthly) today!&lt;/p&gt;',
  plan: ['deluxe'],
  promoImageDark: '/black-friday-dark.png',
  promoImageLight: '/black-friday-light.png',
  promoSaleImage: '/black-friday-sale.png',
  discountPercent: 50,
  promoCode: 'BLACKFRIDAYNOV30',
  termsAndConditionsLink: 'https://www.beezer.com/terms-conditions-everything',
};

export const INTERNAL_APP_PATHS = [
  'checkout-_cart',
  'geotracking',
  'marketplace',
  'semvisits',
  'user-profile',
  'workforce-profile',
  'forum-_view',
  'order-_delivered',
  'order-_cancelled',
  'checkout-_cart',
  'workout_history',
  'sign-up',
  'sign-in',
  'forgotten-password',
  'reset-password',
  'profile',
  'diary',
  'scanner',
  'orders-_placed',
  'subscriptions',
  'bookings-placed',
  'my-subscriptions',
  'payments',
  'workforce-profile',
  'assign-group',
  'chats-list',
  'chat',
  'chats',
  'chat-attachments',
  'attendees',
  'create-group',
  'attendee-profile',
  'marketplace_zone',
  'marketplace_zone',
  'marketplace_order',
  'marketplace_product',
  'stripe_connect_check',
];

export const DISABLE_HOW_TO_LINK_ACCOUNTS = ['4DY7HuzyqyW2I2qVr6IFLK7zdbA3'];

export const ADD_APP_ADMINS_APPS = ['singleappadmin2024', 'mentor'];
