import { connect } from 'react-redux';

import Ecommerce from './Ecommerce';

import { addInventoryDialogOpen, inventoryItemChange } from './actions';
import { openNewFilterDialog } from './modules/marketplace/modules/filters/newFilter/actions';
import { openUserFilterFieldDialog } from './modules/marketplace/modules/filters/editFilter/modules/create/actions';
import { loadContent } from '../memberLogin/actions';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { addAppointmentDialogOpen } from './modules/bookings/modules/appointments/actions';
import { addStaffDialogOpen } from './modules/bookings/modules/staff/actions';
import {
  toggleAuth,
  getAuth,
  stopGetAuth,
} from '../memberLogin/modules/memberLoginMenu/actions';
import { closeUpgradeDialog } from 'modules/memberLogin/modules/upgradeDialog/actions';
import { addCoursesDialogOpen } from './modules/onlineCourses/modules/courses/action';
const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.inventory,
  appname: ownProps.match.params.appname,
  selected: state.distribute.contacts.data.selected,
  subscription: state.app.appContext.subscription,
  smsCredit: state.account.organisation.sms_credit,
  organisation: state.account.organisation,
  plan:
    state.account.subscriptions.items[ownProps.match.params.appname] &&
    state.account.subscriptions.items[ownProps.match.params.appname].plan,
  foodOrderEnabled: state.ecommerce.structure.foodOrderEnabled,
  marketplaceEnabled: state.ecommerce.structure.marketplaceEnabled,
  bookingEnabled: state.ecommerce.structure.bookingEnabled,
  onlineCourseEnabled: state.ecommerce.structure.onlineCourseEnabled,
  isAppSumoAccount:
    (state.account.organisation.appsumo_id &&
      state.account.organisation.appsumo_id.length > 0) ||
    false,
  is14DayTrialUser: state.account.organisation.isOn14DaysTrial || false,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
  memberLoginEnabled: get(state, 'auth.structure.enabled', false),
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  onRequestClose: () => dispatch(closeUpgradeDialog()),
  onAddFilter: () => dispatch(openNewFilterDialog()),
  onAddField: () => dispatch(openUserFilterFieldDialog()),
  onAddInventoryDialogOpen: isOpen => dispatch(addInventoryDialogOpen(isOpen)),
  onInventoryItemChange: item => dispatch(inventoryItemChange(item)),
  loadContent: () => dispatch(loadContent(ownProps.match.params.appname)),
  onAddAppointmentDialogOpen: isOpen =>
    dispatch(addAppointmentDialogOpen(isOpen)),
  onAddCourseDialogOpen: isOpen => dispatch(addCoursesDialogOpen(isOpen)),
  onAddStaffMemberDialog: isOpen => dispatch(addStaffDialogOpen(isOpen)),
  enableMemberLogin: () =>
    dispatch(toggleAuth(ownProps.match.params.appname, true)),
  getAuth: () => dispatch(getAuth(ownProps.match.params.appname)),
  stopGetAuth: () => dispatch(stopGetAuth(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDisptachToProps)(Ecommerce),
);
