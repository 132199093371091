import React, { useState } from 'react';

export const HotelTherapyContext = React.createContext({});

export const HotelTherapyProvider = ({ children }) => {
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [match, setMatch] = useState('');
  const [description, setDescription] = useState('');
  const [capacity, setCapacity] = useState(1);
  const [date, setDate] = useState('');
  const [key, setKey] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [appointmentType, setAppointmentType] = useState(0);
  const [bookingType, setBookingType] = useState('single');
  const [timeslots, setTimesloTs] = useState([
    { time: new Date(), capacityBooked: 0 },
  ]);
  const [appointmentDuration, setAppointmentDuration] = useState(30);
  const [timezone, setTimezone] = useState({
    value: '',
    label: '',
  });
  const [hotel, setHotel] = useState({
    value: '',
    label: '',
  });
  const [therapist, setTherapist] = useState({
    value: '',
    label: '',
  });
  const [club, setClub] = useState({
    value: '',
    label: '',
  });

  const [address, setAddress] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedStaffMember, setSelectedStaffMember] = useState([]);
  const [location, setLocation] = useState({ value: '', locationData: {} });
  const [eventData, setEventData] = useState();
  const [appointmentError, setAppointmentError] = useState(null);
  const [slotsError, setSlotsError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const addTimeSlot = () => {
    setTimesloTs(prev => {
      if (prev.length >= 4) return prev;
      return [...prev, { time: new Date(), capacityBooked: 0 }];
    });
  };

  const removeTimeSlot = index => {
    setTimesloTs(prev => prev.filter((_, i) => i !== index));
  };

  const updateTimeSlot = (index, newTime) => {
    setTimesloTs(prev =>
      prev.map((slot, i) => (i === index ? { ...slot, time: newTime } : slot)),
    );
  };

  return (
    <HotelTherapyContext.Provider
      value={{
        match,
        setMatch,
        description,
        setDescription,
        capacity,
        setCapacity,
        date,
        setDate,
        key,
        setKey,
        isButtonDisabled,
        setIsButtonDisabled,
        appointmentType,
        setAppointmentType,
        appointmentDuration,
        setAppointmentDuration,
        timezone,
        setTimezone,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        selectedStaffMember,
        setSelectedStaffMember,
        location,
        setLocation,
        eventData,
        setEventData,
        appointmentError,
        setAppointmentError,
        slotsError,
        setSlotsError,
        imageUrl,
        setImageUrl,
        submitted,
        setSubmitted,
        hotel,
        setHotel,
        therapist,
        setTherapist,
        address,
        setAddress,
        club,
        setClub,
        timeslots,
        setTimesloTs,
        addTimeSlot,
        removeTimeSlot,
        updateTimeSlot,
        bookingType,
        setBookingType,
        isDuplicate,
        setIsDuplicate,
      }}
    >
      {children}
    </HotelTherapyContext.Provider>
  );
};
