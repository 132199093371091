import React, { useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
import debounce from 'lodash.debounce';

export default function RichTextEditor({ initialValue = '', onChange }) {
  const editor = useRef(null);
  const [content, setContent] = useState(initialValue);

  const config = {
    readonly: false,
    height: 400,
    toolbar: true,
    spellcheck: true,
    language: 'en',
    toolbarButtonSize: 'medium',
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'ul',
      'ol',
      '|',
      'paragraph',
      'fontsize',
      // 'brush',
      '|',
      // 'image',
      // 'video',
      'table',
      'link',
      '|',
      'align',
      '|',
      'undo',
      'redo',
    ],
  };

  const handleUpdate = debounce(newContent => {
    setContent(newContent);
    if (onChange) {
      onChange(newContent);
    }
  }, 1000); // Adjust debounce time as needed

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      onChange={handleUpdate}
    />
  );
}
