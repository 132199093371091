import {
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ActionMenu from 'app/components/actionMenu';
import { Empty as EmptyIcon } from 'app/components/icons';
import Search from 'app/components/search';
import {
  get,
  isEmpty,
  keys,
  pickBy,
  reduce,
  set,
  size,
  capitalize,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { downloadCSV, objectToArray } from 'utils';
import themeStyles from '../../../../../../_export.scss';
import { getAllCourses, getAllParticipants } from '../../api';
import styles from './styles.module.scss';
import { useStyles } from './customStyles';

const ParticipantsTable = props => {
  const { appname, setOpenDialog, setParticipantDetails } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [courseFilter, setCourseFilter] = useState('all');
  const [participantsData, setParticipantsData] = useState();
  const [coursesData, setCoursesData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [dateOrderFilter, setDateOrderFilter] = useState('desc');

  const { data, error, isLoading, isError } = useQuery(
    ['participants', { appname }],
    getAllParticipants,
  );

  const {
    data: courses,
    error: coursesError,
    isLoading: isCoursesLoading,
    isError: coursesIsError,
  } = useQuery(['courseItems', { appname }], getAllCourses);

  const [chunks, setChunks] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  /** 
  ### Sort participants by date order 
  * @param {{order:string, participants:object}} 
  * @returns {object} sorted participants object
  **/
  const orderParticipantsByDate = ({ order, participants }) =>
    Object.fromEntries(
      Object.entries(participants).sort(([, bk1], [, bk2]) =>
        order === 'asc'
          ? bk1.created_at - bk2.created_at
          : bk2.created_at - bk1.created_at,
      ),
    );

  useEffect(() => {
    setCoursesData(objectToArray(courses));
  }, [courses]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const orderedParticipantsData = orderParticipantsByDate({
        order: dateOrderFilter,
        participants: data,
      });
      setParticipantsData(orderedParticipantsData);
    }
  }, [data]);

  const handleApplyFilter = async () => {
    const participants = Object.entries(data);
    const res = participants.filter(participant => {
      // Only apply course filter if not "all"
      const courseFilterPassed =
        courseFilter === 'all' ||
        Object.keys(participant[1].enrollments).some(
          item => item === courseFilter,
        );

      const searchFilter =
        (participant[1].firstName &&
          participant[1].firstName
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (participant[1].lastName &&
          participant[1].lastName
            .toString()
            .includes(searchText.toLowerCase())) ||
        (participant[1].email &&
          participant[1].email.toString().includes(searchText.toLowerCase()));

      return courseFilterPassed && (searchFilter || !searchText);
    });

    let filteredParticipantData = {};
    let orderedParticipantsData = {};
    if (!isEmpty(res)) {
      filteredParticipantData = res.reduce(
        (object, [key, value]) => ({ ...object, [key]: value }),
        {},
      );
      orderedParticipantsData = orderParticipantsByDate({
        order: dateOrderFilter,
        participants: filteredParticipantData,
      });
    }

    setParticipantsData(orderedParticipantsData);
  };

  useEffect(() => {
    if (data) {
      handleApplyFilter();
    }
  }, [searchText, courseFilter]);

  const getCsvData = (selectedParticipants, participantChunks) => {
    const csvColumns = [
      t('Firstname'),
      t('Lastname'),
      t('Email'),
      t('Courses Enrolled'),
    ];
    let csvData = `${csvColumns.join(',')}\n`;
    selectedParticipants.forEach(key => {
      csvData += `${participantChunks[key].firstName ||
        '-'},${participantChunks[key].lastName || '-'},${participantChunks[key]
        .email || '-'},${Object.values(participantChunks[key].enrollments)
        .map(enrollment => enrollment.title)
        .join(' | ') || '-'}\n`;
    });
    return csvData;
  };

  useEffect(() => {
    setChunks(participantsData);
  }, [participantsData]);

  if (isLoading || isCoursesLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  if (isError || coursesIsError) {
    return (
      <span>
        <Trans>Error:</Trans> {error.message || coursesError.message}
      </span>
    );
  }

  const onSelect = id => {
    const modifiedChunks = { ...chunks };
    const prevValue = get(modifiedChunks, `${id}.selected`, false);
    set(modifiedChunks, `${id}.selected`, !prevValue);
    setChunks(modifiedChunks);
    const selectedItems = keys(pickBy(modifiedChunks, chunk => chunk.selected));
    setSelected(selectedItems);
  };

  const selectAllParticipants = () => {
    const allIds = keys(chunks);
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: true,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected(allIds);
    setSelectAll(true);
  };

  const deselectAllParticipants = () => {
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: false,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected([]);
    setSelectAll(false);
  };
  return (
    <div className="scroll-container">
      <div className={`row middle-xs table-heading ${styles.scrollHeader}`}>
        <div className="col-xs-2">
          <span className={styles.headerLabel}>
            <Trans>Participants</Trans>: {size(participantsData)}
          </span>
        </div>
        {!isEmpty(data) && (
          <>
            <div className="col-xs-6">
              <Search
                name="search"
                placeholder={t('Search...')}
                value={searchText}
                onChange={val => setSearchText(val)}
                onClear={() => setSearchText('')}
              />
            </div>
            <div className="col-xs-4">
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="participants-date"
                  style={{ left: '-15px' }}
                >
                  <Trans>Courses</Trans>
                </InputLabel>
                <Select
                  variant="standard"
                  id="participants-courses"
                  value={courseFilter}
                  onChange={e => setCourseFilter(e.target.value)}
                >
                  <MenuItem value="all">
                    <Trans>All Courses</Trans>
                  </MenuItem>
                  {coursesData.map(course => (
                    <MenuItem key={course.id} value={course.id}>
                      <Trans>{course.value.title}</Trans>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </>
        )}
      </div>
      {isEmpty(chunks) ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>
              You don't have any participants from your online course yet.
            </Trans>
          </h5>
          <p style={{ textAlign: 'center' }}>
            <Trans>
              Your app users will be able to enroll for a course from the app.
              Start by adding your first course.
            </Trans>{' '}
            <Link to={`/${appname}/ecommerce/online-courses/courses`}>
              <Trans>here</Trans>
            </Link>
          </p>
        </div>
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{ maxHeight: 650, borderRadius: 15, boxShadow: 'none' }}
          >
            <Table
              stickyHeader
              aria-label="customized table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Trans>First Name</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Last Name</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Email</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Courses Enrolled</Trans>
                  </TableCell>
                  <TableCell>
                    <div className="options-container">
                      <Checkbox
                        disableRipple
                        checked={selectAll || false}
                        onChange={(e, isChecked) =>
                          isChecked
                            ? selectAllParticipants()
                            : deselectAllParticipants()
                        }
                        style={{ color: themeStyles.primaryColor }}
                      />
                      <ActionMenu
                        selected={selected}
                        onDownloadSelected={() => {
                          downloadCSV(
                            getCsvData(selected, chunks),
                            t('participant'),
                          );
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!isEmpty(participantsData) > 0 &&
                  Object.keys(chunks).map(key => (
                    <TableRow
                      hover
                      key={key}
                      onClick={() => {
                        setOpenDialog(true);
                        setParticipantDetails(chunks[key]);
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {capitalize(chunks[key].firstName) || '-'}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {capitalize(chunks[key].lastName) || '-'}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {chunks[key].email || '-'}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {chunks[key].enrollments
                          ? Object.keys(chunks[key].enrollments).length
                          : '0'}
                      </TableCell>

                      <TableCell>
                        <div className="options-container">
                          <Checkbox
                            disableRipple
                            checked={chunks[key].selected || false}
                            onClick={e => e.stopPropagation()}
                            onChange={e => {
                              e.stopPropagation();
                              onSelect(key);
                            }}
                            style={{ color: themeStyles.primaryColor }}
                          />
                          <ActionMenu
                            selected={[key]}
                            onViewSelected={() => {
                              setOpenDialog(true);
                              setParticipantDetails(chunks[key]);
                            }}
                            onDownloadSelected={() =>
                              downloadCSV(
                                getCsvData([key], chunks),
                                t('participant'),
                              )
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

ParticipantsTable.propTypes = {
  appname: PropTypes.string.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  setParticipantDetails: PropTypes.func.isRequired,
};

export default ParticipantsTable;
