import {
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ActionMenu from 'app/components/actionMenu';
import { Empty as EmptyIcon } from 'app/components/icons';
import Search from 'app/components/search';
import { get, isEmpty, keys, pickBy, reduce, set, size } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { downloadCSV } from 'utils';
import themeStyles from '../../../../../../_export.scss';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import {
  getAllCourseEnrollments,
  getAllCourses,
  duplicateCourse,
} from '../../api';
import styles from './styles.module.scss';
import { useStyles } from './customStyles';

const CUSTOM_ERROR_MESSAGE =
  'You cannot delete a course that has active participants.';

const CoursesTable = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { appname, onCoursesDialogOpen, onCoursesItemChange, currency } = props;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [coursesData, setCourseData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [dateOrderFilter, setDateOrderFilter] = useState('desc');
  const [courseStatusFilter, setCourseStatusFilter] = useState('all');
  const [timeFilter, setTimeFilter] = useState('all');
  const [chunks, setChunks] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { mutateAsync, isLoading: isloadingDuplicate } = useMutation(
    duplicateCourse,
  );

  const { data, error, isLoading, isError } = useQuery(
    ['courseItems', { appname }],
    getAllCourses,
  );

  const {
    data: enrollmentsData,
    error: enrollmentsError,
    isLoading: enrollmentsLoading,
    isError: isEnrollmentsError,
  } = useQuery(['courseEnrollments', { appname }], getAllCourseEnrollments);

  const calculateCompletedCounts = useCallback(enrollmentsData => {
    if (!enrollmentsData) return {};

    return Object.entries(enrollmentsData).reduce((acc, [courseId, data]) => {
      const completedCount = Object.values(data.participants || {}).filter(
        participant => participant.progress.completed === 100,
      ).length;
      acc[courseId] = completedCount;
      return acc;
    }, {});
  }, []);

  const completedCounts = useMemo(
    () => calculateCompletedCounts(enrollmentsData),
    [enrollmentsData, calculateCompletedCounts],
  );

  /** 
  ### Sort courses by date order 
  * @param {{order:string, bookings:object}} 
  * @returns {object} sorted courses object
  **/
  const orderCoursesByDate = ({ order, courses }) =>
    Object.fromEntries(
      Object.entries(courses).sort(([, apt1], [, apt2]) =>
        order === 'asc'
          ? apt1.created_at - apt2.created_at
          : apt2.created_at - apt1.created_at,
      ),
    );

  const getCsvData = (selectedValues, chunkData) => {
    const csvColumns = [
      t('Course Name'),
      t('Date Created'),
      t('Cost'),
      t('Participants'),
      t('Completed By'),
      t('Status'),
    ];
    let records = `${csvColumns.join(',')}\n`;
    selectedValues.forEach(key => {
      records += `${chunkData[key].title || '-'},${moment(
        get(chunkData[key], 'created_at', 0),
      ).format(`MM/DD/YY`)},${(chunkData[key].isPaid &&
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(Number(chunkData[key].price))) ||
        'Free'},${chunkData[key].participants || '0'},${completedCounts[key] ||
        '0'},${chunkData[key].isActive ? t('Active') : t('Inactive')}\n`;
    });
    return records;
  };

  useEffect(() => {
    if (data) {
      const orderedCoursesData = orderCoursesByDate({
        order: dateOrderFilter,
        courses: data,
      });
      setCourseData(orderedCoursesData);
    } else setCourseData({});
  }, [data]);

  const handleApplyFilter = async () => {
    let timeValue = [0, 'days'];
    let pastDate = 0;

    if (timeFilter && timeFilter !== 'all') {
      timeValue = timeFilter.split(':');
      pastDate = moment()
        .subtract(timeValue[0], timeValue[1])
        .valueOf();
    }

    const courses = Object.entries(data);
    const res = courses.filter(course => {
      const timeQuery = pastDate < course[1].created_at;
      const searchFilter = course[1].title
        .toLowerCase()
        .includes(searchText.toLowerCase());
      let statusFilter = true;

      if (courseStatusFilter === 'active') {
        statusFilter = course[1].isActive;
      } else if (courseStatusFilter === 'inactive') {
        statusFilter = !course[1].isActive;
      }
      return timeQuery && (searchFilter || !searchText) && statusFilter;
    });

    let filteredCourseData = {};
    let orderedCoursesData = {};
    if (!isEmpty(res)) {
      filteredCourseData = res.reduce(
        (object, [key, value]) => ({ ...object, [key]: value }),
        {},
      );
      orderedCoursesData = orderCoursesByDate({
        order: dateOrderFilter,
        courses: filteredCourseData,
      });
    }

    setCourseData(orderedCoursesData);
  };

  useEffect(() => {
    if (data) {
      handleApplyFilter();
    }
  }, [searchText, timeFilter, dateOrderFilter, courseStatusFilter]);

  useEffect(() => {
    setChunks(coursesData);
  }, [coursesData]);

  const onSelect = id => {
    const modifiedChunks = { ...chunks };
    const prevValue = get(modifiedChunks, `${id}.selected`, false);
    set(modifiedChunks, `${id}.selected`, !prevValue);
    setChunks(modifiedChunks);
    const selectedItems = keys(pickBy(modifiedChunks, chunk => chunk.selected));
    setSelected(selectedItems);
  };

  const selectAllCourses = () => {
    const allIds = keys(chunks);
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: true,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected(allIds);
    setSelectAll(true);
  };

  const deselectAllCourses = () => {
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: false,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected([]);
    setSelectAll(false);
  };

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  if (isError) {
    return (
      <span>
        <Trans>Error:</Trans> {error.message}
      </span>
    );
  }

  const onDeleteSelected = selected => {
    Promise.all(
      selected.map(key => {
        const courseData = coursesData[key];
        if (courseData.participants > 0) {
          return Promise.reject(new Error(CUSTOM_ERROR_MESSAGE));
        }

        fetch(
          `${process.env.REACT_APP_ONLINE_COURSE_API}/delete-course/${appname}/${key}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ appname, key }),
          },
        );
      }),
    )
      .then(() => {
        queryClient.invalidateQueries(['courseItems', { appname }]);
        dispatch(
          openSnackbar(
            `Online Course${
              selected.length > 1 ? 's' : ''
            } delete successfully.`,
          ),
        );
      })
      .catch(e => {
        dispatch(
          openSnackbar(
            e.message === CUSTOM_ERROR_MESSAGE
              ? CUSTOM_ERROR_MESSAGE
              : 'Unable to delete course.',
          ),
        );
      });
  };

  const handleEdit = key => {
    onCoursesDialogOpen(true);
    onCoursesItemChange(coursesData[key]);
  };

  const handleDuplicate = async key => {
    try {
      await mutateAsync({ courseData: coursesData[key], appname });
      dispatch(openSnackbar(`Online Course duplicated successfully.`));
      queryClient.invalidateQueries(['courseItems', { appname }]);
    } catch (e) {
      dispatch(openSnackbar(`Unable to duplicate course.`));
    }
  };

  return (
    <div className="scroll-container">
      <div className={`row middle-xs table-heading ${styles.scrollHeader}`}>
        <div className="col-xs-2">
          <span className={styles.scrollHeader}>
            <Trans>Courses:</Trans> {size(coursesData)}
          </span>
        </div>
        {!isEmpty(data) ? (
          <>
            <div className="col-xs-6">
              <Search
                name="search"
                placeholder={t('Search...')}
                value={searchText}
                onChange={val => setSearchText(val)}
                onClear={() => setSearchText('')}
              />
            </div>
            <div className="col-xs-2">
              <FormControl fullWidth>
                <InputLabel htmlFor="bookings-date" style={{ left: '-15px' }}>
                  <Trans>Courses From</Trans>
                </InputLabel>
                <Select
                  variant="standard"
                  id="bookings-date"
                  value={timeFilter}
                  onChange={e => setTimeFilter(e.target.value)}
                >
                  <MenuItem value="all">
                    <Trans>All time</Trans>
                  </MenuItem>
                  <MenuItem value="30:days">
                    <Trans>Past 30 Days</Trans>
                  </MenuItem>
                  <MenuItem value="3:months">
                    <Trans>Past 3 Months</Trans>
                  </MenuItem>
                  <MenuItem value="6:months">
                    <Trans>Past 6 Months</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-xs-2">
              <FormControl fullWidth>
                <InputLabel htmlFor="bookings-date" style={{ left: '-15px' }}>
                  <Trans>Status</Trans>
                </InputLabel>
                <Select
                  variant="standard"
                  id="bookings-status"
                  value={courseStatusFilter}
                  onChange={e => setCourseStatusFilter(e.target.value)}
                >
                  <MenuItem value="all">
                    <Trans>All</Trans>
                  </MenuItem>
                  <MenuItem value="active">
                    <Trans>Active</Trans>
                  </MenuItem>
                  <MenuItem value="inactive">
                    <Trans>Inactive</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {isEmpty(chunks) ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You haven't added any online courses yet.</Trans>
          </h5>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <Trans>Click the</Trans>&nbsp;
            <AddCircleIcon />
            &nbsp;<Trans>button to add a new course.</Trans>
          </p>
          <p style={{ textAlign: 'center' }}>
            <Trans>
              You can add multiple courses which will be displayed in a list
              within the app. Your app users will be able to click on an course,
              see course details and book it. You can create free and paid
              courses.
            </Trans>
          </p>
          <p style={{ textAlign: 'center' }}>
            <Trans>
              To enable payment via the app, go to Settings and add your Stripe
              API keys.
            </Trans>{' '}
            <Link to={`/${appname}/ecommerce/online-courses/settings`}>
              <Trans>Add Stripe API Keys</Trans>
            </Link>
            .{' '}
            {!props.isAppAdmin ? (
              <a
                href="https://beezer.zendesk.com/hc/en-us"
                rel="noreferrer noopener"
                target="_blank"
                style={{ color: themeStyles.primaryColor }}
              >
                <Trans>Find out more.</Trans>
              </a>
            ) : (
              ''
            )}
          </p>
        </div>
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{ maxHeight: 650, borderRadius: 15, boxShadow: 'none' }}
          >
            <Table
              stickyHeader
              aria-label="customized table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  <TableCell>
                    <Trans>Course Title</Trans>
                  </TableCell>
                  <TableCell>
                    <div className={styles.dateHeader}>
                      <Trans>Date Created</Trans>
                      {dateOrderFilter === 'desc' ? (
                        <IconButton
                          onClick={() => setDateOrderFilter('asc')}
                          color="primary"
                          title={t('Sort by oldest')}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => setDateOrderFilter('desc')}
                          color="primary"
                          title={t('Sort by newest')}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Trans>Cost</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Participants</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Completed by</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Status</Trans>
                  </TableCell>
                  <TableCell>
                    <div className="options-container">
                      <Checkbox
                        disableRipple
                        checked={selectAll || false}
                        onChange={(e, isChecked) =>
                          isChecked ? selectAllCourses() : deselectAllCourses()
                        }
                        style={{ color: themeStyles.primaryColor }}
                      />
                      <ActionMenu
                        selected={selected}
                        onDeleteSelected={() => onDeleteSelected(selected)}
                        onDownloadSelected={() => {
                          downloadCSV(
                            getCsvData(selected, chunks),
                            t('courses'),
                          );
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {coursesData &&
                  Object.keys(chunks).map((key, i) => (
                    <>
                      <TableRow
                        hover
                        key={`${key}`}
                        onClick={() => {
                          handleEdit(key);
                        }}
                      >
                        <TableCell width="20%" component="th" scope="row">
                          {chunks[key].title || '-'}
                        </TableCell>

                        <TableCell width="15%" component="th" scope="row">
                          {moment(get(chunks[key], 'created_at', 0)).format(
                            `MM/DD/YY`,
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {(chunks[key].isPaid &&
                            new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency,
                              minimumFractionDigits: 0, // Ensure no decimal places
                              maximumFractionDigits: 0, // Ensure no decimal places
                            }).format(Number(chunks[key].price))) ||
                            'Free'}
                        </TableCell>

                        <TableCell component="th" scope="row" align="center">
                          {chunks[key].participants || '0'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {completedCounts[key] || '0'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {
                            <>
                              {chunks[key].isActive ? (
                                <span className={styles.success}>
                                  <Trans>Active</Trans>
                                </span>
                              ) : (
                                <span className={styles.error}>
                                  <Trans>Inactive</Trans>
                                </span>
                              )}
                            </>
                          }
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="options-container">
                            <Checkbox
                              disableRipple
                              checked={chunks[key].selected || false}
                              onClick={e => e.stopPropagation()}
                              onChange={e => {
                                e.stopPropagation();
                                onSelect(key);
                              }}
                              style={{ color: themeStyles.primaryColor }}
                            />
                            <ActionMenu
                              selected={[key]}
                              onEditSelected={() => handleEdit(key)}
                              onDuplicateSelected={() => handleDuplicate(key)}
                              onDeleteSelected={() => onDeleteSelected([key])}
                              onDownloadSelected={() =>
                                downloadCSV(
                                  getCsvData([key], chunks),
                                  t('courses'),
                                )
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

CoursesTable.propTypes = {
  onCoursesDialogOpen: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
  onResetCourseData: PropTypes.func.isRequired,
  isAppAdmin: PropTypes.bool.isRequired,
  onCoursesItemChange: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

export default CoursesTable;
