import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  addButton: {
    marginLeft: 'auto',
    display: 'inline-block',
    textTransform: 'capitalize',
    padding: '0px',
    '&:hover': {
      border: 'none',
      background: 'none',
    },
  },
  removeButton: {
    width: '200px',
    marginTop: '20px',
    border: '1px solid #9c27b0!important',
  },
  customToggle: {
    marginTop: '20px',
  },
  subTitle: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '0.8rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));
