import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { Trans, withTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import Badge from '../../components/badge';
import Thing from '../../components/thing';
import {
  plansAppSumo,
  TIER_1_PLAN_ID,
  TIER_2_PLAN_ID,
  TIER_3_PLAN_ID,
  TIER_4_PLAN_ID,
  TIER_5_PLAN_ID,
  plans,
} from '../../../../plans';

import './styles.css';

class Billing extends Component {
  static propTypes = {
    billing: PropTypes.array,
    t: PropTypes.func.isRequired,
    apps: PropTypes.object,
    // payment stuff is passed as a child
    children: PropTypes.node.isRequired,
    intl: PropTypes.shape({
      currency: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    apps: [],
    billing: [],
  };

  // Stripe plans
  getInvoiceLabel = invoice => {
    const invoiceDataList = get(invoice, 'lines.data', []);
    const latestData = invoiceDataList[invoiceDataList.length - 1];
    let planId = get(latestData, 'plan.id', '');
    if (planId) {
      if (planId.includes('diy')) planId = 'diy';
      if (planId.includes('deluxe')) planId = 'deluxe';
      if (planId.includes('accelerate')) planId = 'deluxe';
      if (planId.includes('something')) planId = 'something';
      if (planId.includes('addon')) planId = 'addon';
      if (planId.includes('diary')) planId = 'addon';
      switch (planId) {
        case 'accelerate':
        case 'accelerate_yearly':
        case 'accelerate_yearly-2023':
          return plans.accelerate.title;
        case 'something':
        case 'something_yearly':
        case 'something_yearly-2023':
          return plans.something.title;
        case 'launch':
        case 'launch_yearly':
        case 'launch_yearly-2023':
          return plans.launch.title;
        case 'premium_yearly':
        case 'premium_yearly-2023':
        case 'premium':
          return 'premium';
        case 'expert_yearly':
        case 'expert_yearly-2023':
        case 'expert_new':
        case 'expert':
          return plans.expert.title;
        case 'deluxe_yearly':
        case 'deluxe_yearly-2023':
        case 'deluxe_lifetime':
        case 'deluxe':
          return plans.deluxe.title;
        case 'diy_yearly':
        case 'diy_yearly-2023':
        case 'diy':
          return plans.diy.title;
        case 'addon':
          return 'Add On';
        case TIER_1_PLAN_ID:
          return plansAppSumo[TIER_1_PLAN_ID].title;
        case TIER_2_PLAN_ID:
          return plansAppSumo[TIER_2_PLAN_ID].title;
        case TIER_3_PLAN_ID:
          return plansAppSumo[TIER_3_PLAN_ID].title;
        case TIER_4_PLAN_ID:
          return plansAppSumo[TIER_4_PLAN_ID].title;
        case TIER_5_PLAN_ID:
          return plansAppSumo[TIER_5_PLAN_ID].title;
        default:
          return 'Charge';
      }
    }
    return 'Charge';
  };

  getDate = invoice => moment(invoice.created * 1000).format('DD/MM/YY');

  getComponentFromMetaData = invoice => {
    if (
      Object.keys(invoice.metadata).length > 0 &&
      invoice.metadata.componentName
    ) {
      const componentName = invoice.metadata.componentName;
      return `${
        componentName === 'CustomDomain' ? 'Custom Domain' : componentName
      } component`;
    }
    return '';
  };

  getTitle = invoice => {
    const { t } = this.props;
    const invoiceLabel = this.getInvoiceLabel(invoice);
    let title = '';
    if (invoiceLabel) {
      title = `${invoiceLabel} ${t('Plan')} ${
        !invoice.subscription_details.metadata ||
        Object.keys(invoice.subscription_details.metadata).length === 0
          ? ''
          : invoice.subscription_details.metadata.plan.includes('yearly')
          ? t('Yearly')
          : t('Monthly')
      }`;
      if (invoiceLabel === 'Add On') {
        title = this.getComponentFromMetaData(invoice) || invoiceLabel;
      }
    }
    return title;
  };

  getSubtitle = invoice => {
    const { t } = this.props;
    switch (invoice.type || invoice.object || invoice.metadata.plan) {
      case 'subscription':
        return `${this.getInvoiceLabel(invoice)} ${t('Subscription')}`;

      default:
        if (invoice.metadata.plan) {
          return `${this.getInvoiceLabel(invoice)} ${t('Subscription')}`;
        }
        return '';
    }
  };

  getAmount = invoice => (invoice.amount_paid / 100).toFixed(2);

  getPlanName = subscriptionName => {
    if (subscriptionName.includes('diy')) subscriptionName = 'diy';
    if (subscriptionName.includes('deluxe')) subscriptionName = 'deluxe';
    if (subscriptionName.includes('something')) subscriptionName = 'something';
    return subscriptionName;
  };

  displayDiscountExpiryMsg = () => {
    if (this.props.lastRedeemedCoupon) {
      const currentDate = Date.now();
      const planName = this.getPlanName(this.props.subscription.plan);
      const currentActiveProduct =
        this.props.subscription.period === 'monthly'
          ? plans[planName].monthly_product_id
          : plans[planName].yearly_product_id;
      const couponActiveForProduct =
        this.props.lastRedeemedCoupon.product === currentActiveProduct &&
        (this.props.lastRedeemedCoupon.endDate >= currentDate ||
          this.props.lastRedeemedCoupon.duration === 'forever');

      if (couponActiveForProduct) {
        return (
          <p className="coupon-expiry-msg">
            <Trans>Your Discount of</Trans>{' '}
            {this.props.lastRedeemedCoupon.percent_off}% <Trans>for</Trans>{' '}
            {this.getInvoiceLabel({ metadata: { plan: planName } })}{' '}
            <Trans>Plan will expire on</Trans>{' '}
            <strong>
              {' '}
              {new Date(
                this.props.lastRedeemedCoupon.endDate,
              ).toLocaleDateString()}
            </strong>
          </p>
        );
      }
      return '';
    }
  };

  invoiceLink = invoicePdf => (
    <a
      href={invoicePdf}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: '#231fd1', fontWeight: 500 }}
    >
      <Trans>Download Invoice</Trans>
    </a>
  );

  render() {
    const { t, account } = this.props;
    const billing = this.props.billing || [];
    return (
      <div className="billing">
        <div className="card">
          <h3 className="header">
            <Trans>Your Card</Trans>
          </h3>
          {this.props.children}
        </div>
        <div className="invoice">
          <h3 className="header">
            <Trans>Invoice History</Trans>
          </h3>
          {account && account.ref === 'dealfuel' ? (
            <>
              <p>
                Please visit your{' '}
                <a
                  href="https://www.dealfuel.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DealFuel
                </a>{' '}
                account to view your invoice history
              </p>
            </>
          ) : (
            <>
              {' '}
              {this.displayDiscountExpiryMsg()}
              {billing.map(invoice => (
                <Thing key={invoice.key} className="billing billing-data">
                  <div className="margin-right">
                    <Badge label={this.getInvoiceLabel(invoice)} />
                  </div>
                  <div className="details">
                    {this.getTitle(invoice) && (
                      <h5 className="title">{this.getTitle(invoice)}</h5>
                    )}
                    {this.getSubtitle(invoice) && (
                      <small className="subtitle">
                        {this.getSubtitle(invoice)}
                      </small>
                    )}
                    <small className="subtitle invoice-amount-link">
                      <span>
                        <FormattedNumber
                          maximumFractionDigits={2}
                          format={this.props.intl.currency}
                          currency={
                            invoice.currency
                              ? invoice.currency
                              : this.props.intl.currency
                          }
                          value={this.getAmount(invoice)}
                        />{' '}
                        {invoice.metadata.hasVAT === 'true' && (
                          <span>+VAT</span>
                        )}
                      </span>
                      <br />
                      {invoice.invoice_pdf &&
                        this.invoiceLink(invoice.invoice_pdf)}
                    </small>
                  </div>
                  <div className="details align-right">
                    <h5 className="title">{this.getDate(invoice)}</h5>
                  </div>
                </Thing>
              ))}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Billing);
