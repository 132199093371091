import { connect } from 'react-redux';
import Participants from './Participants';
import {
  onResetParticipantData,
  viewParticipantDialog,
  participantItemChange,
} from './action';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.onlineCourse.participant,
  appname: ownProps.match.params.appname,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  viewParticipantDialog: isOpen => dispatch(viewParticipantDialog(isOpen)),
  onResetParticipantData: () => dispatch(onResetParticipantData()),
  participantItemChange: item => dispatch(participantItemChange(item)),
});

export default connect(mapStateToProps, mapDisptachToProps)(Participants);
