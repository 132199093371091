import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import OnlineCourse from './OnlineCourse';
import { designChange, fieldChange } from './action';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    design: ownProps.component.design,
    appname: ownProps.match.params.appname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onDesignChange: (field, value) => {
    return dispatch(
      designChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        field,
        value,
      ),
    );
  },
  onButtonDesignChange: (type, value) => {
    return dispatch(
      designChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        `buttonDesign/${type}`,
        value,
      ),
    );
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnlineCourse),
);
