import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

import { Tabs, Tab } from '../../../../../components/tabs';
import TextField from '../../../../../../../app/components/textField';
import TextEditor from '../../../../../../../app/components/textEditor';
import DatePicker from '../../../../../components/datePicker';
import Toggle from '../../../../../../../app/components/toggle';

import SectionHeader from '../../../../../components/sectionHeader';
import ButtonDesignMenu from '../../../../../components/buttonDesignMenu';
import ButtonLinkMenu from '../../../../../components/buttonLinkMenu';
import ColorPicker from '../../../../../components/colorPicker';
import DesignToggle from '../../../../../components/designToggle';
import Container from '../../../../../../../app/components/container';

import LayoutTab from '../../tabs/layoutTab';
import LayoutOption from '../../tabs/layoutTab/layoutOption';
import countdownStandard from './countdownStandard';
import countdownFullScreen from './countdownFullScreen';

import ImageGradientMenu from '../../../../../components/imageGradientMenu';

import './countdown.css';

class Countdown extends Component {
  static propTypes = {
    appname: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    design: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
    onButtonDesignChange: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    await this.props.onLoadGoogleAnalytics();
  }

  getDimensions = index =>
    this.dimensions.medium && this.dimensions.medium[index];

  handleChange = prop => value => this.props.onChange(prop, value);
  handleDesign = prop => (e, value) => this.props.onDesignChange(prop, value);
  handleChangeLinkSection = (prop, value) => this.props.onChange(prop, value);
  handleToggle = prop => (e, isToggled) => this.props.onChange(prop, isToggled);

  handleDateChange = prop => value => {
    const date = new Date(value);
    const timestamp = date.getTime();
    this.props.onChange(prop, timestamp);
  };

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onDesignChange(prop, rgba);
  };

  countdownLayoutsOptions = () => {
    const { t } = this.props;
    const style = {
      width: '100%',
      heigth: '100%',
    };
    return {
      options: [
        <LayoutOption
          key="countdown_layout"
          wrapperKey="countdown_layout"
          grid={2}
          tooltip={t('Change your countdown component template')}
          title={t('Countdown templates')}
          active={this.props.component.layout}
          items={[
            { value: 'Standard', icon: countdownStandard, style },
            { value: 'FullScreen', icon: countdownFullScreen, style },
          ]}
        />,
      ],
    };
  };

  render() {
    const {
      appname,
      data,
      component,
      design,
      pages,
      onDesignChange,
      onButtonDesignChange,
      t,
    } = this.props;

    const toolbarButtons = {
      fonts: true,
      fontSize: true,
      bold: true,
      italic: true,
      underline: true,
      url: true,
      image: true,
      video: true,
      orderedList: true,
      unorderedList: true,
      negativeIntent: true,
      positiveIndent: true,
      textAlign: true,
      textColor: true,
      textBackground: true,
      clearTextFormatting: true,
      codeBlock: true,
      blockquote: true,
    };

    const sectionToggle = section => (
      <Toggle
        onToggle={this.handleDesign(`/${section}/enabled`)}
        toggled={component.design[section].enabled}
        disabled={!component.design.enabled}
      />
    );

    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <SectionHeader
              title={t('End Date and Time')}
              tooltip={t('End date and time of your event')}
            />
            <DatePicker
              ref={node => {
                this.datePicker = node;
              }}
              onChange={this.handleDateChange('date')}
              ts={data.date}
              show
              onlyDate
            />
            <Toggle
              label={t('Show or hide Countdown days')}
              toggled={data.displayDays}
              onToggle={this.handleToggle('displayDays')}
              className="full-width toggle-days-sec"
            />
            <Toggle
              label={t('Show or hide Countdown seconds')}
              toggled={data.displaySeconds}
              onToggle={this.handleToggle('displaySeconds')}
              className="full-width toggle-days-sec"
            />
            <SectionHeader
              title={t('Countdown')}
              tooltip={t('A caption to be displayed on your countdown')}
              borderTop
            />

            <TextEditor
              id="header"
              value={data.header}
              onChange={this.handleChange('header')}
              placeholder={t('Header')}
              toolbar={toolbarButtons}
            />

            <TextField
              id="subheader"
              value={data.subheader}
              onChange={this.handleChange('subheader')}
              placeholder={t('Subheader')}
            />
            <SectionHeader
              title={t('Card')}
              tooltip={t(
                'Define the content for the card displayed when the Countdown has reached 0',
              )}
              borderTop
            />
            <Toggle
              label={t('Preview Card')}
              toggled={data.previewCard}
              onToggle={this.handleToggle('previewCard')}
              className="full-width toggle-days-sec"
            />
            <TextField
              id="title"
              value={data.card.title}
              onChange={this.handleChange('/card/title')}
              placeholder={t('Title')}
            />
            <TextField
              id="caption"
              value={data.card.caption}
              onChange={this.handleChange('/card/caption')}
              placeholder={t('Caption')}
            />
            <ButtonLinkMenu
              link={data.link}
              pages={pages}
              buttonOptional
              borderTop
              onChange={this.handleChangeLinkSection}
              lazyData={component.lazy_data}
              googleAnalytics={this.props.googleAnalytics}
              gaEnabled={this.props.data.gaEnabled}
              gaCategory={this.props.data.gaCategory}
              gaAction={this.props.data.gaAction}
            />
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={onDesignChange}
            />
            <ColorPicker
              label={t('Color')}
              color={component.design.color}
              height={22}
              disabled={!component.design.enabled}
              onChange={this.handleColorChange('color')}
            />
            <ColorPicker
              label={t('Background Color')}
              color={design.backgroundColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={this.handleColorChange('backgroundColor')}
            />
            <ImageGradientMenu
              component={component}
              onDesignChange={onDesignChange}
            />
            <SectionHeader
              title={t('Timer')}
              actions={sectionToggle('timerDesign')}
              tooltip={t('Define the styles for countdown')}
              borderTop
            />
            <ColorPicker
              label={t('Countdown Color')}
              color={design.timerDesign.color}
              height={22}
              disabled={!component.design.timerDesign.enabled}
              onChange={this.handleColorChange('/timerDesign/color')}
            />
            <ColorPicker
              label={t('Countdown Background Color')}
              color={design.timerDesign.backgroundColor}
              height={22}
              disabled={!component.design.timerDesign.enabled}
              onChange={this.handleColorChange('/timerDesign/backgroundColor')}
            />
            <ColorPicker
              label={t('Countdown Label Color')}
              color={design.timerDesign.labelColor}
              height={22}
              disabled={!component.design.timerDesign.enabled}
              onChange={this.handleColorChange('/timerDesign/labelColor')}
            />
            <SectionHeader
              title={t('Card')}
              actions={sectionToggle('cardDesign')}
              tooltip={t(
                'Define the styles for the card displayed when the Countdown has reached 0',
              )}
              borderTop
            />
            <ColorPicker
              label={t('Card Text Color')}
              color={design.cardDesign.color}
              height={22}
              disabled={!component.design.cardDesign.enabled}
              onChange={this.handleColorChange('/cardDesign/color')}
            />
            <ColorPicker
              label={t('Card Background Color')}
              color={design.cardDesign.backgroundColor}
              height={22}
              disabled={!component.design.cardDesign.enabled}
              onChange={this.handleColorChange('/cardDesign/backgroundColor')}
            />
            <SectionHeader
              title={t('Button')}
              actions={sectionToggle('buttonDesign')}
              tooltip={t('Define the styles for your button')}
              borderTop
            />
            <ButtonDesignMenu
              component={component}
              onDesignChange={onButtonDesignChange}
            />
          </Container>
        </Tab>
        <Tab label={t('layout')}>
          <LayoutTab
            componentKey={component.key}
            appname={appname}
            {...this.countdownLayoutsOptions()}
          />
        </Tab>
      </Tabs>
    );
  }
}

export default withTranslation()(Countdown);
