import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Material Imports
import AddIcon from 'material-ui/svg-icons/content/add';

import PageHeader from '../../app/components/pageHeader';
import { FloatingActionButton } from '../../app/components/buttons';

import PrivateRoute from '../../app/components/privateRoute';
import AccountsDropdown from '../editor/internals/accountsDropdown';
import RegionalPerformanceCentre from './modules/RegionalPerformanceCentre';
import PlMatchSupport from './modules/PlMatchSupport';
import HotelTherapy from './modules/HotelTherapy';
import ConfirmedBookings from './modules/ConfirmedBookings';
import Therapists from './modules/Therapists';
import { TherapistsProvider } from './modules/Therapists/context/TherapistsContext';
import { HotelTherapyProvider } from './modules/HotelTherapy/context/HotelTherapyContext';

const PGMOL = props => {
  const {
    match: {
      params: { appname },
    },
    togglePerformanceModal,
    toggleMatchSupportModal,
    pgmol,
    toggleBlogModal,
    toggleTherapistModal,
    toogleHotelTherapyModal,
    location,
  } = props;

  let pageTitle = 'Regional Performance Centres';

  if (location.pathname && location.pathname.includes('/pl-match-support')) {
    pageTitle = 'PL Match Day Support';
  } else if (location.pathname && location.pathname.includes('/blog')) {
    pageTitle = 'Blog';
  } else if (location.pathname && location.pathname.includes('/therapist')) {
    pageTitle = 'Therapists';
  } else if (
    location.pathname &&
    location.pathname.includes('/hotel-therapy')
  ) {
    pageTitle = 'Hotel Therapy';
  } else if (
    location.pathname &&
    location.pathname.includes('/confirmed-bookings')
  ) {
    pageTitle = 'Confirmed Bookings';
  }

  return (
    <HotelTherapyProvider>
      <TherapistsProvider>
        <div className="flex-container sidebar-extended background-texture">
          <PageHeader
            title={pageTitle}
            userSettings={<AccountsDropdown />}
            actions={
              <div style={{ paddingRight: '10px' }}>
                <Route
                  path="/:appname/pgmol/regional-performance-centre"
                  render={() => (
                    <div className="row middle-xs">
                      <div className="col">
                        <FloatingActionButton
                          icon={<AddIcon />}
                          onClick={() =>
                            togglePerformanceModal(
                              !pgmol.regionalPerformanceCentre.modalOpen,
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path="/:appname/pgmol/pl-match-support"
                  render={() => (
                    <div className="row middle-xs">
                      <div className="col">
                        <FloatingActionButton
                          icon={<AddIcon />}
                          onClick={() =>
                            toggleMatchSupportModal(
                              !pgmol.plMatchSupport.modalOpen,
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                />

                <Route
                  path="/:appname/pgmol/therapist"
                  render={() => (
                    <div className="row middle-xs">
                      <div className="col">
                        <FloatingActionButton
                          icon={<AddIcon />}
                          onClick={() =>
                            toggleTherapistModal(!pgmol.plTherapists.modalOpen)
                          }
                        />
                      </div>
                    </div>
                  )}
                />

                <Route
                  path="/:appname/pgmol/hotel-therapy"
                  render={() => (
                    <div className="row middle-xs">
                      <div className="col">
                        <FloatingActionButton
                          icon={<AddIcon />}
                          onClick={() =>
                            toogleHotelTherapyModal(
                              !pgmol.plHotelTherapies.modalOpen,
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                />

                {!pgmol.plTherapists.enableTherapistBooking && (
                  <Redirect
                    exact
                    from={`/${props.match.params.appname}/pgmol/therapist`}
                    to={`/${props.match.params.appname}/pgmol/regional-performance-centre`}
                  />
                )}

                {/* <Route
              path="/:appname/pgmol/blog"
              render={() => (
                <div className="row middle-xs">
                  <div className="col">
                    <FloatingActionButton
                      icon={<AddIcon />}
                      onClick={() => toggleBlogModal(!pgmol.blog.modalOpen)}
                    />
                  </div>
                </div>
              )}
            /> */}
              </div>
            }
          />
          <Switch>
            <Redirect
              exact
              from={`/${appname}/pgmol`}
              to={`/${appname}/pgmol/regional-performance-centre`}
            />
            <PrivateRoute
              path="/:appname/pgmol/regional-performance-centre"
              component={RegionalPerformanceCentre}
              requiresUpgrade={false}
            />
            <PrivateRoute
              path="/:appname/pgmol/pl-match-support"
              component={PlMatchSupport}
            />
            <PrivateRoute
              path="/:appname/pgmol/therapist"
              component={Therapists}
            />
            <PrivateRoute
              path="/:appname/pgmol/hotel-therapy"
              component={HotelTherapy}
            />
            <PrivateRoute
              path="/:appname/pgmol/confirmed-bookings"
              component={ConfirmedBookings}
            />
          </Switch>
        </div>
      </TherapistsProvider>
    </HotelTherapyProvider>
  );
};

export default PGMOL;
