import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import ResultsTable from 'app/components/resultsTable';
import Header from 'app/components/header';
import ActionMenu from 'app/components/actionMenu';
import MenuItem from 'material-ui/MenuItem';
import { Autocomplete, TextField } from '@material-ui/core';

import {
  formatDate,
  capitaliseString,
  downloadCSV,
  objectToArray,
  arrayToObject,
} from 'utils';
import SelectField from '../../../../app/components/selectField';
import { withTranslation, Trans } from 'react-i18next';
import FormDetails from './formDetails';
import TableCheckbox from '../../components/tableCheckbox';

import './styles.css';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

class Form extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    items: PropTypes.array.isRequired,
    chunks: PropTypes.object,
    error: PropTypes.string,
    filter: PropTypes.string,
    getFormData: PropTypes.func.isRequired,
    watchFormData: PropTypes.func.isRequired,
    removeFormDataWatcher: PropTypes.func.isRequired,
    getAllForms: PropTypes.func.isRequired,
    onFilterFormData: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectAllResponses: PropTypes.func.isRequired,
    onDeselectAllResponses: PropTypes.func.isRequired,
    onOpenFormDetail: PropTypes.func.isRequired,
    selectAll: PropTypes.bool,
    forms: PropTypes.array.isRequired,
    onFavouriteSelected: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    onSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    colSorted: PropTypes.string.isRequired,
    appname: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    subscription: PropTypes.object.isRequired,
    formNames: PropTypes.array,
    selectedForm: PropTypes.string,
    changeSelectedForm: PropTypes.func.isRequired,
    allForms: PropTypes.object.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: '',
    filteredChunks: {},
    chunks: {},
    selectAll: false,
    formNames: [],
    selectedForm: '',
  };

  componentDidMount() {
    this.props.getFormData();
    //this.props.watchFormData();
    // this.props.getAllForms();
  }

  componentWillUnmount() {
    // this.props.removeFormDataWatcher();
  }

  getCategoryClass = value => {
    switch (value) {
      case 'All':
      default:
        return 'all';
    }
  };

  getSelectAllCheckbox = () => {
    const {
      onSelectAllResponses,
      onDeselectAllResponses,
      error,
      pending,
      selectAll,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) => {
            isChecked ? onSelectAllResponses() : onDeselectAllResponses();
          }}
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        {this.getActionMenu()}
      </div>
    );
  };

  getCsvData = filteredChunks => {
    if (!filteredChunks || Object.keys(filteredChunks).length === 0) {
      return '';
    }

    const formatChunks = objectToArray(filteredChunks);
    if (!formatChunks[0] || !formatChunks[0].value) {
      return '';
    }

    // Collect all unique fields across all submissions
    const allFields = new Map(); // Use Map to maintain order and uniqueness
    formatChunks.forEach(submission => {
      const { chunks } = submission.value;
      if (!chunks) return;

      Object.keys(chunks).forEach(key => {
        const field = chunks[key];
        if (!allFields.has(key)) {
          allFields.set(key, {
            key,
            label: field.label || field.question,
            order: field.order,
          });
        }
      });
    });

    // Convert to array and sort by order
    const columns = Array.from(allFields.values())
      .sort((a, b) => a.order - b.order)
      .map(field => ({
        label: field.label,
        key: field.key,
      }));

    // Build header row
    const headerRow = ['Date', 'Title', ...columns.map(col => col.label)];
    const csvContent = [`"${headerRow.join('","')}"`];

    // Build data rows
    Object.values(filteredChunks).forEach(item => {
      const { date, title, chunks: itemChunks } = item;
      const row = [
        formatDate(date),
        title,
        ...columns.map(col => {
          const chunk = itemChunks[col.key];
          return chunk ? chunk.value || '' : '';
        }),
      ];
      csvContent.push(`"${row.join('","')}"`);
    });

    return csvContent.join('\n');
  };

  getFormColumns = (unique, maxFields = 3) => {
    const { filteredChunks, items } = this.props;
    const formCols = {};

    Object.values(filteredChunks).forEach((chunk, index) => {
      const responseKey = items[index];
      formCols[responseKey] = [];
      chunk.order.forEach((key, i) => {
        const field = chunk.chunks[key];
        formCols[responseKey].push(
          unique ? `field-${i}` : field.label.toLowerCase(),
        );
      });
    });
    return formCols;
  };

  getTableColumns = () =>
    // if (
    //   Object.values(this.props.filteredChunks).length &&
    //   this.props.items.length
    // ) {
    //   let formCols = this.getFormColumns(false);

    //   const noUnique = this.noUniqueColumns(formCols);
    //   if (!noUnique) {
    //     formCols = this.getFormColumns(true);
    //   }

    //   return this.generateTableColumns(formCols, noUnique);
    // }
    [];

  getTableData = () => {
    if (
      !Object.values(this.props.filteredChunks).length ||
      !this.props.items.length
    ) {
      return {};
    }

    const result = objectToArray(this.props.filteredChunks).map(item => {
      const fieldItems = { ...item };
      // Create a map for faster lookups
      const chunksMap = Object.values(item.value.chunks).reduce(
        (acc, field) => {
          const label = field.label || '';
          const question = field.question || '';
          const key = label.toLowerCase() || question.toLowerCase();
          if (key) {
            acc[key] = field.value;
          }
          return acc;
        },
        {},
      );

      fieldItems.value = {
        ...fieldItems.value,
        ...chunksMap,
      };

      return fieldItems;
    });

    return arrayToObject(result);
  };

  getActionMenu = () => {
    const { selected, filteredChunks, onDeleteSelected, t } = this.props;
    return (
      <ActionMenu
        selected={selected}
        onDownloadSelected={() =>
          downloadCSV(
            this.getCsvData(filteredChunks),
            t(`${this.props.selectedForm.label}`),
          )
        }
        onDeleteSelected={() => onDeleteSelected(selected)}
        text={t('form responses')}
      />
    );
  };

  getGenericFieldNames = (formCols, noUniqueColumns, maxFieldCols) =>
    React.useMemo(() => {
      if (noUniqueColumns) return [];

      const maxCols = Object.values(formCols).reduce(
        (max, field) => Math.max(max, field.length),
        0,
      );

      return Array.from(
        { length: Math.min(maxCols, maxFieldCols) },
        (_, i) => ({
          id: `field-${i}`,
          header: `Field ${i + 1}`,
          classNames: 'col-xs-2',
        }),
      );
    }, [formCols, noUniqueColumns, maxFieldCols]);

  getFieldNames = (formCols, noUniqueColumns, maxFieldCols) =>
    React.useMemo(() => {
      const columns = [];
      if (!noUniqueColumns) return columns;

      const nonUniqueColumns = this.noUniqueColumns(formCols);
      return nonUniqueColumns.slice(0, maxFieldCols).map(field => ({
        id: `${field}`,
        header: `${capitaliseString(field)}`,
        classNames: `col-xs-${nonUniqueColumns.length > 2 ? '2' : '3'}`,
      }));
    }, [formCols, noUniqueColumns, maxFieldCols]);

  generateTableData = noUniqueColumns => {
    const { filteredChunks, items, forms, t } = this.props;

    return React.useMemo(() => {
      const tableData = {};
      items.forEach(index => {
        const chunk = filteredChunks[index];
        if (!chunk) return;

        tableData[index] = {
          ...chunk,
          id: (forms.indexOf(chunk.id) + 1).toString(),
        };

        // Process all fields in one pass
        const processedFields = chunk.order.reduce((acc, key, i) => {
          const field = chunk.chunks[key];
          const fieldKey = !noUniqueColumns
            ? `field-${i}`
            : field.label.toLowerCase();
          acc[fieldKey] =
            typeof field.value !== 'boolean'
              ? field.value
              : field.value
              ? t('Selected')
              : t('Not Selected');
          return acc;
        }, {});

        tableData[index] = {
          ...tableData[index],
          ...processedFields,
        };
      });
      return tableData;
    }, [filteredChunks, items, forms, noUniqueColumns, t]);
  };

  subHeaderContent = () => (
    <span>
      <Trans>Total form responses</Trans>:{' '}
      <b>{Object.keys(this.props.chunks).length}</b>
    </span>
  );

  generateTableColumns = () => {
    const { items, chunks, t } = this.props;

    let columns = [
      {
        id: 'date',
        header: <Trans>Date Created</Trans>,
        classNames: 'col-xs-2 created-at fix-left',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value)}</span>
        ),
      },
      {
        id: 'title',
        header: t('Form Name'),
        classNames: 'col-xs-3 fix-left',
      },
      // {
      //   id: 'name',
      //   header: 'Name',
      //   classNames: 'col-xs-2 created-at',
      //   containerElement: ({ value }) => <span>{value}</span>,
      // },
      // {
      //   id: 'email',
      //   header: 'Email',
      //   classNames: 'col-xs-2 created-at',
      //   containerElement: ({ value }) => <span>{value}</span>,
      // },
      // {
      //   id: 'message',
      //   header: 'Message',
      //   classNames: 'col-xs-2',
      //   containerElement: ({ value }) => <span>{value}</span>,
      // },
      // ...this.getFieldNames(formCols, noUniqueColumns, maxFieldCols),
      // ...this.getGenericFieldNames(formCols, noUniqueColumns, maxFieldCols),
    ];

    if (Object.keys(chunks).length > 0) {
      const columnNames = objectToArray(chunks)[0].value.chunks;
      const formatColumNames = Object.values(columnNames).slice(0, 3);

      formatColumNames.map(item => {
        const column = {
          id: item.label.toLowerCase() || item.question.toLowerCase(),
          header: item.label || item.question,
          classNames:
            formatColumNames.length < 3
              ? 'col-xs-3 fix-left'
              : 'col-xs-2 fix-left',
        };

        columns = [...columns, column];
      });
      const filter = {
        id: 'selected',
        header: this.getSelectAllCheckbox(),
        classNames: `${formatColumNames.length < 3 ? 'col-xs-1' : 'col-xs-1'} ${
          items.length < 4 ? 'fix-left' : 'selected'
        }`,
        containerElement: ({ value, rowId }) => (
          <>
            <TableCheckbox
              value={value}
              onSelect={() => this.props.onSelect(rowId)}
            />
            <ActionMenu
              selected={[rowId]}
              onDownloadSelected={() =>
                downloadCSV(
                  this.getCsvData(this.props.filteredChunks),
                  t(`${this.props.selectedForm.label}`),
                )
              }
              onDeleteSelected={() => this.props.onDeleteSelected([rowId])}
              onEditSelected={() => this.props.onOpenFormDetail(rowId)}
              text={t('form response')}
            />
          </>
        ),
      };

      columns = [...columns, filter];
    }

    return columns;
  };

  noUniqueColumns = formCols =>
    Object.values(formCols).reduce((acc, current) =>
      JSON.stringify(current) !== JSON.stringify(acc) ? false : current,
    );

  rightSection = () => {
    const {
      formNames,
      selectedForm,
      changeSelectedForm,
      allForms,
      t,
    } = this.props;

    if (formNames.length > 0) {
      return (
        // <SelectField
        //   style={{ width: '100%' }}
        //   key="formNames-filter"
        //   options={formNames.map(option => (
        //     <MenuItem
        //       key={option.id}
        //       value={option.id}
        //       primaryText={option.name}
        //     />
        //   ))}
        //   label={t('Select a Form')}
        //   value={selectedForm}
        //   onChange={(i, payload) => {
        //     changeSelectedForm(allForms, payload);
        //     // selectMemberBody(payload);
        //     // getEvents();
        //     // onFilterEventData('', this.getColumns());
        //   }}
        // />

        <Autocomplete
          options={formNames}
          getOptionLabel={option => option.label}
          value={selectedForm}
          onChange={(event, newValue) => {
            changeSelectedForm(allForms, newValue);
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Select an option"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
              }}
            />
          )}
        />
      );
    }
    return null;
  };

  render() {
    const {
      items,
      filteredChunks,
      onOpenFormDetail,
      onFilterFormData,
      onSort,
      order,
      colSorted,
      filter,
      selectAll,
      onSelectAllResponses,
      onDeselectAllResponses,
      t,
    } = this.props;

    const formInstructions = (
      <p className="instructions-container">
        <p className="instructions-section-1">
          <span>
            <Trans>To use this feature add a Form component to your app.</Trans>
          </span>
          <br />
          <span>
            <Trans>
              To add Form component go to the Components menu and click on Add
              Form component.
            </Trans>
          </span>
          <br />
        </p>
        <p className="instructions-section-2">
          <span>
            <Trans>
              When your app users submit a Form entry, the Form Data will be
              displayed here.
            </Trans>
          </span>
        </p>
          
      </p>
    );

    return (
      <section className="form-table-container">
        <Header
          title={this.subHeaderContent()}
          actionMenu={this.getActionMenu()}
          onChange={onFilterFormData}
          filter={filter}
          selectAll={selectAll}
          onSelectAllResponses={onSelectAllResponses}
          onDeselectAllResponses={onDeselectAllResponses}
          dataFilters={this.rightSection()}
        />

        <ResultsTable
          {...this.props}
          columns={this.generateTableColumns()}
          chunks={this.getTableData()}
          emptyMessage={t('Looks like you have no form responses yet')}
          optionalMessage={formInstructions}
          onRowTouchTap={onOpenFormDetail}
          // sortTable={cols => onSort(cols)}
          // order={order}
          // colSorted={colSorted}
          // colsToSort={['date', 'email', 'name']}
        />
        {items && filteredChunks && <FormDetails />}
      </section>
    );
  }
}

export default withTranslation()(Form);
