import React, { useState } from 'react';

export const AppointmentContext = React.createContext({});

export const AppointmentProvider = ({ children }) => {
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [name, setName] = useState('');
  const [meetingUrl, setMeetingUrl] = useState('');
  const [description, setDescription] = useState('');
  const [cost, setCost] = useState(0);
  const [capacity, setCapacity] = useState(1);
  const [date, setDate] = useState('');
  const [key, setKey] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isBookingPaid, setIsBookingPaid] = useState(false);
  const [appointmentType, setAppointmentType] = useState(0);
  const [bookingType, setBookingType] = useState('single');
  const [timeslots, setTimesloTs] = useState([
    { time: new Date(), capacityBooked: 0 },
  ]);
  const [appointmentDuration, setAppointmentDuration] = useState(30);
  const [timezone, setTimezone] = useState({
    value: '',
    label: '',
  });
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedStaffMember, setSelectedStaffMember] = useState([]);
  const [location, setLocation] = useState({ value: '', locationData: {} });
  const [eventData, setEventData] = useState();
  const [stripeKeyError, setStripeKeyError] = useState(false);
  const [isRecurringBooking, setIsRecurringBooking] = useState(false);
  const [recurringType, setRecurringType] = useState('days');
  const [recurringValue, setRecurringValue] = useState(2);
  const [appointmentError, setAppointmentError] = useState(null);
  const [slotsError, setSlotsError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const addTimeSlot = () => {
    setTimesloTs(prev => {
      if (prev.length >= 4) return prev;
      return [...prev, { time: new Date(), capacityBooked: 0 }];
    });
  };

  const removeTimeSlot = index => {
    setTimesloTs(prev => prev.filter((_, i) => i !== index));
  };

  const updateTimeSlot = (index, newTime) => {
    setTimesloTs(prev =>
      prev.map((slot, i) => (i === index ? { ...slot, time: newTime } : slot)),
    );
  };

  return (
    <AppointmentContext.Provider
      value={{
        name,
        setName,
        description,
        setDescription,
        cost,
        setCost,
        capacity,
        setCapacity,
        date,
        setDate,
        key,
        setKey,
        isButtonDisabled,
        setIsButtonDisabled,
        isBookingPaid,
        setIsBookingPaid,
        appointmentType,
        setAppointmentType,
        appointmentDuration,
        setAppointmentDuration,
        timezone,
        setTimezone,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        selectedStaffMember,
        setSelectedStaffMember,
        location,
        setLocation,
        eventData,
        setEventData,
        stripeKeyError,
        setStripeKeyError,
        isRecurringBooking,
        setIsRecurringBooking,
        recurringType,
        setRecurringType,
        recurringValue,
        setRecurringValue,
        appointmentError,
        setAppointmentError,
        slotsError,
        setSlotsError,
        imageUrl,
        setImageUrl,
        submitted,
        setSubmitted,
        meetingUrl,
        setMeetingUrl,
        timeslots,
        setTimesloTs,
        addTimeSlot,
        removeTimeSlot,
        updateTimeSlot,
        bookingType,
        setBookingType,
        isDuplicate,
        setIsDuplicate,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};
