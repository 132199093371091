import styled from 'styled-components';

export const DialogHeader = styled.div`
  width: 100%;
  display: grid;
  padding: 23px 10px 13px 0px;
  column-gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  & h6 {
    text-align: center;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin: 0;
  }

  div:last-child {
    text-align: right;
    padding-right: 13px;

    svg {
      cursor: pointer;
      color: #291fd6;
    }
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  margin-top: 14px;
  p {
    color: #ff0000;
    font-size: 14px;
    margin: 0px;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 30px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;

  h6 {
    color: #000000;
  }
`;
