import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateMarketplaceSettings } from './actions';
import Settings from './Settings';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    ...state.ecommerce.marketplace.settings,
    profile: { ...state.account.profile },
    pages: state.editor.structure.routes || [],
    isAppAdmin:
      (state.account.auth.profile.admin_appname &&
        state.account.auth.profile.admin_appname.length > 0) ||
      false,
    isSubAdminWithMulipleApps:
      (state.account.auth.profile.admin_appnames &&
        Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
      false,
    appname: state.app.appContext.appname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMarketplaceSettings: (name, data) =>
    dispatch(
      updateMarketplaceSettings(ownProps.match.params.appname, name, data),
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings),
);
