import styled from 'styled-components';

const HiddenFileVideoInput = styled.input.attrs({
  type: 'file',
  accept: 'video/mp4',
})`
  display: none;
`;

export default HiddenFileVideoInput;
