import React from 'react';
import { Toggle, Divider, MenuItem, Checkbox } from 'material-ui';
import PropTypes from 'prop-types';
import themeStyles from '../../../../../_export.scss';
import { ListItemText, Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { Autocomplete } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import EditableTextField from '../../../components/editableField/InlineEditTextField';
import DialogConfirmation from '../../../../../app/components/dialogConfirmation';

const SettingsWrapper = styled.div`
  div.MuiAutocomplete-endAdornment {
    display: flex;
  }
`;

const Settings = props => {
  const {
    getUsers,
    updateSelectedUsers,
    chunks,
    enabled,
    onTogglePublicAccess,
    publicAccessUsers,
    onToggleUserPrivateAccess,
    userPrivateAccess,
  } = props;
  const { t } = useTranslation();

  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    getUsers();
  }, []);

  React.useEffect(() => {
    setUsers(publicAccessUsers || []);
  }, [publicAccessUsers]);

  const handleChange = (event, value, reason) => {
    const usersArr = value.map(({ key }) => key);
    setUsers(usersArr);
    updateSelectedUsers(usersArr);
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const allUsers =
    chunks &&
    Object.keys(chunks).map(key => ({
      key,
      firstName: chunks[key].firstName,
      lastName: chunks[key].lastName,
      email: chunks[key].email,
    }));
  const selectedUsers =
    chunks &&
    Object.keys(chunks)
      .filter(key => users.indexOf(key) >= 0)
      .map(key => ({
        key,
        firstName: chunks[key].firstName,
        lastName: chunks[key].lastName,
        email: chunks[key].email,
      }));
  const togglePublicAccess = value => {
    onTogglePublicAccess(value);
    if (value) {
      handleSelectOnOpen();
    }
  };
  const handleSelectOnOpen = () => {
    if (users && users.length == 0) {
      const usersArr = allUsers && allUsers.map(({ key }) => key);
      setUsers(usersArr || []);
      updateSelectedUsers(usersArr || []);
    }
  };
  const [hideFromAdminPanelOpen, setHideFromAdminPanelOpen] = React.useState(
    false,
  );
  return (
    <SettingsWrapper>
      <div className="auth-panel">
        <div className="auth-panel-title">
          <Trans>Enable Public access to Diary Entries</Trans>
        </div>
        <div className="auth-panel">
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => togglePublicAccess(b)}
            toggled={enabled}
            label={t('Enable')}
            trackSwitchedStyle={{
              backgroundColor: themeStyles.primaryColorLight,
            }}
            thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
          />
          <p>
            <Trans>
              Allow users to publicly display their diary notes in the app, thus
              enabling users to view each other's diary notes.
            </Trans>
          </p>
          <br />
          <InputLabel id="demo-mutiple-checkbox-label">
            <Trans>Select Users</Trans>
          </InputLabel>

          <p>
            <Trans>
              Uncheck users that are not allowed to make their entries Public
            </Trans>
          </p>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            onOpen={handleSelectOnOpen}
            disableCloseOnSelect
            options={[...allUsers]}
            getOptionLabel={({ key }) =>
              `${chunks[key].firstName} ${chunks[key].lastName}`
            }
            isOptionEqualToValue={(option, value) => option.key === value.key}
            renderOption={(props, { key }, optionState) => {
              return (
                <li {...props} key={key}>
                  <Grid item md={1}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={users.indexOf(key) >= 0}
                    />
                  </Grid>
                  <Grid item md={11}>
                    <ListItemText
                      primary={`${chunks[key].firstName} ${chunks[key].lastName} | ${chunks[key].email}`}
                    />
                  </Grid>
                </li>
              );
            }}
            style={{ width: 700 }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={t('Search User')}
                variant="standard"
              />
            )}
            onChange={handleChange}
            value={[...selectedUsers]}
            disabled={!enabled}
            filterOptions={(options, state) => {
              const displayOptions = options.filter(
                option =>
                  option.firstName
                    .toLowerCase()
                    .trim()
                    .includes(state.inputValue.toLowerCase().trim()) ||
                  option.lastName
                    .toLowerCase()
                    .trim()
                    .includes(state.inputValue.toLowerCase().trim()) ||
                  option.email
                    .toLowerCase()
                    .trim()
                    .includes(state.inputValue.toLowerCase().trim()),
              );

              return displayOptions;
            }}
          />
        </div>
      </div>
      <div className="auth-panel">
        <div className="auth-panel-title">
          <Trans>
            Enable users to hide Diary Entries from the Admin based on their
            preference.
          </Trans>
        </div>
        <div className="auth-panel">
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, value) => {
              if (value) {
                onToggleUserPrivateAccess(value);
              } else {
                setHideFromAdminPanelOpen(true);
              }
            }}
            toggled={userPrivateAccess}
            label={t('Enable')}
            trackSwitchedStyle={{
              backgroundColor: themeStyles.primaryColorLight,
            }}
            thumbSwitchedStyle={{
              backgroundColor: themeStyles.primaryColor,
            }}
          />
          <p>
            <Trans>
              Allow users to hide diary entries from the admin based on their
              preference. By Default receives all diary entry data in the Diary
              section of the Admin dashboard. When user chooses to hide a diary
              entry, then entry will not be added to the list of entries in the
              Diary section.
            </Trans>
          </p>
        </div>
      </div>
      <div className="auth-panel">
        <div className="auth-panel-title">
          <Trans>Change page titles in Diary component</Trans>
        </div>
        <div className="auth-panel">
          <Grid container>
            <Grid item md={8}>
              <Trans>
                'My Diary' is located in the navigation menu at the top right
                corner of the app.
              </Trans>
            </Grid>
            <Grid item md={4}>
              <EditableTextField
                {...props}
                name="navigationMenu"
                maxLength={16}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Trans>
                'Diary' is the main page where a list of all entries is
                displayed.
              </Trans>
            </Grid>
            <Grid item md={4}>
              <EditableTextField {...props} name="pageTitle" maxLength={16} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Trans>
                'My Entries' is located at the bottom right navigation menu
                allowing users to
              </Trans>{' '}
              <br />
              <Trans>view a list of their own diary entries.</Trans>
            </Grid>
            <Grid item md={4}>
              <EditableTextField
                {...props}
                name="lowerNavigationMenu"
                maxLength={16}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <DialogConfirmation
        title="Notice"
        open={hideFromAdminPanelOpen}
        onClose={() => {
          setHideFromAdminPanelOpen(false);
        }}
        message={t(
          `Please note that if you disable the option for app users to hide diary entries from admin, the notes which they have added under this condition will no longer be visible in their list of diary entries in the app. Do you want to proceed?`,
        )}
        action={() => onToggleUserPrivateAccess(false)}
      />
    </SettingsWrapper>
  );
};

Settings.propTypes = {
  enabled: PropTypes.bool,
  togglePublicAccess: PropTypes.func.isRequired,
};

Settings.defaultProps = {
  enabled: false,
};

export default Settings;
