import {
  OPEN_PARTICIPANT_DIALOG,
  PARTICIPANT_ITEM_CHANGE,
  RESET_PARTICIPANT_DETAILS,
} from './types';

const initialState = {
  participantDialogOpen: false,
  participantItem: {},
};
const participants = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_PARTICIPANT_DIALOG:
      return {
        ...state,
        participantDialogOpen: action.payload,
      };
    case PARTICIPANT_ITEM_CHANGE:
      return {
        ...state,
        participantItem: action.payload,
      };
    case RESET_PARTICIPANT_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export default participants;
