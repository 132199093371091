import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
      borderBottom: 'none',
    },

    '& .options-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiCheckbox-root': {
      width: 24,
      height: 24,
    },
    '& .action-menu-container': {
      width: 24,
      height: 24,
    },
  },
});
