import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';
import Close from 'material-ui/svg-icons/navigation/close';
import { Trans, useTranslation } from 'react-i18next';
import SelectField from '../../../../../../../app/components/selectField';
import TextField from '../../../../../../../app/components/textField';
import CircularValidation from '../../../../../../../app/components/circularValidation';
import { CircularProgress } from '../../../../../../../app/components/progress';
import { FlatButton } from '../../../../../../../app/components/buttons';
import { MARKETPLACE_FILTER_OPTIONS } from '../../../../../../../config';

import './styles.css';
import { CUSTOM_MARKETPLACE_APPS } from '../../../../../../../plans/constants';

const NewFilter = props => {
  const {
    open,
    pending,
    error,
    onSubmit,
    onRequestClose,
    name,
    onFieldChange,
    description,
    filterType,
    match,
    selectedFilter,
  } = props;
  console.log('props-->', props);
  const isUpdate = Object.keys(selectedFilter).length > 0;

  const isCustomMarkeplaceApp = CUSTOM_MARKETPLACE_APPS.includes(
    match.params.appname,
  );

  const { t } = useTranslation();

  const displayError = error ? (
    <div>
      <strong>{error.message}</strong>
    </div>
  ) : null;

  const buttonSubmit = !pending ? (
    <FlatButton
      label={isUpdate ? t('Update') : t('Create')}
      onClick={onSubmit}
      disabled={!name.isValid}
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );

  const fieldOptions = MARKETPLACE_FILTER_OPTIONS.map(data => (
    <MenuItem key={data.id} value={data.id} primaryText={data.value} />
  ));

  useEffect(() => {
    if (isUpdate) {
      onFieldChange('description', selectedFilter.description);
      onFieldChange('name', selectedFilter.name);
    }
  }, [selectedFilter]);

  return (
    <Dialog open={open} onRequestClose={onRequestClose} autoScrollBodyContent>
      <div className="create-filter-form">
        <div className="modal-controls-container">
          <IconButton onClick={onRequestClose}>
            <Close />
          </IconButton>
        </div>
        <div className="list-fieldset">{displayError}</div>
        <div>
          <h5>
            {isUpdate ? (
              <>
                {isCustomMarkeplaceApp ? (
                  'Update Business Type'
                ) : (
                  <Trans>Update category</Trans>
                )}
              </>
            ) : (
              <>
                {isCustomMarkeplaceApp ? (
                  'Create a Business Type'
                ) : (
                  <Trans>Create a category</Trans>
                )}
              </>
            )}
          </h5>
          <TextField
            id="name"
            placeholder={isCustomMarkeplaceApp ? 'Type' : t('Name')}
            {...name}
            onChange={value => onFieldChange('name', value)}
            rightInputAction={
              <CircularValidation
                isValid={name.isValid}
                defaultMsg={t("Filter's name required")}
                errorMsg={t(`Name must be at least 2 characters long`)}
                showDefault={!name.value}
              />
            }
          />

          <TextField
            id="description"
            placeholder={t('Description')}
            {...description}
            onChange={value => onFieldChange('description', value)}
            rightInputAction={
              <CircularValidation
                isValid={description.isValid}
                defaultMsg={t('Description required')}
                errorMsg={t('Description must be at least 2 characters long')}
                showDefault={!description.value}
              />
            }
          />
          {/* <SelectField
        className="offset-top"
        options={fieldOptions}
        label="Select Filter Type"
        onChange={(i, payload) => onFieldChange('filterType',payload)}
        fullWidth
        value={filterType.value}
      /> */}
        </div>
        {buttonSubmit}
      </div>
    </Dialog>
  );
};

NewFilter.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  name: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  onFieldChange: PropTypes.func.isRequired,
  selectedFilter: PropTypes.object.isRequired,
};

NewFilter.defaultProps = {
  error: null,
};

export default NewFilter;
