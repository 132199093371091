const components = {
  Timer: {
    name: 'Workout Timer',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  TimerHistory: {
    name: 'Workout History',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Image: {
    name: 'Image',
    plan: 'start',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Text: {
    name: 'Text',
    plan: 'start',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Button: {
    name: 'Button',
    plan: 'start',
    price: 1,
    subscriptionPlan: 'addon',
  },
  TwitterFeed: {
    name: 'Twitter Feed',
    plan: 'start',
    price: 1,
    subscriptionPlan: 'addon',
  },

  Header: {
    name: 'Header',
    plan: 'start',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Slider: {
    name: 'Slider',
    plan: 'start',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Countdown: {
    name: 'Countdown',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
  },
  EventList: {
    name: 'Event List',
    plan: 'start',
    price: 1,
    subscriptionPlan: 'addon',
  },

  Twitter: {
    name: 'Twitter',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  VimeoVideo: {
    name: 'Vimeo Video',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  YouTubeVideo: {
    name: 'YouTube Video',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Livestream: {
    name: 'Livestream',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  UserChat: {
    name: 'UserChat',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  YouTube: {
    name: 'YouTube',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
  },
  FacebookPage: {
    name: 'Facebook Feed',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  InstagramFeed: {
    name: 'Instagram Feed',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Zoom: {
    name: 'Zoom',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Html: {
    name: 'Html',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Delegates: {
    name: 'Delegates',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Loyalty: {
    name: 'Loyalty',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Forum: {
    name: 'Forum',
    plan: 'something',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Food: {
    name: 'Food',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  FkAcademyGroups: {
    name: 'FkAcademyGroups',
    plan: 'diy',
  },
  Form: {
    name: 'Form',
    plan: 'something',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Roster: {
    name: 'Roster',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Schedule: {
    name: 'Schedule',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  File: { name: 'File', plan: 'diy', price: 1, subscriptionPlan: 'addon' },
  PDFViewer: {
    name: 'PDF Library',
    plan: 'deluxe',
    price: 4,
    subscriptionPlan: 'addon',
    usd: {
      price: 5,
    },
    eur: {
      price: 4.5,
    },
    aud: {
      price: 7,
    },
    nzd: {
      price: 8,
    },
    cad: {
      price: 6,
    },
    zar: {
      price: 90,
    },
  },
  Media: {
    name: 'Media',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Marketplace: {
    name: 'Marketplace',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Vote: { name: 'Vote', plan: 'diy', price: 1, subscriptionPlan: 'addon' },

  IFrame: {
    name: 'iFrame Widget',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
  },
  WordPress: {
    name: 'WordPress',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
  },
  Notification: {
    name: 'Notification',
    plan: 'deluxe',
    price: 8,
    subscriptionPlan: 'addon',
    usd: {
      price: 10,
    },
    eur: {
      price: 10,
    },
    aud: {
      price: 15,
    },
    nzd: {
      price: 15,
    },
    cad: {
      price: 12,
    },
    zar: {
      price: 140,
    },
  },
  Appointedd: {
    name: 'Appointedd',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
  },
  Shopify: {
    name: 'Shopify',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
  },
  Ecwid: {
    name: 'Ecwid',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
  },
  GoogleMaps: {
    name: 'Google Maps',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
  },
  Rating: {
    name: 'Rating',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
  },
  EventCourses: {
    name: 'Event/Course/CPD',
    plan: 'start',
  },
  EventCalendar: {
    name: 'Event Calendar',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  EventOrganizers: {
    name: 'Event Organizers',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Workforces: {
    name: 'Workforce Profiles',
    plan: 'start',
  },
  Memberbodies: {
    name: 'Member Bodies',
    plan: 'start',
  },
  PgmolRegionPerformanceCentre: {
    name: 'Regional Performance Centres',
    plan: 'start',
    price: 1,
    subscriptionPlan: 'addon',
  },
  PgmolPlMatchSupport: {
    name: 'PL Match Day Support',
    plan: 'start',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Title: {
    name: 'Title',
    plan: 'start',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Blog: {
    name: 'Blog',
    plan: 'start',
    price: 10,
    subscriptionPlan: 'addon',
    usd: {
      price: 12,
    },
    eur: {
      price: 12,
    },
    aud: {
      price: 17,
    },
    nzd: {
      price: 17,
    },
    cad: {
      price: 15,
    },
    zar: {
      price: 180,
    },
  },
  Woocommerce: {
    name: 'Woocommerce',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
  },
  RadioStreaming: {
    name: 'Radio Streaming',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
  },
  Popup: {
    name: 'Popup',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
  },
  PayNow: {
    name: 'PayNow',
    plan: 'deluxe',
    price: 10,
    subscriptionPlan: 'addon',
    usd: {
      price: 12,
    },
    eur: {
      price: 12,
    },
    aud: {
      price: 17,
    },
    nzd: {
      price: 17,
    },
    cad: {
      price: 15,
    },
    zar: {
      price: 180,
    },
  },
  CustomDomain: {
    name: 'CustomDomain',
    plan: 'deluxe',
    price: 8,
    subscriptionPlan: 'addon',
    usd: {
      price: 10,
    },
    eur: {
      price: 10,
    },
    aud: {
      price: 15,
    },
    nzd: {
      price: 15,
    },
    cad: {
      price: 12,
    },
    zar: {
      price: 140,
    },
  },
  CustomDomainPlatform: {
    name: 'CustomDomainPlatform',
    plan: 'deluxe',
    price: 8,
    subscriptionPlan: 'addon',
    usd: {
      price: 10,
    },
    eur: {
      price: 10,
    },
    aud: {
      price: 15,
    },
    nzd: {
      price: 15,
    },
    cad: {
      price: 12,
    },
    zar: {
      price: 140,
    },
  },
  Diary: {
    name: 'Diary',
    plan: 'deluxe',
    price: 8,
    subscriptionPlan: 'addon',
    addonPlanName: 'diary',
    usd: {
      price: 11,
    },
    eur: {
      price: 10,
    },
    aud: {
      price: 16,
    },
    nzd: {
      price: 17,
    },
    cad: {
      price: 14,
    },
    zar: {
      price: 193,
    },
  },
  Canva: {
    name: 'Canva Widget',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
  },
  Gallery: {
    name: 'Image Gallery',
    plan: 'deluxe',
    price: 1,
    usd: {
      price: 2,
    },
    eur: {
      price: 2,
    },
    aud: {
      price: 3,
    },
    nzd: {
      price: 3,
    },
    cad: {
      price: 2,
    },
    zar: {
      price: 20,
    },
    subscriptionPlan: 'addon',
  },
  Appointment: {
    name: 'Booking',
    plan: 'diy',
    price: 1,
    subscriptionPlan: 'addon',
  },

  Feedback: {
    name: 'Feedback',
    plan: 'deluxe',
    price: 8,
    subscriptionPlan: 'addon',
    usd: {
      price: 9.5,
    },
    eur: {
      price: 9,
    },
    aud: {
      price: 14,
    },
    nzd: {
      price: 15,
    },
    cad: {
      price: 13,
    },
    zar: {
      price: 180,
    },
  },
  SubscriptionPlans: {
    name: 'Subscription Plans',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
  },
  OnlineCourse: {
    name: 'Online Course',
    plan: 'deluxe',
    price: 1,
    subscriptionPlan: 'addon',
  },


  Html: {
    name: 'Custom HTML',
    plan: 'deluxe',
    price: 6,
    subscriptionPlan: 'addon',
    usd: {
      price: 7,
    },
    eur: {
      price: 7,
    },
    aud: {
      price: 11,
    },
    nzd: {
      price: 12,
    },
    cad: {
      price: 10,
    },
    zar: {
      price: 136,
    },
  },
  MemberLogin: {
    name: 'Member Login',
    plan: 'deluxe',
    price: 10,
    subscriptionPlan: 'addon',
    usd: {
      price: 12,
    },
    eur: {
      price: 12,
    },
    aud: {
      price: 17,
    },
    nzd: {
      price: 17,
    },
    cad: {
      price: 15,
    },
    zar: {
      price: 180,
    },
  },
};

export default components;
