import React, { useEffect, useContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  // InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Trans, useTranslation } from 'react-i18next';
import { newValidator } from 'utils';
import firebase from '../../../../../firebase';
import ImageUpload from '../../../../../modules/editor/internals/imageUpload';
import FlatButton from '../../../../../app/components/buttons/flat/Flat';
// import { addStaffMember, editStaffMember } from '../../api';
import './styles.css';
import { countries } from '../../../../distribute/utils';

import { TherapistsContext } from '../context/TherapistsContext';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  textField: {
    '& label.MuiInputLabel-root': {
      color: '#000000',
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 600,
    },
    '& label.Mui-focused': {
      color: '#000000',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#000000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000000',
    },
    '& .css-ghsjzk-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#000000',
    },
  },
  formControl: {
    '& label': {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 400,
      fontSize: 14,
      color: '#000000',
      left: -15,
    },
    '& .MuiSelect-icon': {
      color: '#000000',
    },
    '& .MuiSelect-standard': {
      color: '#333333',
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 500,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#000000',
    },
    '& .css-1480iag-MuiInputBase-root-MuiInput-root:after': {
      borderBottomColor: '#000000',
    },
    '& .MuiSelect-select:before': {
      borderBottomColor: '#000000',
    },
    '& .css-1480iag-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#000000',
    },
  },
}));

const TherapistsModal = props => {
  const {
    open,
    therapist,
    onStaffMemberChange,
    allTherapistsData,
    toggleTherapistModal,
    submitTherapist,
    appname,
    isImageUploaded,
  } = props;

  const { t } = useTranslation();
  const {
    name,
    setName,
    email,
    setEmail,
    mobile,
    setMobile,
    isButtonDisabled,
    setIsButtonDisabled,
    dialCode,
    setDialCode,
    resetTherapistData,
    imageUrl,
    setImageUrl,
  } = useContext(TherapistsContext);
  const [showHelperText, setShowHelperText] = useState(false);

  const isUpdate = Object.keys(therapist).length > 0;
  const classes = useStyles();
  const accountId = useSelector(state => state.account.organisation.key);

  const getImgUrl = () => `/pgmol/tempImages/therapist/${appname}/${accountId}`;

  useEffect(() => {
    const getImageUrl = async () => {
      const imageRef = firebase.database().ref(getImgUrl());
      const snapshot = await imageRef.once('value');
      const value = snapshot.val();
      if (value) setImageUrl(value);
    };

    isImageUploaded && getImageUrl();
  }, [isImageUploaded]);

  useEffect(() => {
    if (isUpdate) {
      setName(therapist.name);
      setEmail(therapist.email);
      setMobile(therapist.phone);
      setImageUrl(therapist.imageUrl);
      setDialCode(therapist.dialCode);
    }
  }, [therapist]);

  const handleSubmit = async e => {
    e.preventDefault();

    const payload = {
      name,
      email,
      imageUrl,
      phone: mobile,
      dialCode,
      key: isUpdate ? therapist.key : null,
    };

    setIsButtonDisabled(true);
    submitTherapist(payload);
    setIsButtonDisabled(false);
    resetTherapistData();
    await firebase
      .database()
      .ref(getImgUrl())
      .set(null);
  };

  const handleClose = async () => {
    await firebase
      .database()
      .ref(getImgUrl())
      .set(null);
    setImageUrl(null);
    setName('');
    setEmail('');
    setMobile('');
    setIsButtonDisabled(false);
    toggleTherapistModal();
    resetTherapistData();
  };

  const emailIsInvalid = () => {
    if (email && allTherapistsData) {
      if (
        Object.keys(allTherapistsData).findIndex(
          staffKey =>
            allTherapistsData[staffKey].email.toLowerCase() ===
              email.toLowerCase() && therapist.key !== staffKey,
        ) >= 0
      ) {
        return t('Email already exists');
      }

      const emailValidator = newValidator({
        type: 'email',
        required: true,
      });
      if (!emailValidator(email)) {
        return t('Invalid email');
      }
    }
    return '';
  };

  const mobileIsInvalid = () => {
    if (mobile && allTherapistsData) {
      if (
        Object.keys(allTherapistsData).findIndex(
          staffKey =>
            allTherapistsData[staffKey].phone.toLowerCase() ===
              mobile.toLowerCase() &&
            allTherapistsData[staffKey].dialCode &&
            allTherapistsData[staffKey].dialCode.toLowerCase() === dialCode &&
            therapist.key !== staffKey,
        ) >= 0
      ) {
        return t('Mobile number already exists');
      }
      const moblieValidator = newValidator({
        required: true,
      });
      if (!moblieValidator(mobile)) {
        return t('Invalid mobile number');
      }
    }
    return '';
  };

  const isTherapistInvalid =
    isButtonDisabled ||
    !name ||
    !email ||
    !mobile ||
    emailIsInvalid() ||
    mobileIsInvalid();

  const countryOptions = countries().map(country => (
    <MenuItem key={country.dialCode} value={country.dialCode}>
      {country.name} ({country.dialCode})
    </MenuItem>
  ));
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" disableEnforceFocus>
      <div className="staff-modal-content">
        <DialogTitle
          style={{
            color: '#261ed5',
            fontFamily: 'Raleway, sans-serif',
            fontWeight: 600,
            fontSize: 24,
            marginTop: 40,
          }}
        >
          {isUpdate ? 'Edit Therapist' : 'Add Therapist'}
        </DialogTitle>
        <div className="field-wrapper">
          {/* <div className="dialog-label">Image</div> */}
          <ImageUpload
            src={imageUrl}
            avatar={true}
            width="180px"
            height="180px"
            targetId="therapist_image_upload"
            label={t('Image')}
            caption={t('This image will be displayed for appointments')}
            firebaseUrl={getImgUrl()}
            title={'Therapist Image'}
            tooltip={t('Minimum image size 240x240 pixels')}
            showCropAreaSizes
            type="image"
            fileType="png"
          />
        </div>

        <div className="field-wrapper">
          <TextField
            className={classes.textField}
            type="text"
            label={t('Name')}
            value={name}
            required
            fullWidth
            variant="standard"
            onChange={e => setName(e.target.value)}
            inputProps={{
              style: { fontFamily: 'Raleway, sans-serif', fontWeight: 500 },
            }}
            InputLabelProps={{
              style: {
                fontWeight: 500,
                color: '#333333',
              },
            }}
          />
        </div>

        <div className="field-wrapper">
          <TextField
            className={classes.textField}
            type="email"
            label={t('Email')}
            value={email}
            required
            fullWidth
            variant="standard"
            onChange={e => setEmail(e.target.value)}
            onBlur={() => setShowHelperText(true)} // Show helper text on blur
            onFocus={() => setShowHelperText(false)} // Hide helper text on focus
            helperText={showHelperText && emailIsInvalid()}
            error={showHelperText && emailIsInvalid()}
            inputProps={{
              style: { fontFamily: 'Raleway, sans-serif' },
            }}
            InputLabelProps={{
              style: {
                fontWeight: 500,
                color: '#333333',
              },
            }}
          />
        </div>

        <div className="field-wrapper">
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="staff-contact-label">
              <Trans>Select Country Dial Code</Trans>
            </InputLabel>

            <Select
              labelId="staff-contact-label"
              id="staff-member-select"
              variant="standard"
              value={dialCode}
              onChange={e => setDialCode(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    color: '#333333',
                    fontWeight: 500,
                  },
                },
              }}
              inputProps={{
                PaperProps: {
                  style: {
                    color: '#333333',
                    fontWeight: 500,
                  },
                },
              }}
            >
              {countryOptions}
            </Select>
          </FormControl>
        </div>

        <div
          className="field-wrapper"
          style={{
            marginBottom: 60,
          }}
        >
          <TextField
            className={classes.textField}
            type="tel"
            label={t('Mobile')}
            value={mobile}
            InputProps={{
              // startAdornment: <InputAdornment>{dialCode}</InputAdornment>,
              style: { fontFamily: 'Raleway, sans-serif' },
            }}
            required
            fullWidth
            variant="standard"
            onChange={e => setMobile(e.target.value)}
            helperText={mobileIsInvalid()}
            error={mobileIsInvalid()}
            InputLabelProps={{
              style: {
                fontWeight: 500,
                color: '#333333',
              },
            }}
          />
        </div>

        <div>
          <FlatButton
            label={isUpdate ? t('Save') : t('ADD')}
            onClick={handleSubmit}
            primary
            disabled={isTherapistInvalid}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default TherapistsModal;
