import firebase from '../../../firebase';
import { remove500OfferAccountIds } from '../../../utils';

const requestSubscription = () => ({
  type: 'LOAD_SUBSCRIPTION',
});

const receiveSubscription = data => ({
  type: 'RECEIVE_SUBSCRIPTION',
  data,
});

export const selectApp = appname => ({
  type: 'SELECT_APP',
  appname,
});

const saveLifetimeOffer = () => ({
  type: 'SAVING_OFFER_FROM_QUERYSTRING',
  offer: 'lifetime',
});

export const loadSubscription = appname => async (dispatch, getState) => {
  const accIds = await remove500OfferAccountIds();
  dispatch(requestSubscription());

  firebase
    .database()
    .ref(`apps/${appname}/subscription`)
    .once('value')
    .then(snapshot => {
      const account = getState().account;
      const app = getState().app;
      const data = { appname };
      let subscriptionExpired = false;
      if (snapshot.val() && snapshot.val().plan) {
        subscriptionExpired = Date.now() > snapshot.val().current_period_end;
      }

      data[`{${appname}} plan`] =
        snapshot.val() && snapshot.val().plan
          ? snapshot.val().plan
          : 'free_trial';
      const result = snapshot.val();
      if (subscriptionExpired) {
        return dispatch(receiveSubscription(null));
      } else if (snapshot.exists()) {
        const plan =
          result.plan === 'price_1OPOWsH7KxBKIk4ar4GVzFYV'
            ? 'deluxe'
            : result.plan;
        const realPlan =
          result.realPlan === 'price_1OPOWsH7KxBKIk4ar4GVzFYV'
            ? 'deluxe'
            : result.realPlan;
        return dispatch(
          receiveSubscription({
            ...result,
            plan,
            realPlan,
          }),
        );
      }
      return dispatch(receiveSubscription(snapshot.val()));
    });
};

export const recordLastVisited = appname => {
  const user = firebase.auth();

  if (!user) {
    return null;
  }

  firebase
    .database()
    .ref(`users/${user.currentUser.uid}`)
    .child('lastVisited')
    .set(appname);

  return {
    type: 'RECORD_LAST_VISITED',
    appname,
  };
};
