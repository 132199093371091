import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Sidebar, { SidebarItem } from '../../../../app/components/sidebar';

import { PlMatchSupportIcon, RegionalPerformanceIcon } from '../../icons';

import {
  Drivers as DriversIcon,
  Appointment as AppointmentIcon,
  Booking as BookingIcon,
} from '../../../ecommerce/components/icons';

import ViewApp from '../../../../app/components/buttons/viewApp/';
import Toggle from '../../../../app/components/toggle';

const PgmolMenu = props => {
  const {
    match,
    location,
    history,
    therapistBookingEnabled,
    toggleBooking,
    getTherapistBooking,
  } = props;
  const baseHref = `/${match.params.appname}/pgmol`;
  const activeCheck = prefix =>
    location.pathname === `/${match.params.appname}/pgmol/${prefix}`;

  useEffect(() => {
    getTherapistBooking();
  }, []);

  return (
    <Sidebar animate>
      <ViewApp isPublished match={match} />
      <SidebarItem
        label="Regional Performance Centres"
        href={`${baseHref}/regional-performance-centre`}
        active={activeCheck('regional-performance-centre')}
        icon={<RegionalPerformanceIcon />}
      />
      <SidebarItem
        label="PL Match Day Support"
        href={`${baseHref}/pl-match-support`}
        active={activeCheck('pl-match-support')}
        icon={<PlMatchSupportIcon />}
      />
      <div
        className={`ml-switch-container ${
          therapistBookingEnabled ? 'enabled' : ''
        }`}
      >
        <span className="ml-switch-label">Enable Booking</span>
        <Toggle
          style={{ margin: '0' }}
          onToggle={(v, b) => {
            toggleBooking(b);
            if (b) history.push(`${baseHref}/therapist`);
          }}
          toggled={therapistBookingEnabled}
        />
      </div>

      {therapistBookingEnabled && (
        <>
          <SidebarItem
            label="Therapists"
            href={`${baseHref}/therapist`}
            active={activeCheck('therapist')}
            icon={<DriversIcon />}
          />
          <SidebarItem
            label="Hotel Therapy"
            href={`${baseHref}/hotel-therapy`}
            active={activeCheck('hotel-therapy')}
            icon={<AppointmentIcon />}
          />
          <SidebarItem
            label="Confirmed Bookings"
            href={`${baseHref}/confirmed-bookings`}
            active={activeCheck('confirmed-bookings')}
            icon={<BookingIcon />}
          />
        </>
      )}
    </Sidebar>
  );
};

PgmolMenu.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isPublished: PropTypes.bool,
};

PgmolMenu.defaultProps = {
  integration: null,
  isPublished: false,
};

export default PgmolMenu;
