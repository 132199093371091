/* eslint-disable linebreak-style */
import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import {
  CancelOutlined,
  CheckCircle,
  LibraryBooks,
  Schedule,
} from '@material-ui/icons';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

import { DetailsWrapper, DialogHeader } from '../../Elements';
import './styles.css';

const ParticipantsDetails = ({
  viewParticipantDialog,
  participantDialogOpen,
  selectedParticipant,
}) => {
  return (
    <Dialog
      data-testid="view-participant"
      open={participantDialogOpen}
      autoScrollBodyContent
      disableEnforceFocus
      scroll="paper"
      maxWidth="xl"
    >
      <div className="participant-modal-content">
        <DialogHeader>
          <div />
          <h6>
            <Trans>Participant&apos;s Details</Trans>
          </h6>
          <div>
            <CancelOutlined onClick={() => viewParticipantDialog(false)} />
          </div>
        </DialogHeader>
        <DialogContent>
          {selectedParticipant && (
            <Box>
              <Box mb={4}>
                <DetailsWrapper>
                  <Typography variant="h6" color="textSecondary">
                    First Name:
                  </Typography>
                  <Typography variant="h6">
                    {capitalize(selectedParticipant.firstName)}
                  </Typography>
                </DetailsWrapper>
                <DetailsWrapper>
                  <Typography variant="h6" color="textSecondary">
                    Last Name:
                  </Typography>
                  <Typography variant="h6">
                    {capitalize(selectedParticipant.lastName)}
                  </Typography>
                </DetailsWrapper>
                <DetailsWrapper>
                  <Typography variant="h6" color="textSecondary">
                    Email:
                  </Typography>
                  <Typography variant="h6">
                    {selectedParticipant.email}
                  </Typography>
                </DetailsWrapper>
              </Box>

              <Typography variant="h6" gutterBottom>
                Enrolled Courses (
                {Object.keys(selectedParticipant.enrollments).length})
              </Typography>
              <Box
                sx={{
                  maxHeight: '400px',
                  overflowY: 'auto',
                  paddingRight: 2,
                  marginRight: -2,
                  paddingBottom: '20px',
                  '&::-webkit-scrollbar': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '10px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '10px',
                    '&:hover': {
                      background: '#666',
                    },
                  },
                }}
              >
                <Grid container spacing={3}>
                  {Object.values(selectedParticipant.enrollments).map(
                    course => (
                      <Grid item xs={12} key={course.key}>
                        <Card style={{ border: '1px solid #ccc' }}>
                          <CardContent>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              columnGap={2}
                              mb={2}
                            >
                              <Typography
                                variant="h6"
                                style={{ fontSize: '16px' }}
                              >
                                {course.title}
                              </Typography>
                              <Chip
                                icon={
                                  course.progress.completed === 100 ? (
                                    <CheckCircle />
                                  ) : (
                                    <Schedule />
                                  )
                                }
                                label={
                                  course.progress.completed === 100
                                    ? 'Completed'
                                    : 'In Progress'
                                }
                                color={
                                  course.progress.completed === 100
                                    ? 'primary'
                                    : 'default'
                                }
                              />
                            </Box>
                            <Box mb={2}>
                              <LinearProgress
                                variant="determinate"
                                value={course.progress.completed}
                              />
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                mt={1}
                              >
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Progress
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {Math.round(course.progress.completed)}%
                                </Typography>
                              </Box>
                            </Box>
                            <Box display="flex" alignItems="center" gap={2}>
                              <LibraryBooks fontSize="small" color="action" />
                              <Typography variant="body2" color="textSecondary">
                                {course.modules.length} modules
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                •
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {course.progress.completedModules
                                  ? course.progress.completedModules.length
                                  : 0}{' '}
                                completed
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ),
                  )}
                </Grid>
              </Box>
            </Box>
          )}
        </DialogContent>
      </div>
    </Dialog>
  );
};

ParticipantsDetails.propTypes = {
  viewParticipantDialog: PropTypes.func.isRequired,
  participantDialogOpen: PropTypes.bool.isRequired,
  selectedParticipant: PropTypes.object.isRequired,
};

export default ParticipantsDetails;
